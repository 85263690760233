.overlay-mute{
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,0.80);
  z-index: 2;
}

.sidebar-fluid--full-width {
  .overlay-mute {
    position: fixed;
  }
}

.sidebar-fluid--full-width {
  .overlay-mute--spinner {
    top: 200px;
  }
}

.overlay-mute--spinner{
  position: absolute;
  top: 40%;
  left: 50%;
  height: 32px;
  width: 32px;
  margin-left: -16px;
  background: url('../img/spinner_32x32.gif') no-repeat 0 0;
}

.nav-tree__scroll {
  .overlay-mute--spinner {
    position: fixed;
    top: 50%;
    left: 185px;
  }
}

.overlay-mute--switcher{
  left: -3px;
  right: -3px;
}

.modal-content__box__loader {
  height: 90px;
}

.modal-content__box__loader__text {
  position: relative;
  color: darken(@gray, 7%);
  font-size: 14px;
  text-align: center;
  z-index: 5;
}