// Selectable section
//

.selectable-section {
  position: relative;
  padding: 10px 15px 0 20px;
  border-bottom: 1px solid @gray-border;

  &:last-child {
    border: none;
  }

  .ul-content {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 10px;
  }

  .glyphicon {
    margin-right: 6px;
  }
}

.modal-content__well__header {
  border: 1px solid @gray-border;
  border-bottom: none;

  h5 {
    margin: 0;
    padding: 8px 15px;
  }
}

.selectable-section__checkmark {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  color: @white;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    background: @green;
    .rotate(45deg);
    z-index: 3;
  }

  .glyphicon {
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 11px;
    z-index: 4;
  }
}

.selectable-section--selected {
  background: @bg-selected;

  .selectable-section__checkmark {
    display: block;
  }
}
