.room-info-icon{
  position: relative;
  top: -2px;
  display: inline-block;
  color: @white;
  font-size: 8px;
  line-height: 15px;
  text-align: center;
  font-style: normal;
  width: 15px;
  height: 15px;
  background: @grayLight;
  border-radius: 8px;
}

.hotel-table-wrap{
  .com{
    float: left;
    width: 45px;
  }

  .price{
    float: left;
    width: 123px;
    white-space: nowrap;
  }

  .gd{
    float: left;
    width: 55px;
    padding-right: 5px;
  }

  .description{
    float: left;
    width: 275px;
    padding-right: 5px;
  }

  .cancel{
    float: left;
    width: 160px;
  }

  .booking-info {
    float: left;
    width: 145px;
    font-size: 11px;

    .badge {
      margin: 1px 0 0;
      font-size: 10px;
      color: @red;
    }
  }

  .icons {
    float: left;
    width: 65px;

    [class^=icon-hs]{
      vertical-align: middle;
    }
  }
}

.header-filters{
  .clearfix();
  border-bottom: 1px solid @grayBorder;

  div{
    .bold();
    padding-bottom: 5px;
    padding-top: 5px;
  }

  span{
    position: relative;
    color: @gray;
    font-size: 12px;

    &.down{

      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -10px;
        border-top: 4px solid @gray;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
      }
    }

    &.up{

      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -10px;
        border-bottom: 4px solid @gray;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
      }
    }
  }
}

.rooms-list-wrap{
  margin: 0 0 5px;
  max-height: 300px;
  overflow-y: scroll;
}

.room-details-box{
  .clearfix();
  padding: 10px 0;
  border-bottom: 1px solid @grayBorder;

  &:hover{
    background: #f7fafe;

    .btn {
      .opacity(100);
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .btn {
    .opacity(70);
  }

  .room-mute{
    display: block;
    color: @grayLight;
    text-transform: uppercase;
  }

  .price {
    small {
      font-size: 11px;
    }
  }

  .description{
    h4{
      overflow: hidden;
      max-height: 34px;
      font-size: 13px;
      margin: 1px 0 0;
    }
  }

  .room-data{
    color: @grayLight;
    font-size: 11px;

    .item-data{
      display: inline-block;
      margin-right: 8px;

      [class^=icon-hs]{
        position: relative;
        top: 2px;
      }

      .icon-hs-other {
        top: 4px;
        margin-left: 1px;
      }
    }

    .lnk {
      color: @gray;

      &:hover {
        color: darken(@gray, 15%);
      }
    }

    .rate-details-spinner {
      float: left;
    }
  }

  .cancel{
    padding-top: 1px;

    .dotted-link{
      .bold();
    }

    .highlighted-label{
      display: inline-block;
      color: @white;
      .bold();
      text-transform: uppercase;
      padding: 0 3px;
      margin: 15px 0 0;
      background: #a1140d;
    }
  }

  .nightly-rates-spinner {
    position: absolute;
  }
}

.nightly-rates-table{
  width: 100%;

  th{
    .bold();
    text-transform: uppercase;
  }

  th,
  td{
    font-size: 11px;
    color: @white;
    text-align: left;
    font-style: normal;
  }
}

pre.error-text {
  white-space: pre-wrap;
  word-break: normal;
}

.btn-error-details {
  padding: 0px;
}
