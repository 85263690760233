// Tabs
//
.tabs__nav {
  .clearfix();
  padding: 0 25px;
  border-bottom: 1px solid @gray-border;

  > a {
    float: left;
    min-width: 68px;
    margin: 0 5px -1px 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 30px;
    color: @gray-dark;
    text-align: center;
    text-decoration: none;
    outline: 0;
    background: @gray-bg;
    border: 1px solid @gray-border;
    border-radius: 4px 4px 0 0;

    &:hover {
      background: darken(@gray-bg, 3%);
    }
  }
}

.row-wrapper > .row > .tabs__nav {
  margin: 0 15px;
}

.tabs__nav--out {
  margin-top: -42px;
}

.tabs__nav--small {
  padding: 0 0 0 15px;

  > a {
    font-size: 13px;
    padding: 3px 10px;
    line-height: 25px;
  }
}

.tabs__nav--active {
  background: @white !important;
  border-bottom: 1px solid @white !important;
  font-weight: bold;
  cursor: default;
}

.tabs__nav--error {
  border-color: red!important;
}

.tabs__nav--disabled {
  .opacity(0.45);

  &:hover {
    .opacity(0.8);
    background: @white !important;
  }
}

.tabs__content--absolute {
  position: absolute;
  top: 111px;
  right: 0;
  bottom: 81px;
  left: 360px;
  overflow-y: auto;
}

.tab__content--item {
  max-width: 1080px;
}

.tabs-inner {
  .clearfix();
  padding: 10px 0 0;
  background: @gray-bg;
  border-bottom: 1px solid @gray-border;
}

.tabs-inner--header {
  margin: -20px -25px 0;

  .tabs-inner__header {
    margin-left: 25px;
    font-size: 24px;
    font-weight: normal;
  }

  .tabs-inner__controls {
    float: left;
    margin-left: 25px;
  }
}

.tabs-inner__header {
  margin: 0 0 0 15px;
  font-weight: bold;
  font-size: 12px;
}

.tabs-inner__controls {
  margin: 13px 0 0 15px;
}

.tabs-inner__item {
  float: left;
  display: block;
  margin: 0 5px -1px 0;
  padding: 7px 13px;
  min-width: 90px;
  color: @gray-dark;
  background: @white;
  text-align: center;
  border-radius: 3px 3px 0 0;
  border: 1px solid @gray-border;
  .opacity(0.75);

  &:hover,
  &:active,
  &:focus {
    color: @gray-dark;
    .opacity(1);
    text-decoration: none;
  }
}

.tabs-inner__item--active {
  font-weight: bold;
  border-bottom-color: @white;
  .opacity(1);
}


.tabs-inner--categories {
  padding-top: 9px;

  .tabs-inner__controls {
    margin: 0;
  }

  .tabs-inner__item {
    position: relative;
    height: 40px;
    min-width: 140px;
    padding: 4px 10px 4px 11px;
    background: rgba(255,255,255,0.35);
    .opacity(1);

    &:hover {
      background: @white;
    }
  }

  .tabs-inner__item--active {
    padding-right: 14px;
    border-left: 3px solid lighten(@green, 5%);
    background: @white;

    .tabs-inner__item__title {
      color: @gray-darker;
    }
  }
}

.tabs-inner__item__title {
  display: block;
  margin-top: 6px;
  text-transform: uppercase;
  font-weight: bold;
  color: lighten(@gray-darker, 8%);
}

.tabs-inner__item__icon {
  float: right;
  margin: 4px -5px 0 0;
  padding: 3px 4px;
  font-size: 11px;
  border: 1px dashed @gray-border;
  border-radius: 3px;

  &:hover {
    background: @gray-bg;
  }

  .glyphicon {
    margin: 0;
  }
}

.radio-tabs {
  margin: 0 0 6px;

  .radio-rev {
    position: relative;
    padding: 11px 12px 11px 31px !important;
    font-size: 12px;
    line-height: 1;
    z-index: 2;
  }

  .radio__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 3px;

    &:before {
      top: 8px;
      left: 9px;
    }

    &:after {
      top: 12px;
      left: 13px;
    }
  }

  input[type="radio"]:checked {
    & + .radio__icon {
      background: darken(@gray-bg, 2%);
    }
  }
}

.tabs__nav--table {
  padding: 0 15px;

  .inner-tabs__item {
    padding: 8px 12px;
    font-size: 12px;
    line-height: 1;
  }
}

.warning {
  .tabs__nav--table {
    .tabs__nav--active {
      background: transparent !important;
      border-bottom: 1px solid @state-warning-bg !important;
    }
  }
}

.tabs__nav__content {
  padding: 10px 15px;

  ul {
    margin: 10px 0 0;
  }

  .table {
    width: 80%;
    margin-bottom: 0;
    background: transparent;

    tr:first-child th {
      border-top: none;
    }
  }
}

.tabs-row {
  margin: 40px 0 5px;
  padding: 8px 0 0;
  background: @gray-bg;

  .tabs__nav--small {
    padding-left: 25px;
  }
}

.tabs--rev {
  padding: 10px 25px 0;
  background: @gray-bg;

  .inner-tabs__item {
    padding: 7px 12px;
    font-size: 15px;
  }
}