// Radio / Checkbox Buttons
//
//

.radio-rev {
  padding-left: 22px;
  cursor: pointer;
  .user-select(none);

  input[type="radio"] {
    display: none;
  }
}

input[type="radio"]:checked {
  + .radio__icon {
    &:after {
      display: block;
    }
  }
}

input[type="radio"]:disabled {
  + .radio__icon {
    .opacity(0.55);
  }
}

.radio__icon {
  display: block;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -22px;
    top: 0px;
    width: 16px;
    height: 16px;
    border: 1px solid @gray;
    border-radius: 10em;
    background: @white;
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: -18px;
    top: 4px;
    width: 8px;
    height: 8px;
    background: @gray-dark;
    border-radius: 10em;
  }
}

.radio--error {
  label {
    color: @red;
  }

  .radio__icon {
    &:before {
      border-color: @red;
      background-color: lighten(@yellow, 22%);
    }
  }
}

.checkbox-rev {
  padding-left: 22px;
  cursor: pointer;
  .user-select(none);

  input[type="checkbox"] {
    display: none;
  }
}

input[type="checkbox"]:checked {
  + .checkbox__icon {
    &:after {
      display: block;
    }
  }
}

input[type="checkbox"]:disabled {
  + .checkbox__icon {
    .opacity(0.55);
  }
}

.checkbox__icon {
  display: block;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -22px;
    top: 0px;
    width: 16px;
    height: 16px;
    border: 1px solid @gray;
    border-radius: 2px;
    background: @white;
  }

  &:after {
    content: "\e013";
    display: none;
    position: absolute;
    left: -19px;
    top: 3px;
    font-family: 'Glyphicons Halflings';
    font-size: 9px;
    line-height: 1;
    color: @gray-dark;
  }
}

.checkbox--error {
  label {
    color: @red;
  }

  .checkbox__icon {
    &:before {
      border-color: @red;
      background-color: lighten(@yellow, 22%);
    }
  }
}

.nav-tree {
  .checkbox__icon {
    display: block;
  }
}

.control--disabled {
  cursor: default !important;
  color: darken(@gray-light, 10%);
}

.form-horizontal {
  .radio.radio-rev + .radio.radio-rev,
  .checkbox.checkbox-rev + .checkbox.checkbox-rev {
    margin-top: 0 !important;
  }
}

.radio-inline.radio-rev + .radio-inline.radio-rev,
.checkbox-inline.checkbox-rev + .checkbox-inline.checkbox-rev {
  margin-left: 18px;
}

.radio-inline.radio-rev,
.checkbox-inline.checkbox-rev {
  margin-top: 0;
}

.offset-20 {
  margin-left: 20px;

  .radio-inline + .radio-inline {
    margin-left: 0;
  }
}

.checkbox__option {
  display: block !important;
  margin: 3px 0 0 4px !important;
  padding-bottom: 0 !important;
}

.radio-select__option {
  padding-top: 3px !important;
  padding-bottom: 4px;
}

.radios-header {
  padding-top: 11px;
}

.checkbox__toggle {
  margin: 0 0 3px 21px;
}

.checkbox__toggle__value {
  margin: 5px 0 0 21px;
  font-size: 11px;
}

.radio-block__option {
  padding-bottom: 0 !important;

  .muted {
    padding-top: 2px;
    color: @gray;
    font-size: 12px;
  }
}

.radio-rev,
.checkbox-rev {
  .muted {
    padding: 2px 0 3px 0;
    font-size: 12px;
    color: @gray;
  }
}
