.comparison-section {
  position: relative;
  margin: -23px -20px -20px;
  padding-bottom: 17px;
  border-top: 3px solid @green;
  z-index: 5;

  &:after {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    background: @gray-bg;
    z-index: -1;
  }

  .muted {
    color: #666;
  }

  .small {
    font-size: 10px;
  }
}

.comparison-section__row {
  .clearfix();
  position: relative;
  margin: 3px 0;
}

.comparison-section__col {
  float: left;
  width: 250px;
  min-height: 5px;
  padding: 0 15px;
  font-size: 12px;

  a {
    font-size: 11px;
  }

  h5 {
    margin: 0 0 5px;
    text-transform: uppercase;
    font-size: 11px;
  }

  li {
    margin-bottom: 3px;
    font-size: 11px;
    line-height: 1.4;
  }

  ul {
    padding-left: 12px;
  }
}

.comparison-section__col--label {
  width: 100px;
  padding-top: 1px;
  text-align: right;
  font-size: 11px;
}

.comparison-section__col--header {
  float: left;
  display: block;
  min-height: 38px;
  margin-bottom: 15px;
  padding: 10px 10px 0;
  font-size: 11px;
  color: #444;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;

  .small {
    margin-left: 4px;
    font-size: 9px;
  }
}

.comparison-section__price {
  display: inline-block;
  margin-right: 2px;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
}

.comparison-section__hr {
  margin: 12px 0 12px 100px;
  border-bottom: none;
  border-top: 1px solid @gray-border;
}

.comparison-section__rate-title {
  margin: 0 0 2px;
  font-size: 12px;

  &:empty {
    display: none;
  }
}

.comparison-section__rate-desc {
  height: 30px;
  margin-bottom: 3px;
  line-height: 1.38;
  font-size: 11px;
  overflow: hidden;
}

.comparison-section__note {
  margin-top: 2px;
  font-size: 11px;
  line-height: 1.38;
  color: #666;
  text-align: center;
}

.comparison-section__lnk-wrapper {
  height: 24px;
}

.comparison-section__lnk {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 3px 0;
  font-size: 11px;
}