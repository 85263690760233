// Bulk Upload
//

.bulk-upload {
  .clearfix();
  border: 1px solid @gray-border;
}

.bulk-upload__col {
  float: left;
  text-align: center;
  white-space: nowrap;
  width: 24%;
  padding: 17px 0;
}

.bulk-upload__list--vertical {
  float: left;
  width: 52%;
  margin: -1px 0;

  th,
  td {
    text-align: left;
    padding: 4px 12px;
    border: 1px solid @gray-border;
    background: #fafafa;

    .muted {
      color: @gray;
    }

    a {
      font-size: 12px;
    }
  }

  thead tr {
    cursor: default;
    font-weight: bold;
  }

  tr {
    cursor: pointer;

    &:hover {
      td {
        border-bottom: 1px solid @brand-secondary;
        background: @gray-lighter;
      }
    }

    &.bulk-upload__item--selected {
      td {
        border-bottom: 2px solid @brand-secondary;
        background: @gray-lighter;
      }
    }
  }
}

.bulk-upload__list--table {
  width: 100%;

  th,
  td {
    padding: 4px 12px;
    border: 1px solid @gray-border;
    background: #fafafa;
  }

  thead tr {
    cursor: default;
  }

  tr {
    cursor: pointer;

    &:hover {
      td {
        border-bottom: 1px solid @brand-secondary;
        background: @gray-lighter;
      }
    }

    &.bulk-upload__item--selected {
      td {
        border-bottom: 2px solid @brand-secondary;
        background: @gray-lighter;
      }
    }

    &.bulk-upload__item--muted {
      color: lighten(@gray, 5%);
    }
  }
}


.bulk-upload__list {
  float: left;
  width: 52%;

  td {
    text-align: right;
    font-size: 12px;
    cursor: pointer;
    line-height: 1.6;
    vertical-align: top;
    height: 66px;
    padding: 4px 10px 4px 8px;
    background: #fafafa;
    border-left: 1px solid @gray-border;
    border-right: 1px solid @gray-border;

    &:hover {
      background: @gray-lighter;
    }
  }

  .bulk-upload__item-title {
    color: @gray;
    font-size: 11px;
    margin-bottom: 3px;
  }

  .small {
    font-size: 11px;
  }
}

.bulk-upload__item--selected {
  border-bottom: 2px solid @brand-secondary;
}

.bulk-upload__counter {
  text-align: center;
  height: 32px;

  .counter__number {
    display: inline-block;
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    width: 34px;
    height: 32px;
    margin-left: 4px;
    background: @gray-light;
    border-radius: 3px;
  }

  span {
    vertical-align: middle;
  }
}

.bulk-upload__placeholder {
  float: left;
  width: 52%;
  height: 66px;
  background: #fafafa;
  border-left: 1px solid @gray-border;
  border-right: 1px solid @gray-border;
}