.static-slats {
  margin: 0 0 40px 0;

  .modal-body & .row {
    margin-bottom: 0;
  }
}

.static-slats--multiline {
  .static-slats__col--form {
    width: 68%;
  }

  .static-slats__col {
    padding-top: 8px;
    vertical-align: top;
  }

  .btn {
    margin-top: 5px;
  }

  .control-label {
    display: none;
  }

  .static-slats__item {
    .tags-input {
      margin: 0;
    }
  }

  .row + .row {
    margin-top: 8px;

    &:last-child {
      margin-bottom: 3px;
    }
  }

  .dropdown-menu {
    width: 320px;

    a {
      white-space: normal;
    }
  }
}

.static-slats__item {
  position: relative;
  display: table;
  width: 100%;
  margin: 0 0 5px 0;
  table-layout: fixed;
  background: @white;
  border: 1px solid @gray-border;

  &:hover {
    background: #fafafc;
    border: 1px solid darken(@gray-border, 8%);
  }

  .dropdown__unstyled {
    margin-top: -2px;
    padding: 0;

    .dropdown-toggle {
      font-size: 85%;
    }
  }

  .tags-input {
    margin: 5px 0;
  }

  .slider__wrapper {
    .slider {
      padding: 12px 0 8px;
    }

    .slider__append {
      top: -8px;
    }
  }
}

.static-slats__item--active {
  .static-slats__status {
    background: @green;
  }
}

.static-slats__item--disabled {
  .static-slats__status {
    background: darken(@gray-border, 18%);
  }
}

.static-slats__header {
  .clearfix();
  padding: 0 0 11px 5px;
  font-size: 11px;
}

.static-slats__head {
  float: left;
  height: auto !important;
  min-height: 1px;
  padding: 0 20px;
  font-weight: bold;
  background: transparent !important;
}

.static-slats__col {
  display: table-cell;
  height: 45px;
  padding: 0 20px;
  vertical-align: middle;

  .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.static-slats__col__footer {
  margin: 8px 0 0;
  padding: 8px 0 0;
  font-size: 12px;
  border-top: 1px dotted @gray-border;
}

.static-slats__col--attn,
.static-slats__col--attn-narrow {
  width: 22%;
  background: darken(@gray-bg, 4%);
}

.static-slats__col--attn-narrow {
  width: 180px;
  padding: 0 0 0 8px;

  .row {
    margin: 0 0 0 -10px;
  }
}

.static-slats__col--std {
  width: 29%;
  padding: 0 40px;
}

.static-slats__col--form {
  width: 60%;
  height: 45px;
  padding: 8px 15px;

  .form-control {
    padding: 0 8px;
  }
}

.static-slats__col--static {
  width: 100%;
  padding: 0 2%;
}

.static-slats__status {
  display: table-cell;
  width: 4px;
  background: darken(@gray-bg, 4%);
}

.static-slats__add-trigger {
  position: relative;
  width: 100%;
  height: 55px;
  margin-top: 10px;
  background: rgba(255,255,255,0.9);
  border: 1px dashed @gray-border;

  .btn {
    width: 100%;
    height: 54px;
    font-size: 14px;
  }
}
