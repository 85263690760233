//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
  position: relative;
  padding: @alert-padding;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: @alert-border-radius;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing @headings-color
    color: inherit;
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: @alert-link-font-weight;
  }

  .secondary-link {
    text-decoration: underline;
  }

  .form-secondary-title {
    margin: 3px 0 23px 0;
    color: @text-color;
  }

  // Improve alignment and spacing of inner content

  > ul {
    padding-left: 15px;
  }
  li {
    margin-bottom: 5px;
  }
  > p,
  > ul {
    margin-bottom: 0;
    color: @gray-darker;
  }
  > p + p {
    margin-top: 5px;
  }

  .well {
    margin: 12px 0 2px 18px;
    padding: 10px 15px;
    font-size: 12px;
    color: @gray-darker;
    background: rgba(255,255,255,0.4);
    border: 1px dashed rgba(0,0,0,0.11);

    + .well {
      margin-top: 4px;
    }
  }
}

.alert-danger {
  .glyphicon-remove-sign {
    color: @red;
  }
}

.alert--icon {
  padding: 13px 15px 15px 45px;
}

.alert__icon {
  font-size: 22px;
  position: absolute;
  top: 11px;
  left: 13px;
}

.alert__title {
  margin: 0 0 4px;
  font-size: 16px;

  .glyphicons,
  .glyphicon {
    margin-right: 4px;
    font-size: 15px;
  }
}

.alert__text {
  padding: 3px 32px 0 19px;
}

.alert__message {
  display: block;
  margin-top: 5px;

  &:empty {
    display: none;
  }
}

.alert__control {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  color: @gray;
  text-align: center;
  border-left: 1px solid @gray-light;

  &:hover {
    color: @gray-darker;
  }

  .glyphicon {
    position: relative;
    top: 50%;
    font-size: 14px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.alert-success {
  .alert__control {
    border-left-color: @state-success-border;
  }
}

.alert-danger {
  .alert__control {
    border-left-color: @state-danger-border;
  }
}

.alert-warning {
  .alert__control {
    border-left-color: @state-warning-border;
  }
}

.alert-info {
  .alert__control {
    border-left-color: @state-info-border;
  }
}

.alert__toggle {
  .well {
    margin: 5px 0 2px;
    font-size: 11px;
  }
}

.alert__btn-toggle {
  display: block;
  margin: 0;
  padding: 2px 0;
  font-size: 11px;
}



// Dismissable alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable {
 padding-right: (@alert-padding + 20);

  // Adjust close link position
  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
}
.alert-info {
  .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
}
.alert-warning {
  .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
}
.alert-danger {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}

