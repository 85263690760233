// Search widget
//

.search-widget {
  display: inline-block;
  position: relative;
  margin-left: 4px;

  .form-control,
  .borderless {
    width: 220px;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  &:not(.col-right) > .input-group {
    margin-right: 12px;
  }

  .dropdown {
    display: inline-block;
    width: 250px;
    margin-bottom: 0;
  }

  .btn {
    margin-left: 4px;
  }

  .dropdown-menu {
    top: 29px !important;
    right: 0 !important;
    left: auto !important;
    width: auto !important;
    min-width: 250px;
    transform: none !important;
  }

  .input-group-btn .btn {
    height: 32px;
  }
}

.search-widget--large {
  .form-control {
    width: 315px;
  }
}

.search-widget__search--icon {
  position: relative;
  line-height: 32px;

  .form-control {
    padding-left: 29px;
  }

  .borderless {
    padding-left: 23px;
  }

  .search-widget__input-ico {
    position: absolute;
    left: 9px;
    top: 50%;
    margin-top: -5px;
    color: @gray-border;
  }
}

.search-widget--right{
  float: right;
  margin-left: 10px;

  .search-widget > .input-group {
    margin-right: 0;
  }
}
