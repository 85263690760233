// Box grid
//

.box-grid__item {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 225px;
  margin-bottom: 15px;
  padding: 25px 30px 10px;
  vertical-align: top;
  border: 1px solid @gray-border;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: @green;
  }
}

.box-grid__item--empty {
  border-style: dashed;

  &:after {
    display: none;
  }
}

.box-grid__item--disabled {
  border-style: dashed;
  background: #fafafa;

  &:after {
    display: none;
  }

  .box-grid__cover-link {
    color: darken(@gray-text, 6%);
    cursor: not-allowed;
  }
}

.box-grid__cover-link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  span {
    margin-top: -5px;
  }
}

.box-grid__title {
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: bold;
}

.box-grid__desc {
  .list-unstyled();

  li {
    margin-bottom: 3px;
  }
}

.box-grid__control {
  margin-top: 10px;

  .btn-group {
    float: right;
  }
}