// Complex dropdown
//

.complex-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 830px;
  background: @white;
  .box-shadow(0 2px 7px 0px rgba(0, 0, 0, 0.4));
  z-index: 10;

  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
  }

  .form-control {
    height: 27px;
    font-size: 12px;
  }

  .input-daterange {
    .input-group-addon {
      padding: 3px 5px;
      font-size: 11px;
    }
  }

  .form-group__sub {
    margin-bottom: -21px;
    font-size: 11px;
  }

  .form-group {
    margin-bottom: 14px;
  }

  .checkbox-inline {
    padding-bottom: 4px;
  }

  .tags-input {
    padding: 2px 4px 1px;
    font-size: 12px;
  }

  hr {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .control-label {
    margin-bottom: 4px;
    font-size: 11px;
    font-weight: bold;
  }

  .checkbox,
  .radio {
    font-size: 12px;
    margin: 5px 0;
  }

  .checkbox:last-child {
    margin-bottom: 0px;
  }

  .input-daterange {
    width: 100%;
  }

  .multi-selector {
    .controls {
      margin-top: 0;
      padding-top: 0;
      min-height: 90px;
      max-height: 90px;
    }

    .checkbox {
      margin: 3px 0 2px;
      font-size: 11px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .custom-select .value {
    height: 27px;
    line-height: 26px;
    font-size: 12px;

    &:after {
      top: 12px;
    }
  }

  .checkbox-rev {
    padding-left: 20px;
  }

  .checkbox__icon {
    &:before {
      left: -20px;
      width: 14px;
      height: 14px;
    }

    &:after {
      top: 4px;
      left: -17px;
      font-size: 8px;
    }
  }

  .form-group--datetime {
    .clearfix();

    input.form-control {
      float: left;
      width: 66%;
    }

    .custom-select {
      float: right;
      width: 32%;
      margin-right: 0;
    }
  }
}

.complex-dropdown--narrow {
  width: 655px;
}

.complex-dropdown--wide {
  right: -405px;
  width: 1130px;

  [class^="col-xs-"],
  [class*=" col-xs"] {
    padding-right: 8px !important;
  }

  .complex-dropdown__content {
    padding-right: 28px;
  }

  .input-group-addon {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .input-daterange .form-control {
    padding-right: 4px;
    padding-left: 10px;
  }
}

.complex-dropdown--left {
  right: auto;
  left: 0;
}

.complex-dropdown__header {
  .clearfix();
  padding: 10px 16px;
  border-bottom: 1px solid @gray-border;

  .modal-title {
    font-size: 16px;
  }
}

.complex-dropdown__search {
  float: right;

  .form-control {
    float: right;
    width: 200px;
  }
}

.complex-dropdown__search__clr {
  float: left;
  font-size: 12px;
  margin: 8px 15px 0 0;
}

.complex-dropdown__content {
  padding: 15px;

  .input-xlarge {
    width: 310px;
  }
}

.complex-dropdown__table {
  td {
    padding: 5px 10px;
    vertical-align: top;
  }

  .checkbox {
    margin: 0;
  }
}

.complex-dropdown__lnk {
  padding: 8px 0 5px;
}

.complex-dropdown__clear-lnk {
  position: absolute;
  bottom: 22px;
}

.complex-dropdown__clear-icon {
  position: relative;
  top: -1px;
  margin-right: 1px;
}


.complex-dropdown__footer{
  .clearfix();
  text-align: right;
  padding: 15px;
  border-top: 1px solid @gray-border;
}

// Dropdown with colums widget
//

.dropdown-menu__row {
  line-height: 19px;

  &:first-child{
    .dropdown-menu__section{
      border-top: none;
    }
  }

  &:hover{
    .dropdown-menu__section{
      background: @dropdown-link-hover-bg;
    }
  }

  &.active {
    margin-right: 210px;

    .dropdown-menu__section{
      color: @white;
      .bold();
      background: @blue;

      > a {
        color: @white;
      }

      .glyphicon {
        color: rgba(255,255,255,0.3);
      }
    }

    .dropdown-menu__sublist{
      display: block;
    }
  }
}

.dropdown-menu__section{
  display: block;
  width: 210px;
  padding: 7px 10px;
  line-height: 23px;
  cursor: pointer;
  border-top: 1px solid @gray-border;

  .glyphicon{
      margin-top: 3px;
      color: @grayLight;
  }
}

.dropdown-menu__section--attn {
  padding: 0;
  font-size: 11px;
  color: @link-color;

  &:hover {
    font-weight: normal !important;
    color: @link-hover-color;
  }

  a {
    display: block;
    padding: 1px 10px;
  }
}

.dropdown-menu__sublist{
  display: none;
  position: absolute;
  top: -1px;
  left: 210px;
  width: 210px;
  min-height: 100%;
  background: @white;
  border: 1px solid @gray-border;
  box-shadow: inset 2px 0 6px -4px rgba(0, 0, 0, 0.4);
  .box-sizing(content-box);

  li:last-child {
    margin-bottom: 5px !important;
  }

  a {
    display: block;
    padding: 2px 15px;
    color: @gray;

    &:hover {
      background: @dropdown-link-hover-bg;
    }

    &:empty {
      display: none;
    }
  }
}

.dropdown-menu__title {
  padding: 9px 15px 5px;
  font-weight: bold;
}

.dropdown-menu__sub {
  margin-top: 1px;
  font-size: 11px;
  line-height: 15px;
}

.dropdown.destination {
  li[role=presentation] > a {
    padding-top: 5px;
    padding-bottom: 5px;

    span.glyphicons {
      margin: 0 10px 0 -10px;
      font-size: 13px;
    }
  }

  li[role=heading] {
    width: 100%;
    margin-bottom: 3px;
    padding-left: 9px;
    line-height: 23px;
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    color: #333;
    box-sizing: border-box;
    font-smoothing: antialiased;
    background: @gray-bg;
  }

  .muted {
    padding-left: 14px;
  }
}

.dropdown-menu--wide {
  width: 405px !important;
  max-width: none !important;

  a[role="menuitem"] {
    .clearfix();
    padding: 4px 12px !important;
    white-space: normal;
    border-bottom: 1px solid @gray-border;
  }

  li:last-child {
    a[role="menuitem"] {
      border: none;
    }
  }
}

.ag-destination,
.sb-destination {
  .dropdown-menu {
    a {
      white-space: normal;
      padding-left: 32px !important;
    }

    .dropdown-item:is(.active) {
      .dropdown-menu__dates,
      .dropdown-menu__inline-value .text-gray {
        color: lighten(@blue, 43%);
      }
    }
  }

  [role="presentation"]:hover {
    background: @grayBg;
  }

  [role="heading"] {
    background: darken(@grayBg, 4%);
  }

  [role="button"] {
    .glyphicons {
      width: 17px;
      height: 17px;
      margin: 0 8px 0 -25px;
      font-size: 13px;
      text-align: center;
    }
  }
}

.dropdown-menu__dates {
  display: block;
  font-size: 11px;
  color: #999;

  &.expired {
    color: #ddd;
    text-decoration: line-through;
  }
}

.dropdown-menu__abbr,
.dropdown-menu__icon {
  display: inline-block;
  width: 34px;
  margin-left: -9px;
  font-size: 12px;
  font-weight: bold;
}

.dropdown-menu__icon {
  width: 23px;
}

.dropdown-menu__footer {
  padding: 8px 10px 10px !important;
  color: @blue !important;
  cursor: pointer;
  background: transparent !important;

  &:hover {
    color: @white !important;
    background: @blue !important;

    .glyphicons {
      color: @blue;
      background: lighten(@blue, 65%);
    }
  }

  .glyphicons {
    width: auto !important;
    height: 24px !important;
    padding: 5px;
    font-size: 11px;
    background: @blue;
    color: @white;
    border-radius: 20em;
  }
}

.dropdown-menu {
  li + .dropdown-menu__footer {
    margin: 10px 0 0;
    border-top: 1px solid @gray-border;
  }
}

.dropdown-menu__wrapper {
  display: block;
  padding: 3px 0 6px;
  margin: 0 -10px -3px -32px;
  border-bottom: 1px dashed @gray-border;
}

.dropdown-menu__item {
  .clearfix();
  display: block;
  margin-bottom: 1px;
  font-size: 11px;

  .text-muted {
    color: lighten(@gray, 16%);
  }
}

.dropdown-menu__inline-label {
  float: left;
  display: block;
  width: 8%;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;

  .glyphicons {
    margin: 0 !important;
    text-align: left !important;
  }
}

.dropdown-menu__inline-value {
  float: left;
  width: 90%;
  padding-right: 5px;
}

.dropdown-menu__rev {
  display: flex;
  position: relative;

  .glyphicons {
    top: 2px;
  }

  .glyphicons-airplane {
    transform: rotate(45deg);
  }
}

.dropdown-menu__rev--nested {
  margin-left: 46px;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 4px;
    left: -44px;
    width: 11px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 27.444 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.525 19.684l7.49-6.242c.285-.234.428-.553.428-.955 0-.403-.143-.722-.429-.956l-7.49-6.242c-.403-.338-.845-.396-1.326-.175-.481.234-.722.611-.722 1.131v3.746H4.993V3.749a.595.595 0 00-.214-.488L1.034.141C.839-.017.611-.043.351.061.117.166 0 .355 0 .628V14.36c0 .183.058.332.175.449a.608.608 0 00.449.175h16.852v3.745c0 .52.24.897.722 1.132.468.234.91.175 1.327-.176z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    opacity: 0.32;
  }
}

.dropdown-menu-start {
  &[data-bs-popper] {
    right: auto;
    left: 0;
  }
}

.dropdown-menu-end {
  &[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropdown-divider {
  margin: 8px 0;
}
