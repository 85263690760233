@-webkit-keyframes rotate-constantly {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-constantly {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-constantly {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-top: -3px;
  vertical-align: middle;
  border: 5px solid @white;
  border-right-color: transparent;
  border-radius: 100%;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-name: rotate-constantly;
  -moz-animation-name: rotate-constantly;
  animation-name: rotate-constantly;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
}

.loading-spinner--dark {
  border-color: @gray-dark;
  border-right-color: transparent;
}

.loading-spinner--light {
  border-color: lighten(@gray, 35%);
  border-right-color: transparent;
}

.loading-spinner--small {
  width: 22px;
  height: 22px;
  border-width: 3px;
}

.loading-spinner__wrapper {
  padding: 30px 0;
  text-align: center;
}

.screen-loader {
  position: fixed;
  top: 45%;
  right: 0;
  left: 0;
  margin-top: -18px;
  text-align: center;

  .loading-spinner {
    width: 36px;
    height: 36px;
  }
}