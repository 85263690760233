// Sortable widget
//

.sortable__header {
  margin-bottom: 10px;

  p {
    width: 70%;
  }

  + .sortable__header {
    margin-bottom: 14px;
  }
}

.sortable__items {
  position: relative;
}

.sortable__items__title {
  margin-bottom: 10px;

  h4 {
    font-size: 14px;
  }

  .btn {
    margin-top: -4px;
  }
}

.row-wrapper > .row > .sortable__items {
  margin: 0 15px;
}

.sortable-header {
  background: @white;
  height: 66px;
  margin: 0 0 -1px 0;
  padding: 16px 18px;
  border: 1px solid @gray-border;

  .form-group {
    margin: 0;
  }
}

.sortable-header__aside {
  margin-top: 8px;

  .glyphicon {
    margin: 0 5px;
  }

  .clickable {
    border-bottom: 1px dotted @gray;
  }
}

.sortable-header__wrapper {
  margin-top: 4px;

  .btn {
    margin-right: 5px;
  }
}

.sortable__item {
  .clearfix();
  position: relative;
  min-width: 600px;
  min-height: 40px;
  border: 1px solid @gray-border;
  cursor: pointer;
  .user-select(none);

  &:hover {
    background-color: darken(@gray-bg, 2%);
  }

  & + .sortable__item {
    margin-top: -1px;
  }
}

.sortable__item--inactive {
  &:hover {
    background: @white !important;
  }

  .sortable__status:not(.bg-error) {
    background: @gray !important;
  }

  .sortable__title,
  .sortable__title__count {
    color: darken(@gray-text, 4%);
  }
}

.sortable__status {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7px;
  margin: 0 10px 0 0;
  background: @green;

  &.bg-success {
    background: @green;
  }

  &.bg-warning {
    background: @orange;
  }

  &.bg-error  {
    background: @red;
  }
}

.sortable__item--new {
  background: lighten(@yellow, 43%);
}

.sortable__item--action {
  background: lighten(@blue, 45%);
}

.sortable__title__header {
  .text-overflow();
  margin: 0;
  font-size: 16px;
}

.sortable__title__sub {
  font-size: 12px;
  white-space: normal;
}

.sortable__title {
  position: absolute;
  top: 50%;
  display: inline-block;
  max-width: 67.2%;
  margin: 0 0 9px 17px;
  font-size: 14px;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sortable__title--controls {
  position: static;
  width: 100%;
  margin-top: 11px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;

  .btn-group {
    position: absolute;
    top: -7px;
    left: -6px;
  }
}

.sortable__title__control {
  position: absolute;
  left: 16px;
  padding: 0 3px;
  font-size: 12px;
  color: @gray;
  cursor: pointer;
  z-index: 5;

  &:hover {
    color: @gray-darker;
  }

  &.up {
    top: 3px;
  }

  &.down {
    bottom: 1px;
  }
}

.sortable__title__control--inactive {
  cursor: default;
  color: lighten(@gray, 35%) !important;
}

.sortable__tags {
  float: right;
}

.sortable__tag {
  display: inline-block;
  margin: 8px 6px 0 0;
  padding: 0 12px;
  font-size: 11px;
  text-align: center;
  line-height: 22px;
  background: darken(@gray-bg, 3%);
  border: 1px solid @gray-border;
  border-radius: 10em;
}

.sortable__btn-group {
  margin-top: 4px;

  .btn {
    font-size: 12px;
  }
}

.sortable__items--rev {
  padding: 22px;
  overflow: visible !important;
  background: @gray-bg;
  border: 1px solid @gray-border;

  .sortable__item {
    height: 64px;
    background-color: @white;
    cursor: default;

   &:hover:not(.sortable__item--inactive):not(.sortable__item--empty) {
     background-color: lighten(@blue, 51%);
    }
  }

  .sortable__title {
    .text-overflow();
    overflow: visible;
    margin-left: 60px;
    font-size: 13px;
  }

  .sortable__title__count {
    position: absolute;
    top: 0;
    left: 8px;
    bottom: 0;
    width: 35px;
    font-size: 16px;
    line-height: 62px;
    text-align: center;
    background: darken(@gray-bg, 4%);
    border-right: 1px solid lighten(@gray-border, 4%);
  }
}

.sortable__items--cols {
  padding: 22px;
  overflow: visible !important;
  background: @gray-bg;
  border: 1px solid @gray-border;

  .sortable__items__title {
    margin: -3px -15px 17px;

    h4 {
      margin-top: -3px !important;
    }
  }

  .sortable__item {
    padding: 8px 0 8px 19px;
    background-color: @white;
    cursor: default;

   &:hover {
     background-color: lighten(@blue, 60%);
    }
  }

  .sortable__item--empty {
    min-height: 60px;

    .sortable__title {
      position: static;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;

      a {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 19px 0 0 33px;
        font-weight: bold;
        text-align: left;
      }
    }
  }

  .sortable__title {
    font-size: 13px !important;
  }

  .sortable__control {
    margin: 6px 21px 0 0;

    a {
      font-size: 14px;
      color: lighten(@gray, 10%);

      &:hover {
        color: @gray-dark;
      }
    }
  }

  .custom-select {
    margin-top: 5px;
  }

  .sortable__text {
    display: inline-block;
    margin-top: 6px;
  }

  .col-xs-1 {
    width: 12%;
  }

  .col-xs-2 {
    width: 19%;
  }
}

.sortable__items--inactive {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,0.7);
    z-index: 6;
  }
}

.sortable__control {
  float: right;
  margin: 14px 10px 0 0;

  .dropdown {
    float: left;
  }

  .dragging-control {
    display: inline-block;
    margin: 7px 6px 0 0;
    outline: none;
  }
}

.sortable__text {
  color: #444;
}

.sortable__drag-indicator {
  float: right;
  width: 12px;
  margin: 2px 0 0 7px;
  padding: 2px;
  cursor: move; // for IE
  cursor: grab;

  &:hover {
    .sortable__drag-indicator__dot {
      &:after, &:before {
        background: @gray;
      }
    }
  }
}

.sortable__drag-indicator__dot {
  &:after, &:before {
    content: "";
    float: left;
    display: block;
    width: 2px;
    height: 2px;
    background: lighten(@gray, 30%);
    margin: 1px;
  }

  &:after {
    float: right;
  }
}

.sortable__item--empty {
  background: @white;
  border-style: dashed;

  .sortable__title {
    font-size: 14px;
    text-align: center;

    a {
      cursor: pointer;
    }
  }
}

.sortable__title--centered {
  width: 100%;
  max-width: none;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: center;
}

.sortable__service-link {
  text-align: right;
  margin: 15px 0 0;
}

.sortable-placeholder {
  position: relative;
  margin: 40px;
  padding: 0;
  border: none;
}

.sortable__items {
  overflow: hidden;
}

.sortable__item__multi-input {
  display: flex;
}

.sortable__item__multi-input__item {
  flex: 1;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  .custom-select {
    margin: 0;
  }
}

.block-separator {
  margin: 6px 0;
  padding: 7px 0 8px;
  border: 1px dashed @gray-border;
  text-align: center;
}

.block-separator__text {
  display: inline-block;
  padding: 0 1px;
  font-size: 17px;
  line-height: 1.2;
  font-weight: bold;
  border-bottom: 4px solid @red;
}

.block-trigger {
  margin: 10px 0;
  border: 1px dashed @gray-border;

  &:empty {
    display: none;
  }
}

.block-trigger--overlap {
  .block-trigger {
    position: relative;
    margin-top: -25px;
    background: @white;
  }

  .form-group + .block-trigger {
    margin-top: 20px;
  }
}

.block-trigger__text {
  display: block;
  padding: 20px 0;
  text-align: center;
  font-size: 15px;
}

.form-group__list {
  .sortable__items--rev {
    padding: 10px 15px;
    border-radius: 3px;

    .sortable__item {
      height: 42px;
    }

    .sortable__title__header {
      font-size: 14px;
    }

    .sortable__title__count {
      font-size: 14px;
      line-height: 42px;
    }

    .sortable__control {
      margin-top: 5px;
    }
  }
}