//
// Basic print styles
// --------------------------------------------------
// Source: https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

@media print {
  * {
    text-shadow: none !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    background: transparent !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  //a[href]:after {
  // content: " (" attr(href) ")";
  //}

  //abbr[title]:after {
  //  content: " (" attr(title) ")";
  //}

  // Don't show links for images, or javascript/internal links
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
  // Once fixed, we can just straight up remove this.
  select {
    background: #fff !important;
  }

  // Bootstrap components
  .navbar {
    display: none;
  }
  .table {
    td,
    th {
      background-color: #fff !important;
    }
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

}

// Custom styling

@media print {
  html,
  body {
    width: 85%;
  }

  .container {
    width: auto !important;
    padding: 0 !important;
  }

  .no-print,
  .page-footer,
  .header-message,
  .footer-placeholder,
  .top-bar,
  .float-panel,
  .c-confirm__header__control,
  .c-confirm__attn-btn,
  .c-confirm__thumb,
  .c-confirm__addr__map-lnk,
  .hotel-card__rating-row__stars .glyphicon-star--grey,
  .hotel-card__rating-row__stars .glyphicon-star--halfbg:before {
    display: none !important;
  }

  .modal-display {
    overflow: visible !important;
    overflow-y: visible !important;

    .modal-dialog {
      width: 100% !important;
      margin-top: 0 !important;
    }

    .container {
      width: 100% !important;
      min-width: auto !important;
    }
  }

  .icon-preferred-vendor,
  .icon-preferred-car-type,
  .icon-bcd-preferred-vendor,
  .icon-bcd-preferred-car-type {
    width: auto !important;
    margin: 0 !important;
    font-size: 12px;
    white-space: nowrap;
    background: none !important;

    &:after {
      content: ","
    }

    &:last-child:after {
      content: "";
    }
  }

  .icon-preferred-vendor:before,
  .icon-preferred-car-type:before,
  .icon-bcd-preferred-vendor:before,
  .icon-bcd-preferred-car-type:before {
    content: attr(data-title);
  }

  [class^="icon-am"],
  [class*=" icon-am"],
  .ico-tier,
  .ico-company-preferred,
  .ico-agency-tier,
  .ico-agency-preferred,
  .ico-great-rates {
    display: block !important;
    width: auto !important;
    font-size: 0 !important;
    white-space: nowrap !important;
    background: none !important;

    &:after {
      content: ",";
    }

    &:last-child:after {
      content: "";
    }
  }

  [class^="icon-am"]:before,
  [class*=" icon-am"]:before,
  .ico-tier:before,
  .ico-company-preferred:before,
  .ico-agency-tier:before,
  .ico-agency-preferred:before,
  .ico-great-rates:before {
    content: attr(data-print) !important;
    font-size: 12px !important;
  }

  .confirmation-alt {
    .rating-common {
      width: 70px;

      .rating0h:before {
        content: "☆";
      }
      .rating1:before {
        content: "★";
      }
      .rating1h:before {
        content: "★☆";
      }
      .rating2:before {
        content: "★★";
      }
      .rating2h:before {
        content: "★★☆";
      }
      .rating3:before {
        content: "★★★";
      }
      .rating3h:before {
        content: "★★★☆";
      }
      .rating4:before {
        content: "★★★★";
      }
      .rating4h:before {
        content: "★★★★☆";
      }
      .rating5:before {
        content: "★★★★★";
      }
    }
  }
}