
.form-group--inline-inputs {
  .input-daterange {
    width: 100%;
  }
}

.form-group--block-labels {
  .control-label {
    float: none !important;
    display: block;
    width: auto !important;
    margin: 0 0 7px !important;
    pointer-events: none;

    .btn {
      pointer-events: all;
    }
  }
}

.form-group__select-group {
  display: table;
  position: relative;
  width: 100%;
  table-layout: fixed;

  &:hover {
    .form-group__select-group__control {
      display: block;
    }
  }

  & + .form-group__select-group {
    margin-top: 7px;
  }

  .form-control {
    &:focus {
      border-color: @gray-border;
    }

    &[readonly] {
      cursor: default;
    }
  }

  .custom-input {
    display: table-cell;
    position: relative;
    width: 60%;
    padding-left: 5px;
    vertical-align: top;

    .custom-select {
      display: block;
      width: 100%;
    }
  }

  .input-group-label {
    display: table-cell;
    padding: 5px 10px;
    font-weight: bold;
    border: 1px dashed @gray-border;
    border-radius: 3px;
    background: rgba(255,255,255,0.5);
  }

  .input-group-addon {
    .text-overflow();

    display: table-cell;
    color: @gray-darker;
    border: 1px solid @input-group-addon-border-color;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    & + .custom-select .value,
    & + .form-control {
      border-right: none;
      border-radius: 0;
    }
  }

  .form-group__select-group__control {
    display: none;
    position: absolute;
    right: -30px;
    top: 0px;
    padding: 7px;
  }
}

.form-group__select-group {
  .custom-select-focused {
    .value {
      position: relative;
      border-color: transparent;
      box-shadow: 0 0 0 1px @gray;
    }
  }
}

.form-group__select-group__btn {
  margin: 7px 0 0;

  .btn {
    padding: 4px 10px;
    font-size: 12px;
    color: @gray-darker;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.form-group__select-group__wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.form-group__select-group--vertical {
  .custom-select {
    display: block;
    margin-right: 0;

    &:first-of-type {
      .value {
        border-radius: 3px 3px 0 0;
      }
    }

    &:last-of-type {
      .value {
        border-bottom: 1px solid @gray-border !important;
        border-radius: 0 0 3px 3px;
      }
    }
  }

  .value {
    border: 1px solid @gray-border !important;
    border-bottom: none !important;
    border-radius: 0;
  }
}

.form-group--well {
  margin: 6px 15px;
  padding: 10px 12px 10px 0;
  border-radius: 3px;
  border: 1px solid @gray-border;
  background: rgba(255,255,255,0.35);

  .control-label {
    width: 24.5% !important;
  }

  .form-group__sub {
    .clearfix();
    margin: 0;

    .btn {
      margin-left: 20px;
      padding: 6px 0;
      font-weight: bold !important;
    }
  }

  .form-group__select-group + .form-group__sub {
    margin: 8px 0 0;
  }
}

.form-group__active-field {
  margin: -5px 15px 10px 169px;
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  background: rgba(255,255,255,0.25);
  border: 1px dashed @gray-border;
  border-radius: 3px;
}

.form-group__active-field__title {
  display: block;
  padding: 3px 0;
  font-size: 12px;
  color: darken(@gray, 10%);
}

.form-group__active-field__item {
  .clearfix();
  padding: 5px 0;
  font-size: 11px;
  line-height: 1.3;

  & + & {
    border-top: 1px dotted @gray-border;
  }
}

.form-group__active-field__label {
  float: left;
  display: block;
  width: 16%;
}

.form-group__active-field__value {
  float: left;
  width: 83.5%;
}

.form-group__reference {
  .clearfix();
  margin-left: 205px;
  margin-bottom: 14px;

  .well {
    margin-bottom: 0;
    padding: 15px 20px;
    background: @gray-bg;
    border: 1px dashed @gray-border;
  }

  .list-unstyled {
    margin: 0;
    font-size: 12px;

    li {
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.s-selector {
  display: flex;
  border: 1px solid @gray-border;
  border-radius: 3px;
}

.s-selector__values {
  width: 65%;
  padding: 7px 5px 7px 14px;
}

.s-selector__control {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: @gray-bg;
}

.s-selector__list {
  margin: 0;
  padding: 0;
  list-style: none;

  & + & {
    margin-top: 10px;
  }
}

.s-selector__list__title {
  margin-bottom: 2px;
}

.s-selector__list__value {
  margin-left: 15px;

  .btn {
    margin: -2px 0 0 5px;
  }

  .glyphicon {
    margin: 0;
    font-size: 10px;
  }
}
