//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade {
    transition: opacity 0.3s ease-in-out;
  }
  &.show {
    opacity: 1;
  }
  &.fade:not(.show) {
    opacity: 0;
  }
}

.modal-dialog {
  position: relative;
  margin-top: 100px !important;
  width: 600px;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: @modal-content-bg;
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.2),
  0 17px 15px -10px rgba(0,0,0,0.25);
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: none;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal-background;
  background-color: @modal-backdrop-bg;
  opacity: @modal-backdrop-opacity;
  transition: opacity 0.3s ease-out;

  &.show {
    opacity: @modal-backdrop-opacity;
  }

  &.fade:not(.show) {
    opacity: 0;
    transition-delay: 0.3s;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: 15px 20px;
  border-bottom: 1px solid @modal-header-border-color;
  min-height: (@modal-title-padding + @modal-title-line-height);
  background: @gray-bg;
}
// Close icon
.modal-header {
  display: flex;

  .btn-close,
  .close {
    margin: -3px 0 0 auto;
    outline: none;
    font-size: 21px;
    font-weight: @close-font-weight;
    line-height: 1;
    color: @gray;
    background: none;
    border: 0;

    &:hover,
    &:focus {
      color: @gray-darker;
      text-decoration: none;
      cursor: pointer;
    }
    &:after {
      content: "×";
    }
  }
}

// Title text within header
.modal-title {
  margin: 0;
  font-size: 18px;
  line-height: @modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  .clearfix();
  position: relative;
  padding: @modal-inner-padding;
  background: @white;

  .jumbotron{
    color: @gray;
    background: @white;
  }

  .row-wrapper {
    margin: 0;
  }

  .row {
    margin-bottom: 15px;
  }

  .table {
    background: @white;

    .row {
      margin-bottom: 0;
    }
  }

  .row--last{
    margin-bottom: 0;

    label{
      margin-bottom: 0;
    }
  }

  .form-horizontal .control-label {
    width: 184px;
  }

  .form-group__label-offset {
    &:before {
      width: 169px;
    }
  }
}

// Footer (for actions)
.modal-footer {
  margin: 0;
  padding: (@modal-inner-padding - 1) @modal-inner-padding @modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid @modal-footer-border-color;
  background: @gray-bg;

 .btn {
  padding: 7px 25px;
 }

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }

  .inline-help {
    float: left;
    margin-top: 9px;
    color: @gray;
    font-size: 11px;
  }
}

.modal-content__box {
  position: relative;
  padding: 5px 10px;
  margin: -20px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
}

.alert + .modal-content__box {
  margin-top: 0;
}

.modal-content__box--inner-padding {
  padding: 20px;

  .row:last-child {
    margin-bottom: 5px;
  }
}

.modal-content__box--full {
  margin: 0 -20px;
  padding: 20px;
  max-height: none;
  border-left: none;
  border-right: none;
}

.modal-content__well {
  border: 1px solid @gray-border;
}

.modal-content__well--height-limit {
  max-height: 440px;
  overflow-y: auto;
}

.modal-lg {
  width: @modal-lg !important;
}

.modal-md {
  width: @modal-md !important;
}

// Scale up the modal
@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    //width: @modal-md;
    margin: 30px auto;
  }
  .modal-content {
    .box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm { width: @modal-sm; }
}

@media (min-width: @screen-md-min) {
  //.modal-lg { width: @modal-lg; }
}

@media (max-height: 710px) {
  .sm-scrn-adapt {
    .modal-dialog {
      margin-top: 30px !important;
    }

    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.featureFlagModal .btn:focus {
  outline: 1px solid black!important;
}


