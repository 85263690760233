.form-mentions__control {
  min-height: 32px;
  background: @white;
  border-radius: 3px;
  width: 100%;
}

.form-mentions--multiLine {
  .form-mentions__control {
    min-height: 80px;
  }
}

.form-mentions__input {
  .form-control();

  &:disabled {
    background-color: #EDF2F5 !important;
  }
}

.form-mentions__highlighter {
  padding: 7px 13px;
  line-height: normal;
}

.form-mentions__value {
  background: #cfeeff;
}

.has-error .form-mentions__input {
  border-color: @state-danger-text;
}

.form-mentions__suggestions {
  max-height: 245px;
  padding: 4px;
  overflow-y: auto;
  border-radius: 3px;
  box-shadow: 0 0 7px rgba(0,0,0,0.17);
}

.form-mentions__suggestions__item {
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 3px;
}

.form-mentions__suggestions__item--focused {
  color: @white;
  background: @blue;
}
