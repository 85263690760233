

// Keyframes
//

@-webkit-keyframes yellow-highlight {
   0% { background: lighten(@yellow, 7%); }
   100% { background: none; }
}

@keyframes yellow-highlight {
   0% { background: lighten(@yellow, 7%); }
   100% { background: none; }
}

.fade-highlight {
   -webkit-animation: yellow-highlight 3.4s linear 1;
   animation: yellow-highlight 3.4s linear 1;
}

@-webkit-keyframes fade-in-fade-out {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 1; }
}

@keyframes fade-in-fade-out {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 1; }
}

.fade-in-out {
   -webkit-animation: fade-in-fade-out 2s ease-in infinite;
   animation: fade-in-fade-out 2s ease-in infinite;
}

// Override
//

.glyphicon {
  margin-right: 2px;
}

.glyphicon--btn {
  vertical-align: text-top;
  margin-right: 0 !important;
}

.glyphicon--offset {
  margin-right: 5px;
}

pre.well {
  color: @gray-darker;
  font-size: 13px;
}

// Atomic
//

.ml-5 {
  margin-left: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}


// Utility
//

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.text-bg-danger {
  background: lighten(@red, 30%);
}

.text-bg-success {
  background: lighten(@green, 30%);
}

.text-gray {
  color: @gray;
}

.text-xs {
  font-size: 11px !important;
}

.text-sm {
  font-size: 12px !important;
}

.text-std {
  font-size: 13px !important;
}

.text-xl {
  font-size: 15px !important;
}

.mono {
  font-family: monospace;
}

.highlight-blue {
  color: @blue;
}

.highlight-dark-blue {
  color: #062d89;
}

.highlight-orange {
  color: @orange;
}

.highlight-green {
  color: @green;
}

.highlight-dark-green {
  color: darken(@green, 4%);
}

.highlight-red {
  color: @red;
}

a.highlight-red:hover,
.btn.highlight-red:hover,
.btn.highlight-red:active {
  color: darken(@red, 10%);
}

.highlight-dark-red {
  color: darken(@red, 4%);
}

.highlight-purple {
  color: @purple;
}

.highlight {
  background: yellow;
}

.placeholder {
  color: @grayLight;
}

.muted {
  color: @grayLight;
}

a.disabled:not(.btn) {
  cursor: default;
  color: @grayLight;
}

.clickable {
  cursor: pointer;
}

.settings-list__section,
.dropdown-menu__row { // Disabling "#" links in user settings GG-3012
  a[href="#"] {
    color: @gray-light;
    cursor: default;
    text-decoration: none;
  }

  a[href="#"]:hover {
    background: none;
  }
}

.secondary-link {
  color: @gray-darker;

  &:hover {
    color: @gray-dark;
  }
}

.link-unstyled {
  color: @text-color;
  text-decoration: none;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.break-word--no-hyphens {
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}

.static-disabled,
.section-disabled {
  pointer-events: none;
  .opacity(0.57);
}

.select-all {
  .user-select(all);
}

.modal.fade.modal--transparent {
  .opacity(0.7);
}

.row-wrapper { /* Used for wrapping common blocks */
  .clearfix();
  padding: 20px 0;
  margin: 0 25px;
}

.row-wrapper--limited {
  max-width: 1500px;
}

.form-horizontal .row-wrapper > .row {
  margin-left: 0;
  margin-right: 0;
}

.row-wrapper--condensed {
  padding: 12px 0;
}

.row-wrapper--highlighted{
  padding: 20px 25px;
  margin: 0;
  background: @grayBg;
}

.row-wrapper--highlighted-inner {
  padding: 0;
  background: @gray-bg;
}

.row-wrapper--absolute-content {
  position: relative;
  height: 79%;
  overflow: hidden;
}

.row-wrapper-inner {
  .clearfix();
  padding: 14px 0;
}

.row-wrapper--inline {
  margin: 0;
  & > .row > .tabs__nav {
    margin: 0;
  }
}

.row-wrapper.row-alert {
  padding-bottom: 0;

  .alert {
    margin-bottom: 0;
  }
}

.col {
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.col-narrow {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;

  &:empty {
    display: none;
  }
}

.col-right {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;

  &.search-widget {
    margin-left: 15px; // Advertising section
  }
}

.col-group {
  float: left;

  & + .col-group {
    margin-left: 20px;
  }

  .dropdown + .btn {
    margin-left: 5px;
  }
}

.text-offset {
  margin-left: 5px;
}

.inline-icon {
  margin: 0 2px 0 3px;
}

.border-bottom {
  border-bottom: 1px solid @gray-border;
}

.border-top {
  border-top: 1px solid @gray-border;
}

.border-left {
  border-left: 1px solid @gray-border;
}

.border-right {
  border-right: 1px solid @gray-border;
}

.border-none {
  border: none !important;
}

.border-dotted {
  border-bottom-style: dotted;
  border-bottom-width: 1px;
}

.relative {
  position: relative;
}

.inherit {
  position: inherit;
}

.dropdown__unstyled {
  color: @gray;
  background: none;
  border: none;

  &:hover {
    color: @gray-darker !important;
  }

  &:hover,
  &:active,
  &:focus {
    background: none !important;
  }

}

.overflow{
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-max-width {
  max-width: none !important;

  > .row-wrapper {
    max-width: none !important;
  }
}

.large {
  font-size: 110%;
}

.ul-content {
  margin: 17px 0;
  padding-left: 23px;

  li {
    margin-bottom: 5px;
    padding-left: 8px;
  }
}

.error-msg{
  display: none;
  color: @red;
  margin: 5px 0;

  &:empty {
    margin: 0;
  }
}

.error, .has-error {
  .error-msg{
    display: block;
  }
}

.error-label{
  color: @red;
}

.editable-node {
  position: relative;
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,0.2);

    &:after {
      content: "\270f";
      display: block;
      position: absolute !important;
      top: 5px;
      right: -14px;
      width: 0;
      height: 0;
      font-size: 18px;
      .glyphicon();
    }
  }
}

.editable-text {
  position: relative;
  display: inline-block;
  margin: 0;
  padding-left: 3px;
  padding-right: 25px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,0.2);

    &:after {
      content: "\270f";
      display: block;
      position: relative;
      right: -8px;
      width: 0;
      height: 0;
      font-size: 13px;
      .glyphicon();
    }
  }
}

.noneditable-text {
  position: relative;
  display: inline-block;
  margin: 0;
  padding-left: 3px;
  padding-right: 25px;
}

.section-hr {
  margin: 35px 0 30px;
}

.editable-node--title{
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 12px 0;

  .note{
    float: right;
    font-weight: normal;
    font-size: 11px;
    color: @gray-light;
  }
}

.link--unstyled {
  color: @gray-dark;
  text-decoration: underline;
}

.empty-wrapper {
  margin-top: 10%;
  font-size: 15px;
  text-align: center;
}

.empty-wrapper__title {
  font-size: 22px;
}

.empty-wrapper__message {
  margin-bottom: 20px;
  color: @gray;
}

.inline-control {
  display: inline-block;
  padding-top: 7px;
}

.wrapper-warning{
  background: #FDFEEF;
  border: 1px solid @grayBorder;
}

.glyphicon-small{
  position: relative;
  top: 2px;
  margin: 0 5px 0 0;
}

.central-alert{
  text-align: center;
  margin-top: 100px;
}

.row-link{
  display: inline-block;
  margin-top: 10px;
}

.control-delimiter {
  width: 2%;
  padding: 6px 0 0;
  text-align: center;
}

.control-label--full {
  width: auto !important;
}

.control-label--block {
  float: none !important;
  width: 100% !important;
  margin-left: 15px;
  margin-bottom: 8px !important;
}

.form-group.alert {
  padding-left: 20px;
}

.last-field-bumper {
  .clearfix();
  height: 100px;
}

.payment-cards {
  float: right;
  margin: 5px 20px 0 0;
}

div.popup-block {
  position: fixed;
  box-sizing: content-box;
  top: 0px;
  width: 100%;
  height: 30px;
  padding: 15px 0 6px 0;
  font-size: 16px;
  color: @white;
  text-align: center;
  background: @black;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.6);

  > span {
    position: relative;
    display: inline-block;
  }

  .loading-spinner {
    margin-right: 10px;
  }
}

.error-page {
  position: fixed;
  top: 51px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #f8f8f8;

  &__warning {
    .box-sizing(border-box);
    position: absolute;
    left: 50%;
    top: 26%;
    z-index: 1000;
    width: 590px;
    padding: 33px 50px 23px 50px;
    margin: 0 0 0 -295px;
    background: @white;
    border: 1px solid #ebebeb;

    hr {
      margin: 25px 0;
      border-top: 1px solid #ebebeb;
    }

    h3 {
      font-size: 28px;
      line-height: 30px;
      height: 30px;
      margin: 0 0 15px;

      .glyphicon {
        position: relative;
        top: 3px;
        color: #29a0ff;
      }
    }

    p {
      font-size: 18px;
      color: @grayLight;
      line-height: 30px;
    }
  }
}

.login-section {
  width: 430px;
  margin: 70px auto 0;
  padding: 20px 20px 10px;
  background: #f5f5f5;
  border-radius: 3px;

  .form-group + .form-group {
    margin-top: 12px;
  }
}

.login-section__header,
.form-section__header {
  margin: -5px 0 20px;
  padding: 0 0 15px;
  font-size: 18px;
  border-bottom: 1px solid @gray-border;
}

.form-section {
  width: 520px;
  margin: 50px auto 0;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 3px;
}

.form-section__container {
  width: 520px;
  margin: 0 auto;

  .table {
    width: 92%;
    margin: 0 auto;

    th {
      width: 200px;
      background: #f5f5f5;
    }

    th,
    td {
      padding: 5px 12px !important;
    }
  }
}

.form-section__static {
  .clearfix();
  margin: 12px -15px 0 -15px;
}

.a-page {
  margin-top: 20px;
  padding: 15px 20px 60px;
  background: @white;
}

.page-wrapper {
  padding: 15px 30px;
  background: @white;
  border: 1px solid @gray-border;
}

.page-wrapper--conditions {
  .container {
    width: auto;
  }
}

.page-section-title {
  font-size: 18px;
  margin-bottom: 13px;
  padding: 0 0 8px;
  border-bottom: 1px solid @gray-border;
}

.page-section-title__control {
  float: right;
  margin-top: -3px;
}

//////////////////////////////////////////
//////////////////////////////////////////
//////////////////////////////////////////

.container-full {
  height: 100%;
  min-width: 1240px;
}

.container-full--top-fix { /* Fix until .header bottom margin presented */
  margin-top: -16px;
}

.header--alt ~ .container-full--top-fix {
  margin-top: 0;
}

.header-notification--fix {
  .sidebar-fluid,
  .tabs__content--absolute {
    top: 174px;
  }
}

.sidebar-fluid {
  position: absolute;
  top: 111px;
  bottom: 0;
  left: 0;
  width: 360px;
  padding-bottom: 150px;
  border-right: 1px solid @gray-border;
  background-color: @white;
}

.sidebar-fluid--full-width {
  position: static;
  width: 100%;
  border: none;
}

.sidebar-common{
  float: left;
  width: 320px;
  height: 100%;
  padding: 15px 25px;
  background: @gray-bg;
  border-right: 1px solid @gray-border;
}

.sidebar-background{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 320px;
  height: 100%;
  background: @gray-bg;
  border-right: 1px solid @gray-border;
}

.content-fluid {
  margin: 0 0 0 360px;
  padding: 0 0 20px 0;
}

.content-area {
  max-width: 1080px;
}

.header-notification ~ div,
.header-message ~ div {
  .sidebar-fluid,
  .tabs__content--absolute,
  .slide-panel {
    top: 174px;
  }
}

.header-message + .header-message ~ div {
  .sidebar-fluid,
  .tabs__content--absolute,
  .slide-panel {
    top: 237px;
  }
}

.header-notification--fix .header-notification ~ div {
  .sidebar-fluid,
  .tabs__content--absolute,
  .slide-panel {
    top: 236px;
  }
}

.content-area--disabled {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 110px;
    right: 0;
    bottom: 0;
    left: 360px;
    min-width: 880px;
    background: rgba(255,255,255,0.8);
    z-index: 5;
  }

  .page-title {
    position: relative;
    z-index: 11;
  }

  .alert {
    z-index: 11;
  }
}

.content-area--dimmed {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 165px;
    right: 0;
    bottom: 0;
    left: 360px;
    min-width: 880px;
    background: rgba(255,255,255,0.7);
    z-index: 1;
    pointer-events: none;
  }
}

.content-header {
  .clearfix();
  position: relative;
  padding: 20px 25px;
  background: @gray-bg;
  border-bottom: 1px solid @gray-border;

  .pull-left + .btn {
    margin-left: 15px;
  }
}

.content-header--tabs {
  margin-bottom: 0;
  padding: 10px 25px 0;
  border-bottom: none;
  background: @white;

  .content-header__title {
    font-size: 18px;
  }

  .tabs-inner--categories {
    margin: -10px -25px 20px;
    padding: 12px 25px 0;
  }
}

.content-header__title {
  position: relative;
  margin: 0;
  font-size: 22px;
  line-height: 32px;
}

.content-header__details {
  float: right;
  margin-top: 14px;

  .col {
    float: left;
    margin-right: 0;
    margin-left: 40px;
  }
}

.content-header__form {
  float: right;
  margin-right: -15px;

  > .row-wrapper {
    padding: 0;
  }
}

.content-header__form-item {
  float: left;
  margin-left: 7px;

  > .btn .checkbox {
    margin: 1px -5px 0;
    font-size: 12px;
    pointer-events: none;

    .checkbox__icon {
      top: -1px;
    }

  }
}

.content-area__header {
  height: 55px;
  padding: 6px 0 6px 25px;
  border-bottom: 1px solid @gray-border;
  background: @gray-bg;

  .search-widget {
    float: right;
    margin: 3px 25px 0 0;

    .dropdown {
      width: 290px;
    }

    .dropdown-menu {
      min-width: 288px;
    }
  }
}

.required-note{
  float: right;
  color: @gray;
  font-size: 11px;
  margin-top: 7px;
}

.content-header__title--editable {
  display: inline-block;
  cursor: pointer;

  &:hover {
    margin: -1px -5px;
    padding: 0 4px;
    border: 1px solid @gray-border;

    &:after {
      content: "\270f";
      display: block;
      position: absolute;
      top: 5px;
      right: -14px;
      width: 0;
      height: 0;
      font-size: 18px;
      .glyphicon();
    }
  }
}

.page-heading {
  height: 60px;
  padding: 0 15px;
  line-height: 59px;
  background-color: @gray-bg;
  border-bottom: 1px solid @gray-border;

  .dropdown-menu{
    width: 220px;
  }
}

.page-heading__title {
  display: inline-block;
  margin: 0;
  font-size: 19px;

  .glyphicon {
    position: relative;
    top: 2px;
  }
}

.head-info-section {
  float: right;
  margin: -20px 25px 0 0;
  font-size: 15px;
}

.head-info-section__col {
  position: relative;
  float: left;
  margin-left: 36px;
}

.head-info-section__col--attn {
  padding-right: 36px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    right: 0;
    width: 1px;
    height: 59px;
    background: @gray-border;
  }
}

.head-info-section__header {
  display: block;
  height: 30px;
  margin-top: -8px; // For a backward compatibility with a current version
  font-size: 11px;
  color: darken(@gray-text, 6%);
  line-height: 14px;
}

.head-info-section__num {
  font-size: 18px;
}

.content-area__empty{
  position: absolute;
  top: 111px;
  right: 0;
  bottom: 0;
  left: 360px;
  background: @grayBg;
}

.content-empty-text{
  position: absolute;
  top: 50%;
  display: block;
  text-align: center;
  color: @grayLight;
  font-size: 30px;
  .bold();
  line-height: 40px;
  width: 100%;
  margin-top: -20px;
}

.content-area-absolute {
  position: absolute;
  top: 111px;
  right: 0;
  bottom: 81px;
  left: 360px;
  min-width: 830px;
  overflow-y: auto;

  > .row-wrapper {
    max-width: 1030px;
  }

  > .form-horizontal {
    max-width: 1065px;
  }
}

.sticky-controls {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 360px;
  height: 81px;
  padding: 25px;
  text-align: right;
  background: @gray-bg;
  border-top: 1px solid @gray-border;
  z-index: 5;

  .btn {
    min-width: 100px;
    margin-top: 6px;

    & + .btn {
      margin-left: 10px;
    }
  }

  .disabled {
    border-color: darken(@gray-light, 10%) !important;
    background: darken(@gray-light, 10%) !important;
  }
}

.sticky-controls__prompt {
  float: left;
  width: 60%;
  margin: -13px 0 0 -15px;

  .control-label {
    width: 100%;
    margin: 0 0 4px;
    font-size: 11px;
    text-align: left;
    color: @gray;
  }

  .form-control {
    padding: 2px 10px;
    font-size: 12px;
  }

  [class^="col-"],
  [class*=" col-"] {
    width: 100%;
  }
}

.sticky-controls--full-width {
  left: 0;
}

.sticky-controls-bumper {
  height: 100px;
}

.sticky-controls__left{
  float: left;
  margin-top: 1px;
  margin-left: 5px;
  text-align: left;

  .col {
    margin-left: 15px;
  }

  .form-group {
    height: 31px;
  }

  .control-label {
    color: @gray;
  }

  .form-control {
    width: 190px;
  }

  .form-inline {
    position: relative;
    top: 15px;
    margin: -25px 0;

    .checkbox-rev {
      margin-top: 6px;
    }
  }

  .error-msg {
    font-size: 11px;
    margin: 3px 0 0;
  }
}

.sticky-controls__left--form {
  width: 320px;
  margin: 0 !important;

  .control-label {
    .vertical-align();

    width: 100px;
    padding: 0;
  }
}

.sticky-controls--radio {
  width: 300px;
  margin-top: 6px;

  &.pull-right {
    margin-right: 30px;
  }

  .control-label {
    margin-top: 2px;
    padding-right: 0;
  }

  .radio-inline {
    padding-bottom: 0;
  }
}

.sticky-controls__vertical-text{
  display: inline-block;
  margin-top: 10px;
}

.bottom-panel-fix {
  bottom: 0 !important;
}

.breadcrumbs{
  font-size: 11px;
  color: @grayLight;
  margin: 0 0 10px;
}

.footer-nav{
  clear: left;
  padding: 10px 25px;
  background: @gray-bg;
  border-top: 1px solid @gray-border;
  border-bottom: 1px solid @gray-border;
}

.popover-command{
  position: absolute;
  left: 0;
  bottom: 50px;
  z-index: 10;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid @input-border-focus;
  background: @white;

  .btn {
    float: left;
    margin-right: 8px;
  }
}

.popover-command--right{
  right: 0;
  left: auto;
}

.section-title{
  .bold();
  font-size: 15px;
  margin: 7px 0;
}

.section-header__link{
  position: relative;
  top: 2px;
  .bold();
  color: @gray;
  line-height: 32px;
  margin-right: 25px;
}

.form-secondary-title {
  .bold();
  font-size: 14px;
  margin: 10px 0 15px;

  + .checkbox,
  + .radio {
    margin-top: -10px;
  }

  .form__note {
    margin-top: 2px;
  }

  .glyphicon {
    margin-left: 1px;
    font-size: 80%;
    cursor: default;
  }

  .glyphicon-info-sign {
    color: @blue;
  }

  &.border-bottom {
    padding-bottom: 15px;
  }
}

h4 + .form-secondary-title {
  margin-top: 35px;
}

.form-group + .form-secondary-title{
  margin: 35px 0 15px;
}

.checkbox + .form-secondary-title,
.radio + .form-secondary-title {
  margin-top: 25px;
}

.form-group {
  &.border-bottom {
    padding-bottom: 8px;
  }
}

.form-secondary-title__control {
  float: right;
  font-weight: normal;
  font-size: 13px;
}

.form-secondary-subtitle {
  display: block;
  font-weight: normal;
  font-size: 13px;
}

.form-secondary-title__inline-sub {
  color: @gray;
  margin-left: 10px;
}

.form--editing-data{
  .control-label{
    text-align: left;
    font-weight: normal;
    color: @gray;
    width: 165px;
  }
}

.form-group__title {
  margin: 25px 0 8px;
  font-size: 13px;
  font-weight: bold;
}

.form__note {
  float: right;
  margin-top: 11px;
  font-size: 12px;
  font-weight: normal;
  color: @gray;
}

.form__section{
  margin: 0 0 18px;
}

.error-tooltip{
  border-bottom: 1px dotted @red;
}

.info-tooltip {
  display: inline;
  color: @blue;
  font-size: 11px;
}

.tooltip.tooltip-modal {
  z-index: 2000;
}

.dropdown-search{
  padding: 10px;
  margin: -5px 0 15px 0;
  min-width: 200px;
  background: @grayBg;
  border-bottom: 1px solid @grayBorder;
}

.result-block {
  margin: 20px;
  padding: 20px 20px 0;
  background: @gray-bg;
  border: 1px solid @gray-border;

  pre {
    margin: 0;
    padding: 0;
    font-size: 14px;
    border-radius: 0;
    background: none;
    border: none;
  }
}

.sidebar__filter-group{
  padding: 10px 0 0 0;
  border-top: 1px solid @grayBorder;
}

.filter__title{
  .bold();
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 8px;
}

.modal__ad-title {
  margin: 0 0 15px;
  font-size: 16px;
}

.ad-spec-item {
  margin: 0 0 15px;
  font-size: 12px;
}

.upload-box{
  overflow-y: scroll;
  height: 423px;
  padding: 12px;
  background: @white;
  border: 3px dashed @grayBorder;
  border-radius: 6px;
}

.upload__instructions{
  .vertical-align();
  text-align: center;
  color: @gray;
  font-size: 16px;
  line-height: 1.6em;
}

.upload-ads{
  margin-left: -10px;
}

.loading-ads-item{
  position: relative;
  float: left;
  text-align: center;
  color: @gray;
  width: 47%;
  height: 125px;
  margin: 0 0 10px 10px;
  padding: 10px;
  background: @grayBg;
  border: 1px solid @grayBorder;

  .progress{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 100%;
    margin: 0;
  }
}

.modal-content__box{
  .progress{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 100%;
    margin: 0;
  }
}

.loading--file-name{
  .text-overflow();
  display: inline-block;
  max-width: 203px;
}

.uploaded-ads-item{
  position: relative;
  float: left;
  width: 225px;
  height: 125px;
  margin: 0 0 10px 10px;
  padding: 10px;
  border: 1px solid @grayBorder;
}

.ad-preview{
  display: inline-block;
  text-align: center;
  min-width: 50px;
  padding: 3px;
  background: @grayBg;

  img{
    display: inline-block;
    max-width: 100px;
    max-height: 40px;
  }
}

.uploaded-ad__wrap{
  position: absolute;
  left: 10px;
  bottom: 5px;
  width: 205px;
}

.uploaded-ad__wrap-centered{
  position: absolute;
  left: 10px;
  bottom: 36%;
  width: 90%;
}

.uploaded-ad__info{
  .text-overflow();
  display: block;

  &.highlight-red {
    white-space: normal;
  }
}

.ads-item--error{
  background: #FDF1F0;
}

.row-breadcrumbs {
  margin: 20px 25px 5px;
}

.expandable-section {
  border: 1px solid @gray-border;
  margin-top: -1px;

  & + h4 {
    margin-top: 30px;
  }
}

.expandable-section__header {
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  background: @white;
  border-bottom: 1px solid @gray-border;
  cursor: pointer;
  .user-select(none);

  &:hover {
    background: @gray-bg;
  }

  .caret {
    margin: -2px 5px 0 0;
  }
}

.expandable-section__content {
  padding: 14px 15px 5px 15px;
}

.expandable-section--collapsed {
  .expandable-section__content {
   display: none;
  }

  .expandable-section__header {
    border-bottom: none;
  }

  .caret {
    .rotate(-90deg);
  }
}

.block-lnk {
  display: block;
  margin-bottom: 10px;
  padding: 35px 0;
  text-align: center;
  font-size: 15px;
  border: 1px solid @gray-border;
}

.float-content-screen {
  position: absolute;
  top: 25px;
  bottom: 0;
  width: 75%;
}

.float-content-bar {
  position: absolute;
  top: 25px;
  bottom: 0;
  right: 0;
  width: 25%;
}

#content-editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.float-content-screen__header + #content-editor {
  top: 64px;
}

.float-content-screen__header {
  height: 64px;
  padding: 15px 20px 0 10px;
  border: 1px solid @gray-border;
  border-right: none;
  background: darken(@gray-bg, 3%);

  .form-group {
    .clearfix();
    margin: -7px -10px 0 0;
    font-size: 12px;
    background: rgba(255,255,255,0.6);
    border: 1px dashed @gray-border;

    label {
      display: block;
    }

    .checkbox {
      margin: 5px 0;
    }

    .checkbox__icon {
      left: -4px;
      top: 8px;
    }

    .muted {
      display: block;
      margin-top: 1px;
      font-size: 11px;
      color: @gray-dark;
    }
  }
}

.float-content-screen__title {
  float: left;
  margin: 4px 0 0 11px;
}

.float-content--top-fix {
  top: -1px;
}

.empty-block {
  border: 1px dashed @gray-border;
  text-align: center;
  padding: 30px 5px;
}

.page-title {
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 6px;
}

.page-title__delimiter {
  display: inline-block;
  position: relative;
  top: -2px;
  padding: 0 9px;
  font-size: 12px;
}

.page-title__highlight {
  padding: 5px 7px;
  color: #8d4f1b;
  background: #ffdb8c;
  border-radius: 3px;
}

.list-nav {
  margin: 12px 0 35px;
}

.list-nav__header {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
}

.list-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2;
  font-size: 14px;

  a {
    .secondary-link();
  }
}

.list-nav__list--matched {
  position: relative;
  z-index: 10;
  text-shadow: 0 0 8px rgba(0,0,200,0.28)
}

.list-nav__list--pointed {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: -15px;
    width: 7px;
    height: 7px;
    background: @orange;
    border-radius: 10em;
  }
}

.multifield-block {
  position: relative;
  .form-group__tail-control {
    position: absolute;
    right: 12px;
    top: 0;
  }
}

.explanatory {
  clear: both;
  width: 90%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  margin: 0px;
  padding: 0px;
}

// Widgets
//--------------------------------------------
//--------------------------------------------


// Floated tip
//

.floated-tip {
  position: absolute;
  top: 11px;
  right: 15px;
}

// Small modal that appears on 'Rename'
//

.rename-modal {
  position: absolute;
  top: 5px;
  left: 0;
  padding: 10px 15px;
  background-color: #E3EFFC;
  border: 1px solid #87B6F4;
  z-index: 5;

  .btn {
    margin-left: 5px;
  }
}


// Dropdown (language selector)
//

.drop-down-header {
  display: block;
  margin: 0 10px 4px 10px;
  font-weight: bold;
  white-space: nowrap;
}

.dropdown-menu .language {
  li {
    white-space: nowrap;
  }

  [class^="icon-"] {
    position: relative;
    top: 2px;
    margin: 0 6px 0 -3px;
  }
}

.dropdown-toggle:not(.input-group__inline-control) {
  [class^="icon-flag"] {
    float: left;
    margin-right: 5px;
  }

  &:after {
    content: "";
    .caret();
    margin-left: 5px;
  }
}

.dropdown--caret-hover{
  .caret{
    display: none;
  }
}

tr:hover{
  .dropdown--caret-hover{
    .caret{
      display: inline-block;
    }
  }
}

// Form error tooltips
//


.form-group__error {
  display: none;
  position: absolute;
  top: 50%;
  right: 4px;
  max-width: 160px;
  padding: 2px 6px 3px;
  color: @red;
  background: @alert-danger-bg;
  border-radius: 3px;
  z-index: 5;
  -webkit-transform: translate(100%, -50%);
  -ms-transform: translate(100%, -50%);
  transform: translate(100%, -50%);

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -8px;
    top: 50%;
    margin-top: -4px;
    border-top: 4px solid transparent;
    border-right: 4px solid @alert-danger-bg;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.form-group__error--top {
  top: -8px;
  right: auto;
  left: 20px;
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);

  &:before {
    top: auto;
    bottom: -8px;
    left: 8px;
    margin-top: 0;
    border-top: 4px solid @alert-danger-bg;
    border-right: 4px solid transparent;
  }
}

.form-group__error--bottom {
  top: auto;
  bottom: -8px;
  right: auto;
  left: 20px;
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);

  &:before {
    top: -8px;
    left: 8px;
    margin-top: 0;
    border-right: 4px solid transparent;
    border-bottom: 4px solid @alert-danger-bg;
  }
}

.has-error {
  .form-group__error {
    display: block;
  }
}

.form-group__tags + .form-group__error {
  margin-top: -10px;
}

.form-group--sub-link + .form-group__error {
  margin-top: -14px;
}


// Popovers
//

.popover__editing{
  position: absolute;
  left: 0;
  top: 22px;
  padding: 20px;
  background: lighten(@input-border-focus, 28%);
  border: 1px solid @input-border-focus;
  z-index: 2;

  &:after{
    content: "";
    display: block;
    position: absolute;
    left: 10px;
    top: -7px;
    border-bottom: 7px solid @input-border-focus;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  .form-control{
    width: 300px;
  }

  .btn,
  .form__saving-loader{
    margin-left: 10px;
  }

  .popover-btn-group{
    margin-left: -10px;
  }
}

.popover__col{
  display: table-cell;
  padding: 0 20px 10px 0;
}

.popover__subtitle{
  font-size: 11px;
}

.td-control {
  float: right;
  visibility: hidden;

  .btn {
    padding-right: 10px;
    padding-left: 10px;
  }

  .btn-xs {
    font-weight: bold !important;
  }
}

.form__saving-loader{
  display: inline-block;
  position: relative;
  padding-left: 42px;

  .spinner{
    position: absolute;
    top: -6px;
    left: 0;
    width: 32px;
    height: 32px;
  }
}

.title-with-glyphicon{
  h4{
    margin-top: 0;
  }

  .glyphicon{
    float: left;
    font-size: 22px;
    margin-right: 14px;
    margin-left: 4px;
  }
}

.modal-content__wrapper{
  position: relative;
  margin: 0 0 20px;
}

.modal-content__title {
  font-size: 14px;
  color: @gray;
  font-weight: bold;
  text-transform: uppercase;
}

.modal__progress-bar{
  margin-bottom: 0;
}

.modal__errors-table{
  margin: -21px;
  width: 560px;

  >tbody>tr>td,
  >thead>tr>th{
    padding: 8px;
    border: 1px solid @grayBorder;
  }
}

.prefooter-controls-bumper {
  height: 120px;
}

.prefooter-controls{
  position: absolute;
  bottom: 0;
  width: 100%;
  min-width: 1240px;
}

.modal--subtitle{
  .bold();
  font-size: 14px
}

.disabled .sortable__items .sortable__item {
  cursor: default;
  opacity: 0.7;

  &:hover {
    background: @grayBg;
  }
}

.sortable-dragging,
.dragging-control {
  cursor: move;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.promo-modal textarea {
  width: 100%;
  height: 77px;
}

.empty-companies {
  text-align: center;
  color: #999999;
  margin-top: 60px;
  font-size: 16px;
}


// Companies settings list
//

.settings-list__section{
  .clearfix();
  margin: 0 0 10px;
  padding: 0;
  border-bottom: 1px solid @grayLighter;
}

.settings-list__section__title{
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  width: 160px;
}

.settings-list__section__items{
  .list-unstyled();
  overflow: hidden;
  margin: 0;

  li{
    float: left;
    width: 140px;
    margin: 0 0 7px;
    padding: 0 10px 0 0;
  }
}

.form-group--condensed{
  margin-bottom: 8px;
}

.form-control--label{
  position: relative;
  color: @white;
  line-height: 32px;
  padding: 4px 6px;
  background: @blue;
  white-space: nowrap;
  border-radius: 3px;

  &:after{
    content: "";
    position: absolute;
    left: -4px;
    top: 8px;
    display: block;
    height: 0;
    width: 0;
    border-right: 4px solid @blue;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}

.label--target{
  float: left;
  margin-right: 10px;
}

.modal-info-box{
  margin: 25px 0 0;
}

.alert-align{
  margin-left: 9px;
}

.step-navigation{
  .clearfix();
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 4px;

  span{
    position: relative;
    display: block;
    float: left;
    line-height: 32px;
    text-align: center;
    width: 33.333%;
    border-left: 1px solid @gray-border;

    &:first-child{
      border-left: 0;
    }

    &.active{
      color: @white;
      background: @gray;
      z-index: 1;

      &:after{
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        width: 0;
        height: 0;
        border-left: 8px solid @gray;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
      }
    }
  }
}

.modal-static-container{
  height: 220px;
}

.progress__static-container{
  margin: 200px auto 0 auto;
  width: 50%;
}

.pencil-edit__table{
  position: absolute;
  top: 0;
  right: 8px;
  color: @gray;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: @grayDark;
  }
}


// UI Lib
//

.ui-lib-body {
  background: @gray-light;
  min-height: 100%;
}

.ui-lib-body__container {
  height: 100%;
  padding-bottom: 150px;
  background: @white;
  border-left: 1px solid @gray-border;
  border-right: 1px solid @gray-border;
}

.ui-lib-row {
  .clearfix();
  padding: 20px 0;

  + h3 {
    padding-top: 20px;
  }
}

.ui-lib-list {
  display: none;
  width: 1000px;
  margin: 10px auto 0 -15px;
  padding: 0 0 25px 0;
  font-size: 0px;

  &> div {
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 5px;
    width: 120px;
    font-size: 10px;
    line-height: 12px;
    vertical-align: top;
    text-align: center;
    text-decoration: none;
  }

  .glyphicons {
    font-size: 29px;
  }

  strong {
    display: block;
    margin: 6px 0 4px 0;
    line-height: 1.4em;
    font-weight: normal;
  }
}

.ui-lib-list--opened {
  .ui-lib-list {
    display: block;
  }

  .ui-lib-list__lnk {
    display: none;
  }
}

.ui-lib-list__lnk {
  display: block;
  padding: 10px 0;
  margin-bottom: 15px;
  font-size: 14px;
}

.spinner-trivial-block {
  background: url('../img/select2-spinner.gif') no-repeat 0 0;
  margin: 8px 0px 0px 8px;
  height: 24px;
  width: 24px;
}

.spinner-wrap {
  position: relative;
  display: inline-block;

  .spinner{
    position: absolute;
    left: -10px;
    top: -14px;
    height: 20px;
    width: 20px;
  }
}

.form-control-title{
  display: inline-block;
  margin: 5px 0 6px 220px;
}

.col-offset-small{
  margin-left: 10px;
}

.form-control-link{
  display: inline-block;
  float: right;
  font-size: 12px;
  margin: 7px 0;
}

.text-button-aligned {
  line-height: 34px;
}

.well-section {
  border: 1px solid @gray-border;

  .table-common {
    td {
      background: lighten(@gray-bg, 5%);
    }
  }
}

.well-section__header {
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid @gray-border;

  .search-widget {
    margin-right: 4px;
  }

  .search-widget--right {
    margin-right: 15px;
    margin-left: 0;
  }

  .tabs__nav {
    margin: 25px 0 -16px 0;
    padding-left: 0;

    .inner-tabs__item {
      background-color: transparent;
    }

    .tabs__nav--active {
      background: @gray-bg !important;
      border-bottom-color: @gray-bg !important;
    }
  }
}

.well-section__content {
  padding: 10px 0;
  background: @gray-bg;

  .search-widget {
    .input-group,
    .form-control {
      margin-right: 0 !important;
    }
  }

  .row-wrapper {
    margin: 0 15px;
    padding: 7px 0;
  }

  .block-trigger {
    background: rgba(255,255,255,0.9);
  }
}

.well-section__footer {
  margin-top: 5px !important;
  padding: 15px 0 !important;
  border-top: 1px dashed @gray-border;
}

.well-section__control {
  display: flex;
  margin-top: 25px;
  padding: 25px 0 0;
  flex-direction: column;
  gap: 18px;
  border-top: 1px solid @gray-border;
}

.well-section__control__input {
  height: auto;
  padding: 20px 10px;
  font-size: 14px;
  text-align: center;
  border: none;
  background: #EDF2F7;
  border-radius: 8px;

  &::placeholder {
    color: @gray-darker;
    font-weight: bold;
  }

  &:focus,
  &:active {
    background: darken(#EDF2F7, 5%);
    caret-color: transparent;
    box-shadow: inset 2px 2px 3px rgba(0,0,0,0.12);
  }
}

.well-section__control__hint {
  font-size: 12px;
  color: @gray-dark;
}

.well-section--white {
  .well-section__header,
  .well-section__content {
    background: @white;
  }
}

.notify-banner {
  margin: 0 0 23px 0;
  font-size: 12px;
  padding: 10px 25px;
  background: lighten(@yellow, 30%);
  border: 1px solid @orange;
  border-left: 4px solid @orange;

  .btn-link {
    color: darken(@orange, 33%);
    padding: 0;
    font-size: 12px;
    line-height: 1.3;
    border-bottom: 1px dotted darken(@orange, 33%);

    &:hover {
      color: darken(@orange, 19%);
    }
  }
}

.email-offer__item {
  margin: 20px 0;
  padding: 25px 40px;
  border: 1px solid @gray-border;
  background: @white;
}

.email-offer__item--selected {
  border: 4px solid #F6791D;

  .email-offer__aside {
    .btn {
      display: none;
    }
  }

  .email-offer__item__booking-message {
    display: none;
  }

  .email-offer__item__notification {
    display: block;
  }
}

.email-offer__item--note {
  margin-bottom: -5px;
  padding: 15px 40px;
  font-size: 12px;
  color: @blue;
  border: 1px dotted lighten(@blue, 22%);
}

.email-offer__item__booking-message {
  margin: 5px 10px 0 0;
  font-size: 11px;
  color: @red;
}

.email-offer__item__notification {
  display: none;
  width: 215px;
  margin: 15px 0 0;
  padding: 5px 10px;
  color: @white;
  background: #F6791D;
  border-radius: 2px;

  p {
    margin: 2px 0 3px;
    font-size: 11px;
  }

  .glyphicon {
    margin-right: 3px;
  }
}

.email-offer__label {
  display: inline-block;
  width: 180px;
  vertical-align: top;
}

.email-offer__aside {
  margin: 7px 0 0;

  .btn {
    width: 215px;
    margin: 15px 0;
  }
}

.inline-block-group {
  display: table;
}

.inline-block-group__col {
  display: table-cell;
}

.inline-block-group__col:not(:first-child):not(:last-child) {
  padding: 0 4px;
}
