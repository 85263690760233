// Tables
//

.table.table-unstyled {
  margin-bottom: 0;
  table-layout: fixed;
  background: none;

  td, th {
    padding: 1px 0;
    border: none;
  }
}

.table {
  .hover-toggle {
    display: none;
  }

  td:hover {
    .hover-toggle {
      display: inline;
    }
  }
}

.table-spinner {
  position: relative;
}

.table-inline-inputs {
  width: 100%;
  table-layout: fixed;

  td {
    &:first-child {
      padding-left: 0;
    }

    padding-left: 10px;
  }

  .form-group {
    margin-bottom: 0;

    .control-label {
      display: none;
    }
  }
}

table.table-list {
  > tbody > tr > td {
    width: 20% ;
    padding: 4px 9px;
    vertical-align: top;
    border: none;

    &:first-child {
      width: 8%;
    }
  }

  > tbody > tr:first-child td {
    padding-top: 17px;
  }

  > tbody > tr:last-child td {
    padding-bottom: 17px;
  }

  .checkbox {
    font-weight: normal;
    margin: 0;
  }
}

.table-list__item {
  display: inline-block;
  position: relative;
  padding: 4px 45px 4px 10px;

  &:hover {
    background: @hover-bg;

    .dropdown-toggle {
      display: block;
    }
  }

  .dropdown-toggle {
    display: none;
  }
}

.table-list__dropdown {
  position: absolute;
  right: 2px;
  top: 2px;

  .dropdown-toggle {
    padding: 2px 9px;
    border: none;
    background: none;

    &:hover,
    &:focus {
      box-shadow: none;
      background: none;
    }
  }

  &.open {
    .dropdown-toggle {
      display: block;
    }
  }
}

.table-list__title {
  &:hover {
    text-decoration: underline;
  }
}

.dropdown-inline-group{
  .dropdown{
    display: inline-block;
  }
}

table.table-common{
  margin-bottom: 0;

  > thead > tr > th,
  > tbody > tr > td{
    border: 1px solid @gray-border;
  }

  > thead > tr > th{
    color: @text-color;
    background: @gray-bg;

    &:hover {
      background: darken(@gray-bg, 2%);
    }

    &.sorted{
      background: darken(@gray-bg, 5%);
    }

    .caret{
      float: right;
      margin-top: 8px;
    }

    .asc,
    .desc{
      position: relative;

      &:after{
        content: '';
        position: absolute;
        right: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }

    .asc:after {
      top: 7px;
      border-bottom: 4px solid @text-color;
    }

    .desc:after {
      top: 6px;
      border-top: 4px solid @text-color;
    }
  }

  > thead > tr > td {
    border: 1px solid @gray-border;
    background: @gray-bg;
  }

  > tbody > tr > td{
    &.table-empty-msg{
      text-align: center;
      padding: 50px 0;
      background: @white;

      p{
        margin: 0;
      }
    }
  }

  th {
    background: @gray-bg;
    border: 1px solid @gray-border;
  }

  .editable-node {
    display: inline-block;
    padding-left: 3px;
    padding-right: 20px;
    width: 100%;

    &:hover {
      &:after {
        display: block;
        top: 50%;
        right: 14px;
        margin-top: -6px;
        font-size: 12px;
      }
    }
  }

  .btn-xs {
    min-width: 50px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: relative;
    top: 3px;
    margin: 0;
  }

  pre:empty {
    display: none;
  }
}


.table-common {
  th div.clickable {
    padding-right: 11px;
  }
}

table.table--rows{
  border: 1px solid @gray-border;
  background: @white;

  > thead > tr > th,
  > tbody > tr > td{
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid @gray-border;
  }

  .sortable-dragging{
    outline: 1px solid @gray-border;
  }

  .dragging-control{
    text-align: center;

    .glyphicon{
      font-size: 16px;
      color: @gray-light;
    }
  }
}

.table-common-footer{
  background: @gray-bg;
}

.table-common-footer--pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: @gray;
  padding: 6px 25px 6px 8px;

  .dropdown{

    .dropdown-toggle{
      color: @gray-dark;
    }

    .dropdown-menu{
      top: auto;
      bottom: 100%;
      min-width: 40px;
    }
  }

  .separator-vertical{
    margin: -6px 12px -6px 5px;
  }

  .dropdown__unstyled {
    .dropdown-toggle {
      margin-left: 4px !important;
      padding: 2px 8px 3px 6px;
      background: none;
      border: none;

      .caret {
        top: 10px;
        right: 5px;
      }
    }
  }
}

.pagination__page-input {
  display: inline-block !important;
  vertical-align: middle !important;
  width: 30px !important;
  height: 23px !important;
  padding: 0 !important;
  text-align: center;
}

.pagination--pages{
  .bold();
  display: inline-block;
  height: 23px;
  margin-left: 5px;
  padding-left: 12px;
  line-height: 23px;
  border-left: 1px solid @gray-border;
}

.pagination__page-selector {
  display: inline-block;
  height: 23px;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid @gray-border;
}

.pagination__control {
  float: right;
  height: 23px;
  margin: 0 -10px 0 13px;
  padding-left: 7px;
  border-left: 1px solid @gray-border;

  .glyphicon {
    float: left;
    margin: 1px 0 0 4px;
    padding: 4px;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
}

.table-common__toggle {
  visibility: hidden;
}

.table-common tr:hover {
  .table-common__toggle {
    visibility: visible;
  }
}

.pagination__control--arrows a {
  float: left;
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 3px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  background: @gray-lighter;
  border-radius: 3px;
  outline: none;

  .glyphicon {
    float: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &.disabled {
    .opacity(0.7);

    .glyphicon {

      &:hover {
        cursor: default;
        color: inherit;
      }
    }
  }
}

.table-common__name-wrapper{
  position: relative;
  padding: 1px 0;
  min-height: 18px;

  a{
    color: @text-color;

    &:hover{
      text-decoration: underline;
    }
  }

  .glyphicon-pencil{
    display: none;
    float: right;
    margin-top: 3px;
  }
}

.table-empty-message {
  text-align: center;
  font-size: 16px;
  padding: 25px 0 !important;
  color: @gray;
  background: @gray-bg !important;
}

.empty-message {
  text-align: center;
  font-size: 16px;
  padding: 25px 0 !important;
  color: @gray;
}

.table-empty-message--error {
  color: @state-danger-text;
  background: @state-danger-bg !important;
}

.table--light {
  font-size: 12px;

  th {
    padding: 5px 4px !important;

    div {
      padding: 3px 0;
    }

    &:not(.sorted) {
      .clickable:hover {
        color: @gray-dark;
      }
    }
  }

  > tbody > tr {
    td {
      padding: 6px 4px;
      cursor: default;
      transition: background 0.3s ease;

      .glyphicon {
        font-size: 11px;
      }
    }

    &:hover:not(.warning) {
      > td {
        background: #EFF5FE;
      }
    }
  }

  .dropdown .btn-group-xs {
    margin: -5px 0;
  }

  .glyphicon-plus.clickable,
  .glyphicon-minus.clickable {
    color: @gray-dark;
  }
}

.table--inner {
  td, th {
    padding: 2px 6px !important;
    border: none !important;
  }
}

.table-folded {
  td {
    padding: 0 50px 4px 0;
    vertical-align: top;
    background: transparent;
  }

  .table {
    margin-bottom: 0;
    margin-left: 30px;
    background: transparent;

    th {
      padding-top: 0 !important;
      border-top: none;
    }

    td {
      padding: 3px 15px;
    }
  }
}

.table-selectors {
  width: 100%;
  margin: 0 0 12px 0;

  td {
    min-width: 180px;
    padding: 0 7px 7px 0;
  }
}

.table-selectors__item {
  padding: 8px 8px 8px 27px !important;
  border: 1px dashed @gray-border;
  border-radius: 4px;

  input[type="radio"] {
    margin-right: 6px;
    outline: none;
  }

  &:hover {
    background: lighten(@blue, 48%);
  }
}

.table-selectors__item--selected {
  background: lighten(@blue, 48%);
  border: 1px solid @blue;
}

.table-selectors__title {
  border-bottom: 1px dotted @gray-border;
}

.table-common{
  tr{
    &:hover{
      td{
        background: #F9FBFE;

        .table-common__name-wrapper:hover{
          .glyphicon-pencil{
            display: inline-block;
          }

          .table-common__name{
            min-height: 18px;
            padding: 1px 2px;
            margin: -2px 26px -2px -3px;
            border: 1px solid @gray-border;
          }
        }

        .td-control {
          visibility: visible;
        }
      }
    }

    &.selected{
      td{
        background: #E8F2FD;
      }
    }

    &.error{
      td{
        background: #FDF5F4;

        .table-common__toggle {
          visibility: visible;
        }
      }
    }

    &.attn {
      td{
        background: @state-warning-bg;
      }
    }
  }
}

.table-common--no-hover {
  tr {
    &:hover {
      td {
        background: none !important;
      }
    }
  }
}

.table-common--small{
  tr:hover{
    td{
      background: none;
    }
  }

  &.table-common>thead>tr>th:hover{
      background: #fafafa;
  }
}

.table-editable-cell {
  cursor: pointer;

  &:hover {
    &:before {
      content: "edit";
      float: right;
      margin: 4px 4px 0 2px;
      color: @blue;
      font-size: 11px;
    }

    &:after {
      .glyphicon();
      content: "\270f";
      float: right;
      margin-top: 4px;
      color: @blue;
    }
  }
}

.table__bg-highlight {
  background: #f8faf5;

  &.muted {
    color: @gray-darker;
  }
}

.table-label {
  margin: 3px 0 1px;
  white-space: nowrap;

  .glyphicon {
    margin-left: 3px;
    font-size: 13px !important;
    color: @gray-dark;
  }
}

.table-label__text {
  float: left;
  display: inline-block;
  padding: 1px 2px;
  line-height: 11px;
  font-size: 10px;
  color: @white;
  text-transform: uppercase;
  font-weight: bold;
  background: @gray-dark;
  border-radius: 3px;
  letter-spacing: 0.02em;
}

.table-label--red {
  .glyphicon {
    margin-top: -1px;
    color: @red;
  }

  .table-label__text {
    background: @red;
  }
}

.table-label--orange {
  .glyphicon {
    margin-top: -1px;
    color: @orange;
  }

  .table-label__text {
    background: @orange;
  }
}

.table-label--border-orange {
  .glyphicon {
    color: #f16f00;
  }

  .table-label__text {
    color: #f16f00;
    background: transparent;
    border: 1px dashed #f16f00;
  }
}

.table-label--border-blue {
  .glyphicon {
    color: @blue;
  }

  .table-label__text {
    color: @blue;
    background: transparent;
    border: 1px dashed @blue;
  }
}

.muted:not(.table__bg-highlight) {
  .table-label {
    .glyphicon {
      display: none;
    }
  }

  .table-label__text {
    .muted();
    background: transparent !important;
    border: 1px solid @grayLight;
  }
}

.table-sm {
  font-size: 12px;

  th,
  td {
    padding-right: 5px !important;
  }
}

.table__item-sub-header {
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px dotted #d4d5d6;
}

.table {
  .sorted {
    color: @red;
  }

  .asc,
  .desc{
    position: relative;

    &:after{
      content: '';
      display: inline-block;
      position: relative;
      margin-left: 5px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  .asc:after {
    top: -1px;
    border-bottom: 4px solid @red;
  }

  .desc:after {
    top: -2px;
    border-top: 4px solid @red;
  }

  th {
    &:not(.sorted) {
      .clickable:hover {
        color: @gray-darker;
      }
    }
  }
}
