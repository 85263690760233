// Bootstrap/React tagsinput
.tags-input {
  display: inline-block;
  padding: 4px 6px;
  vertical-align: middle;
  line-height: 22px;
  //color: #555;
  cursor: text;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  background-color: @input-bg;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  box-shadow: none;
  .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

  &.has-error {
    border-color: @red;
  }

  &.focused {
    border-color: @input-border-focus;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: @input-bg-disabled;
  }

  .tag {
    float: left;
    display: block;
    color: #fff;
    background-color: #2e8ccf;
    border: 1px solid darken(#2e8ccf, 8%);
    border-radius: @border-radius-base;
    padding: 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;

    [data-role="remove"] {
      margin-left: 7px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }

    &.disabled [data-role="remove"] {
      display: none;
    }

    &.has-error {
      background-color: @brand-danger;
      border-color: @red;
    }

    .tooltip-area {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }
  }

  input {
    width: 100% !important;
    height: 22px !important;
    margin: 0;
    padding: 0 6px;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    min-width: calc(100% + 2px);
    max-width: 120%;
    max-height: 300px;
    overflow-y: auto;
    transform: translate3d(-7px, 4px, 0) !important;
    inset: auto !important;

    .glyphicons {
      margin-right: 6px;
    }
  }

  .input-group__inline-control {
    position: static;
  }
}

.has-error .tags-input {
  border-color: @red;
}

.tags-input--with-tooltip {
  .tag {
    position: relative;
  }
  span[data-role="remove"] {
    position: relative;
    z-index: 2;
  }
}
