//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  //vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; 20px; @font-size-base; @line-height-base; @border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus {
      outline: 0;
      //.tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    .opacity(.6);
    .box-shadow(none);
    filter: grayscale(10%);
  }
}

.btn-default:not(.btn-secondary) {
  &.disabled,
  &[disabled] {
    color: @gray !important;
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default, .btn-light {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.btn-light:is(:hover, :focus) {
  background: #e1e7eb;
}

.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
.btn-secondary {
  .button-variant(@btn-secondary-color; @btn-secondary-bg; @btn-secondary-border);
}
// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// Button hovers
// --------------------------------------------------

.btn-default:hover {
  background-color: @btn-default-bg-hover;
}

.btn-primary:hover {
  background-color: @btn-primary-bg-hover;
}

.btn-secondary:hover {
  background-color: @btn-secondary-bg-hover;
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal !important;
  cursor: pointer;
  border-radius: 0;

  &,
  &:active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    box-shadow: none !important;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }

  &.btn-link--narrow {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.btn-link--inline {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    vertical-align: inherit;
    border: none;
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; 15px; 1.4; 3px);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @border-radius-small);
  font-weight: normal !important;
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

// Button modifiers

.btn-default--outline {
  border: 1px solid @gray-border;
  color: @blue;
  background: rgba(255,255,255,0.4);

  &:hover {
    background: rgba(255,255,255,0.8);
  }
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.btn .glyphicon + span {
  margin-left: 4px;
}

// Button with a spinner
// --------------------------------------------------

.btn__spinner {
  .loading-spinner();

  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 22px;
  height: 22px;
  margin-left: -11px;
  margin-top: -11px;
  border-width: 4px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.btn__text {
  position: relative;
}

.btn--in-progress {
  position: relative;
  pointer-events: none;

  .btn__text {
    visibility: hidden;
  }

  .btn__spinner {
    display: block;
  }
}

.btn-default:not(.btn-secondary),
.btn-light:not(.btn-secondary) {
  .btn__spinner {
    border-color: @btn-default-color;
    border-right-color: transparent;
  }
}

.btn-flat {
  padding: 4px 8px;
  color: darken(@gray-dark, 9%);
  font-size: 12px;
  line-height: 1;
  background: darken(@gray-bg, 3%);
  border-radius: 4px;

  .glyphicon {
    margin: 0 0 0 7px;
  }

  &:hover {
    color: @gray-darker;
    background: darken(@gray-bg, 9%);
  }
}

.btn-input { // Button with checkbox/radio inside

  .checkbox {
    margin: 0;
  }

  label {
    display: block !important;
    padding: 6px 7px 6px 35px;
    background: @btn-default-bg;
    border: 1px solid @btn-default-border;
    border-radius: 3px;
  }

  &:hover {
    label {
      background: @btn-default-bg-hover;
    }
  }
}

.kb-hint {
  position: absolute;
  top: 3px;
  margin-left: 4px;
  padding: 2px;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #ccc;
  line-height: 1em;
  border-radius: 3px;

  .tabs-inner__item & {
    top: 15px;
    margin-left: 7px;
  }

  .btn__text & {
    top: -4px;
  }

  & + .navbar__counter {
    margin-left: 22px;
  }

  .navbar-default & {
    top: 6px;
  }

  .ag-table__cell-col ~ & {
    position: relative;
    top: -1px;
    margin: 4px 0 0;
  }
}

.kb-tabs-hint {
  top: auto;
  margin-left: 2px;
};
