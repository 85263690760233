.rule-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed @gray-border;

  & + .rule-group__control {
    padding-top: 0;

    .rule-item__control-btn {
      margin-top: 0;
    }
  }

  .well-section {
    position: relative;
    background: #fafdff;
    border: 3px solid #dfeaf1;
    border-radius: 10px;
  }

  .well-section__header {
    display: flex;
    padding: 4px 0 7px 13px;
    border: none;
  }

  .well-section__content {
    padding: 0;
    background: transparent;
  }
}

.rule-item--disabled {
  .rule-group__item {
    background: #f3f3f3;
  }

  .rule-group__heading {
    h4 {
      position: relative;
      margin-top: -1px;
      padding-left: 25px;
      font-size: 13px;
    }

    .glyphicons {
      position: absolute;
      left: 3px;
      top: 50%;
      margin-top: -7px;
    }
  }

  .rule-group__folder {
    background: #fbfbfb;
  }
}

.rule-item--inactive {
  pointer-events: none;
  .opacity(0.35);
}

.rule-item__footer {
  margin-left: 40px;
}

.rule-item__control-btn {
  width: 100%;
  margin: 10px 0 20px;
  padding: 20px;
  text-align: left;
  font-weight: bold !important;
  border: 1px dotted @gray-border !important;
}

.rule-group {
  margin: 2px 12px 12px;
  padding: 12px 44px 5px 17px;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(20,23,42,0.1);

  .rule-group__section-title {
    margin: -4px 0 12px;
  }

  .well-section__header .tabs__nav {
    margin-bottom: -8px;
  }

  .inner-tabs__item {
    margin-right: 3px;
    padding: 3px 8px;
    font-size: 12px;
  }
}

.rule-group--related {
  margin-top: 12px !important;
}

.rule-group__message {
  margin-top: -8px;
  padding-bottom: 8px;

  p {
    margin-bottom: 3px;
  }
}

.rule-group__heading {
  display: flex;
  width: 75%;

  h4 {
    margin: 4px 16px 0 0;
    font-size: 14px;
  }

  .glyphicon,
  .glyphicons {
    margin-right: 5px;
  }

  .form-control {
    width: 300px;
    margin-right: 20px;
  }
}

.rule-group__heading-control {
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: 20px;
  flex-direction: row-reverse;

  > .btn-link:not(.highlight-red) {
    margin-left: 10px;
    background: @gray-bg;
  }

  .text-danger {
    color: @red !important;
  }
}

.rule-group__relative {
  position: relative;
  margin: 10px 0 0 40px;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    bottom: 0;
    left: -28px;
    border-left: 3px solid #dfeaf1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 42px;
    left: -28px;
    width: 28px;
    border-top: 3px solid #dfeaf1;
  }

  &:last-of-type {
    &:before {
      bottom: auto;
      height: 55px;
    }
  }
}

.rule-group__control {
  .clearfix();
  padding: 5px 0 0;

  .btn {
    font-size: 12px;
    font-weight: bold !important;
  }
}

.rule-group__item {
  display: table;
  position: relative;
  width: 100%;
  padding: 5px 5px 5px 15px;
  border: 1px dotted @gray-border;
  background: #fffcf6;
  border-radius: 5px;

  & + & {
    margin-top: 5px;
  }

  .custom-select {
    width: auto;

    &:hover {
      .value {
        background: #fff2d7;
      }
    }

    .value {
      padding-right: 30px;
      color: @gray-darker;
      border: none;
      background: transparent;

      &:after {
        border-top-color: @red;
      }
    }
  }

  .custom-select-disabled {
    pointer-events: none;

    .value {
      color: lighten(@gray, 15%) !important;

      &:after {
        border-top-color: lighten(@gray, 15%) !important;
      }
    }
  }

  .col {
    display: table-cell;
    width: 22%;
    table-layout: fixed;
    vertical-align: top;

    &:last-of-type {
      width: auto;
    }
  }

  .col-xs-12 {
    padding: 0;
  }
}

.rule-group__item--empty {
  right: -16px;
  padding: 18px !important;
  font-size: 14px;
  text-align: center;
  color: @gray;
  background: rgba(255,255,255,0.7) !important;
}

.rule-group__folder {
  position: relative;
  width: 93%;
  margin: 7px 0 7px 7%;
  padding: 7px 40px 1px 7px;
  border: 1px dotted @gray-border;
  background: #fff4d0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: -50px;
    height: 34px;
    border-left: 1px dotted @gray-border;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 28px;
    left: -50px;
    width: 50px;
    border-top: 1px dotted @gray-border;
  }

  .col {
    width: 29%;
  }

  .rule-group__control {
    margin: 10px 0 0;
    padding: 5px 0;
    border-top: 1px dotted @gray-border;

    .btn {
      font-size: 12px;
    }
  }
}

.rule-group__item__value {
  .control-delimiter {
    width: 4%;
  }

  .col-xs-4 { // Datepicker hardcoded width override
    width: 48%;
  }

  .datepicker {
    left: auto;
    right: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .slider {
    padding-top: 6px;
  }

  .slider__append {
    top: -5px !important;

    .custom-select {
      width: 100%;
    }
  }
}

.rule-group__item__static {
  padding-top: 7px;
}

.rule-group__item__control {
  position: absolute;
  top: 5px;
  right: -36px;
  padding: 6px;
}

.rule-group__item__compound {
  display: flex;

  > div {
    position: relative;

    &:last-child {
      flex-grow: 1;
    }
  }
}

.rule-group__section-title {
  .clearfix();

  margin: 0 0 12px;

  h4 {
    margin: 2px 0 1px 0;
    font-size: 14px;
  }
}

.rule-group__row {
  .clearfix();
  margin: 9px 20px;

  .form-group {
    margin-bottom: 0;
  }

  .control-label {
    width: 130px;
    padding: 11px 15px 0 0;

    &:empty {
      display: none;
    }
  }

  .rule-group__action-control .rule-group__item__control {
    top: 6px;
    right: 7px;
    font-size: 11px;
  }

  .rule-group {
    margin-left: 0;
    margin-right: 0;
    background: rgba(255,255,255,0.6);
  }
}

.rule-group__table {
  th, td {
    padding: 6px 10px !important;
  }

  th {
    font-weight: normal;
    background: darken(@gray-bg, 2%);
  }
}

.rule-group__table__value {
  display: inline-block;
  width: 70%;
}

.air-policy-cabin-class {
  position: relative;
  display: flex;
  align-items: flex-start;

  .air-policy-cabin-class__label {
    padding: 6px 8px 0 20px;
  }
}
