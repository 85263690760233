.selection-pane {
  display: flex;
}

.selection-pane__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  &--error {
    .selection-pane__filters {
      border-color: @red;
    }
    .selection-pane__section {
      border-color: @red;
    }
    .form-control {
      border-color: @gray-border;
    }
  }
}


.selection-pane__header {
  flex: 1;
}

.selection-pane__form {
  display: flex;
  margin: 0 0 15px;
}

.selection-pane__form__input {
  flex: 1;
  margin-top: 5px;
}

.selection-pane__form__controls {
  margin-left: 25px;
}

.selection-pane__filters {
  padding: 10px 15px 2px;
  border-radius: 3px 3px 0 0;
  background: @gray-bg;
  border: 1px solid @gray-border;
  border-bottom: none;
}

.selection-pane__filters__control {
  display: flex;
  margin-bottom: 15px;

  .checkbox:not(:last-child) {
    margin-right: 20px;
  }
}

.selection-pane__filters__title {
  margin: 7px 12px 0 0;
  font-size: 12px;
  color: @gray-dark;
}

.selection-pane__section {
  height: 450px;
  overflow-y: auto;
  padding: 8px 8px 5px;
  border: 1px solid @gray-border;
  background: @white;
  border-radius: 0 0 3px 3px;

  .checkbox {
    min-height: auto;
    margin-bottom: 1px;
    padding: 0;

    label {
      position: relative;
      display: block;
      margin-bottom: 1px;
      padding: 4px 0 4px 25px;
      //border: 1px dashed #e0e0e0;
      border-radius: 3px;
      z-index: 5;

      &:not(.control--disabled):hover {
        .checkbox__icon {
          background: #f0fbef;
        }
      }
    }
  }

  .checkbox__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    z-index: -1;

    &:before {
      left: 5px;
      top: 7px;
      width: 12px;
      height: 12px;
    }

    &:after {
      left: 7px;
      top: 9px;
      font-size: 7px;
    }
  }

  input[type="checkbox"]:checked ~ .checkbox__icon,
  .selection-pane__group-checkbox--partial .checkbox__icon {
    background: #e7f8e7;
  }

  .control--disabled {
    color: @gray-darker;

    .checkbox__icon {
      background: #f3f3f3 !important;
    }
  }

  .glyphicon {
    float: right;
    margin: 3px 12px 0 0;
    color: @gray-dark;
  }
}

.selection-pane__group-checkbox--partial {
  input[type="checkbox"] ~ .checkbox__icon {
    background: @white !important;

    &:after {
      display: block;
      content: "";
      top: 11px;
      left: 8px;
      width: 6px;
      height: 4px;
      background: lighten(@gray, 10%);
      border-radius: 1px;
    }
  }
}

.selection-pane__group-checkbox {
  label {
    font-weight: bold;
  }

  &:hover {
    & ~ .selection-pane__section__group {
      label:not(.control--disabled) .checkbox__icon {
        background: #f0fbef !important;
      }
    }
  }
}

.selection-pane__section__group {
  margin-left: 25px;
}

.selection-pane__title {
  margin: 13px 0 15px;
  padding: 0 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid @gray-border;
}

.selection-pane__section__title {
  padding-bottom: 3px;
  margin: 14px 0 8px 0;
  color: @gray;
  font-size: 12px;
  font-weight: bold;
}

.selection-pane__control {
  display: flex;
  width: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn {
    min-height: 80px;
    margin-bottom: 5px;
    padding: 9px;
  }
}

.selection-pane__section__placeholder {
  margin: 50px 10px 0;
  font-size: 14px;
  text-align: center;
  color: @gray;
}

.selection-pane__footer {
  margin: 15px 0 0;

  .btn {
    margin-right: 8px;
  }
}
