// Less for dev purposes
// All temporary LESS/CSS should be added here

.content-box {
  padding: 15px;
  border: 1px solid @gray-border;
  background: @white;
}

.pnr-formatting-client-warning {
  margin-bottom: 0;

  p {
    color: red !important;
  }
}
