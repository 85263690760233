// React Datepicker

.dropdown-menu.datepicker {
  top: 32px;
  padding: 10px;
  width: auto;
  min-width: 258px;
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
  border: none;

  &:focus {
    outline: none;
  }

  &.datepicker--double {
    width: 500px;

    .table-condensed {
      width: 48%;
      float: right;

      &:first-child {
        float: left;
      }
    }
  }

  .table-condensed {
    th,
    td {
      text-align: center;
      font-size: 14px;
      padding: 0;
    }

    thead {
      th {
        height: 27px;
      }

      .prev,
      .next {
        .hide-text();
        width: 30px;
        cursor: pointer;
        padding: 0;
        background-image: url(../img/cal-btn-sprite.png);
        background-size: 68px 27px;
        background-repeat: no-repeat;
        opacity: 0.7;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      .prev {
        background-position: 0 0;
      }

      .next {
        background-position: -35px 0;
      }

      .dow {
        font-size: 11px;
        font-weight: normal;
        color: @gray;
        padding: 15px 0 10px 0;
      }
    }

    tbody {
      td {
        color: #555555;
        font-size: 13px;
        padding: 4px 0;
      }

      .day {
        cursor: pointer;
        width: 34px;

        &:hover {
          color: @white;
          background: @blue;
        }
      }

      .future,
      .past {
        cursor: default;
        opacity: 0.5;

        &:hover {
          color: #555555;
          background: @grayLighter;
        }
      }

      .selected,
      .interval {
        background: lighten(@blue, 37%);

        &.day--selected,
        &.start,
        &.end {
          color: @white;
          background: @blue;
        }
      }
    }
  }
}

.datepicker__footer {
  float: left;
  width: 100%;
  margin: 7px 0 0;
  padding: 6px 0 0;
  border-top: 1px solid @gray-border;
}

.datepicker__trigger {
  float: left;

  .checkbox {
    margin: 6px 0 0 !important;
  }
}

.datepicker__trigger-btn {
  padding: 0;
  line-height: 1.3;
  border-bottom: 1px dotted @blue;
}

.datepicker__time {
  display: flex;
  justify-content: space-between;
  margin: 1px 0 7px;
  padding: 6px 10px 8px;
  border: 1px dashed @gray-border;
  border-bottom: 1px dashed @gray-border;
  background: @gray-bg;

  .form-control {
    width: 100% !important;
  }

  .custom-select {
    margin: 0;

    select {
      position: absolute;
    }
  }
}

.datepicker__time__col__wrapper {
  display: flex;
  justify-content: space-between;

  .form-group {
    width: 45%;
    margin-bottom: 0 !important;
  }
}

.datepicker__time__label {
  margin: 0 0 5px;
  font-size: 11px;
  color: @gray;
  text-transform: uppercase;
  font-weight: bold;
}

.datepicker__time__divider {
  margin-left: 6%;

  .form-group {
    width: 100%;
  }
}

.datepicker__time__value {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .form-group {
    width: 45%;
  }
}

.datepicker__time__col {
  width: 45%;
}

.datepicker__time__control {
  .clearfix();
  padding: 6px 0 2px;
}
