@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.header {
  .clearfix;

  position: relative;
  min-width: 1240px;
  height: 50px;
  margin-bottom: 17px;
  background: @white;
  box-shadow: 0 1px 0 rgba(0,0,0,0.15);
  border-top: 4px solid #596771;
  z-index: 40;

  .logo {
    float: left;
    display: block;
    width: 130px;
    height: 33px;
    margin: 5px 80px 0 7px;
    background: url(../img/ags-logo.svg) 0 0 no-repeat;
    background-size: cover;
  }

  .main-nav {
    float: left;
    position: relative;
    margin: 0 0 0 5px;
    padding: 0;

    > li {
      .pull-left;
      display: inline-block;
      cursor: pointer;
      line-height: 46px;
      height: 46px;
      margin-right: 30px;

      &:last-child {
        margin-right: 5px;
      }

      a{
        display: block;
        color: @gray;
        text-decoration: none;
        background: none;
      }

      &.active,
      &:hover {
        border-bottom: 6px solid @red;

        > a,
        .dropdown-toggle {
          color: @red;
        }
      }
    }

    .dropdown {
      margin: 0;
    }

    .dropdown-toggle {
      display: block;
      padding: 0 !important;
      height: 45px;
      outline: none;
    }

    .dropdown-menu {
      top: 35px;
      width: 158px;
    }
  }

  .dropdown{
    .clearfix();
    float: right;
    margin: 14px 0 0 0;

    .dropdown-toggle{
      color: @gray;
      background: none;
      border: none;
      padding: 1px 6px;
      text-decoration: none;
    }

    &.open{
      .glyphicon{
        color: @link-color;
      }
    }
  }

  .dropdown-menu{
    position: absolute;
    z-index: 30;
    top: 25px;
    right: 0;
    min-width: 136px;
    padding: 5px 0;
    text-align: left;
    background: @white;
    box-shadow: 0 0 2px rgba(0,0,0,0.15);
    border: 1px solid lighten(@gray, 10%);
    border-radius: 0;

    li {
      margin: 0;
      padding: 0;
      text-align: left !important;

      &.divided {
        height: 1px;
        margin: 7px 0;
        border-top: 1px solid @grayBorder;
      }
    }

    a {
      position: relative;
      display: block;
      color: #666666;
      line-height: 19px;
      font-weight: 200;
      margin: 0;
      padding: 4px 14px 5px;

      &:hover,
      &.selected {
        color: @gray;
        background: @grayLighter;
      }
    }
  }
}

.header--alt{
  margin-bottom: 0;
  padding-top: 4px;
  padding-left: 25px;
  padding-right: 25px;
  border-top: none;
  background: @gray-bg;
}

.footer-placeholder{
  height: 49px;
  margin-top: 50px;
}

.user-nav {
  float: left;
  margin: 14px 0 0 0;
  list-style: none;

  li {
    float: left;
  }

  a {
    color: @gray-darker;
    margin-right: 40px;
  }
}

.logo + .user-nav {
  margin-left: -30px;
}

.header__dropdown-nav {
  float: right;
  margin-bottom: 0;
  padding-left: 16px;

  .dropdown {
    height: 47px;
    margin: 0;
  }

  .dropdown-menu {
    top: 40px;
    right: 6px;
  }
}

.header__dropdown-nav__wrapper {
  .text-overflow();
  display: inline-block;
  margin-top: 5px;
  max-width: 146px;
  padding-right: 3px;
  font-size: 12px;
  vertical-align: middle;
}

.header__dropdown-nav__sub {
  display: block;
  font-size: 11px;
  text-align: right;
}

.header-notification{
  font-size: 12px;
  background: #FFFDE6;
}

.notification__content{
  display: block;
  position: relative;
  top: 50%;
  height: 62px;
  padding: 10px 35px 10px 0;
}

.notification__text {
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.notification__close{
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  font-size: 21px;
  font-weight: 600;
  color: @gray;
  margin-top: -12px;
  line-height: 19px;
}

.navbar-header {
  .logo {
    display: block;
    width: 118px;
    height: 30px;
    margin: 3px 80px 0 0;
    background: url(../img/ags-logo.svg) 0 0 no-repeat;
    background-size: cover;
  }
}

.header-message {
  height: 63px;
  padding: 8px 0 10px;
  font-size: 14px;
  text-align: center;
  color: @white;
  background: @red;
  margin-top: -@navbar-margin-bottom;
  margin-bottom: @navbar-margin-bottom;

  a {
    color: @white;
    text-decoration: underline;
  }
}

.header-message--warning {
  background: #FF8C00;
}

.header-message__title {
  margin: 0 1px 0 0;
  font-size: 17px;
  font-weight: bold;
}

.navbar__acc-link {
  float: right;
  display: block;
  margin: 14px 0 0 0;
  padding: 0 6px;
  color: @gray-dark;
  border-radius: 2px;
  background: darken(@gray-bg, 3%);

  &:hover {
    color: @gray-dark;
    background: @gray-bg;
  }
}

.nav-popover {
  float: right;
  position: relative;
  margin: 13px 6px 0;

  .feature-tooltip {
    top: 33px;
    left: -118px;
    width: 255px;
    text-align: center;
  }

  .feature-tooltip--center {
    left: -61px;
    top: 50px;
  }

  .nav-unstyled a {
    color: #777;
  }

  .feature-tooltip__footer {
    margin: 14px 0 5px;
    text-align: center;

    .btn {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.nav-popover__dropdown {
  display: none;
  position: absolute;
  left: -260px;
  top: 44px;
  width: 370px;
  height: 350px;
  background: @white;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 260px;
    top: -8px;
    border-left: 8px solid transparent;
    border-bottom: 8px solid @white;
    border-right: 8px solid transparent;
  }
}

.nav-popover__dropdown__header {
  padding: 13px 18px 12px;
  color: #596771;
  border-bottom: 3px solid #596771;

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn {
    position: absolute;
    right: 8px;
    top: 10px;
    padding: 5px;
    line-height: 1;
    color: #596771;

    &:hover {
      color: darken(#596771, 10%)
    }
  }
}

.nav-popover__dropdown__content {
  position: absolute;
  top: 48px;
  bottom: 37px;
  width: 370px;
  overflow-y: auto;
}

.nav-popover__dropdown__item {
  position: relative;
  padding: 10px 40px 10px 18px;
  font-size: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: #feffe8;
  border-bottom: 1px solid #e6e8ea;
}

.nav-popover__dropdown__label {
  display: inline-block;
  position: absolute;
  right: 9px;
  top: 12px;
  padding: 1px 3px;
  font-size: 11px;
  color: @white;
  line-height: 1;
  border-radius: 2px;
  background: #31cba1;
}

.nav-popover__dropdown__footer {
  margin: 7px 0 0;
  font-size: 10px;
  font-weight: bold;
  color: #8a8a8a;
}

.nav-popover__dropdown__control {
  .btn {
    position: absolute;
    bottom: 0;
    padding: 8px 0;
    color: @gray-dark;
    background: #e6e6e6;
    border-radius: 0 0 4px 4px;

    &:hover {
      background: darken(#e6e6e6, 5%);
    }
  }
}

.navbar-nav__form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .form-group {
    width: 280px;
    margin-bottom: 10px;
  }

  .control-label {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: @gray-dark;
  }

  .custom-select {
    margin-right: 0;
  }
}

.navbar-nav__form__action {
  padding: 0 15px 10px;
}

.navbar-nav__form__footer {
  margin: 0 0 -5px;
  padding: 16px 15px;
  border-top: 1px solid @gray-border;
  background: @gray-bg;
  border-radius: 0 0 3px 3px;

  .btn {
    font-weight: bold;
  }
}

.inbox-lnk {
  display: block;
  position: relative;
  width: 25px;
  height: 24px;
  font-size: 19px;
  line-height: 1;
  color: lighten(@gray-dark, 10%);

  &:hover {
    color: darken(@gray-dark, 10%);
  }
}

.inbox-lnk--new {
  &:after {
    content: "";
    position: absolute;
    left: -7px;
    top: -4px;
    width: 13px;
    height: 13px;
    background: @red;
    background-clip: padding-box;
    border: 2px solid @white;
    border-radius: 10em;
    animation: blink 1s infinite;
  }
}

.inbox-lnk__counter {
  display: block;
  position: absolute;
  right: -2px;
  bottom: 0;
  min-width: 12px;
  padding: 0 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 2px;
}

.navbar__counter {
  position: relative;
  top: -5px;
  margin-left: 5px;
  font-size: 14px;

  .glyphicon {
    margin-right: 3px;
    font-size: 12px;
  }
}

.navbar__ext-link {
  .glyphicons {
    top: 0;
    left: 2px;
    font-size: 11px;
  }
}
