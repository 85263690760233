// Terms list
//

.terms-list {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.terms-list__header {
  height: 64px;
  padding: 15px 10px 0 10px;
  border: 1px solid @gray-border;
  background: darken(@gray-bg, 3%);

  .custom-select {
    float: right;
    display: inline-block;
    width: 62%;
  }
}

.terms-list__header__label {
  width: 34%;
  padding-top: 8px;
}

.terms-list__content {
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 100%;
  padding: 10px 0 10px 10px;
  overflow-y: auto;

  .form-inline .input-max {
    width: 98%;
  }
}

.terms-list__nocontent {
  padding-top: 50%;
  height: 100%;
  text-align: center;
}

.terms-list__title {
  margin-top: 5px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
}

.terms-list__subtitle {
  font-size: 13px;
  font-weight: bold;
}

.terms-list__listing {
  margin: 0 0 25px 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid @gray-border;

  a {
    display: block;
    padding: 4px 3px;
    color: @gray-dark;
    border-bottom: 1px solid @gray-border;

    &:hover {
      color: @blue;
      background: lighten(@blue, 46%);
    }
  }
}

.terms-list__abbr {
  display: block;
  float: left;
  width: 55px;
  height: 100%;
  padding-left: 2px;
  font-weight: bold;
}

.terms-list__item {
  display: block;
}

.terms-list__abbr + .terms-list__item {
  margin-left: 100px;
}