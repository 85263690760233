.l-sidebar {
  position: absolute;
  top: 51px;
  bottom: 0;
  left: 0;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  border-right: 1px dashed @gray-border;

  .control-label {
    width: 100%;
    font-weight: bold;
  }

  .multi-selector-body {
    max-height: 118px;
    resize: vertical;

    &[style*="height"] {
      max-height: unset;
    }

    .checkbox {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.l-col {
  position: absolute;
  top: 51px;
  bottom: 50px;
  overflow-y: auto;
  border-right: 1px dashed @gray-border;
}

.header-notification--fix,
.header-message ~ div {
  .l-sidebar,
  .l-col {
    top: 113px;
  }
}

.header-message + .header-message ~ div {
  .l-sidebar,
  .l-col {
    top: 178px;
  }
}

.l-col--center,
.l-col__footer--center {
  right: 40%;
  left: 300px;
}

.l-col--right {
  right: 0;
  left: 60%;
  bottom: 0;
  padding: 10px 15px 5px;

  .l-col__item {
    margin: 0;
    background: lighten(@yellow, 22%);
    border-color: darken(@yellow, 2%) !important;
    cursor: inherit;

    &:before {
      display: none;
    }

    .l-col__item__header {
      border-bottom-color: @gray-dark;
    }

    .text-gray {
      color: darken(@yellow, 40%);
    }
  }
}

.l-col__footer {
  position: absolute;
  bottom: 0;
  height: 50px;
  padding: 8px 15px 0 0;
  background: @gray-bg;
  border-top: 1px solid @gray-border;
  border-right: 1px dashed @gray-border;

  .form-group {
    width: 270px;
  }

  .control-label {
    margin-top: 7px;
  }

  .btn {
    margin-top: 1px;
  }
}

.l-col__item {
  position: relative;
  margin: 10px 15px;
  padding: 6px 10px;
  font-size: 12px;
  border: 1px solid transparent;
  background: @gray-bg;
  border-radius: 4px;
  cursor: pointer;

  &:before {
    .glyphicon();

    content: "\e080";
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: 8px;
    font-size: 18px;
    color: lighten(@gray, 25%);
  }

  &:hover:not(.l-col__item--selected) {
    border-color: @gray-border;

    &:before {
      color: darken(@gray, 5%);
    }
  }
}

.l-col__item--selected {
  pointer-events: none;
  border-color: darken(@gray-border, 18%);

  &:before {
    color: darken(@gray, 10%);
  }
}

.l-col__item__header {
  .clearfix();

  padding-bottom: 7px;
  margin-bottom: 7px;
  border-bottom: 1px dotted @gray-border;
}

.l-col__item__row {
  margin-right: 20px;
  margin-left: 93px;
}

.l-col__item__value {
  float: left;
  display: block;
  width: 93px;
  margin-left: -93px;
}

.l-col__title {
  margin-bottom: 10px;
  padding: 0 0 5px;
  font-size: 15px;
  border-bottom: 1px solid @gray-border;
}

.l-col__message {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  font-size: 18px;
  color: lighten(@gray, 10%);
  text-align: center;
}

.l-col__context-message {
  margin: 10px 15px;
  padding: 15px 0;
  font-size: 16px;
  color: @gray;
  text-align: center;
  border: 1px dashed @gray-border;
}