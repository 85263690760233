.text-tag {
  display: inline-block;
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 2px;
}

.text-tag--sm {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 12px;
}

.text-tag--gray {
  color: darken(@gray, 11%);
  background: #f0efef;

  .warning & {
    background: shade(@state-warning-bg, 5%);
  }

  .table-hover tr:not(.warning):hover & {
    background: shade(@hover-bg, 5%);
  }
}

.text-tag--green {
  color: @white;
  background: @green;
}

.text-tag--yellow {
  color: #a75511;
  background: #fffabf;
}

.text-tag--blue {
  color: shade(@blue, 12%);
  background: #dfedfb;

  &.clickable:hover {
    color: shade(@blue, 35%);
  }
}