.slider {
  .slider__row {
    margin: 13px 0 0;
  }

  .checkbox {
    margin-bottom: 0;
  }
}

.slider__info {
  .clearfix();
  margin: 2px 0 7px;
  text-align: center;
}

.slider__info--bottom {
  margin: 5px 6px 2px;
  font-size: 11px;
}

.slider__value {
  display: inline-block;
  width: 33%;
  color: @gray;
  text-align: center;
}

.slider__value--left {
  float: left;
  text-align: left;
}

.slider__value--right {
  float: right;
  text-align: right;
}

.slider__control {
  padding: 0 9px;
}

.slider__wrapper {
  display: table;
  width: 100%;

  .slider {
    display: table-cell;
    width: 80%;
    padding-top: 10px;

    & + .slider__append {
      position: relative;
      top: -14px;
      padding-left: 20px;
    }
  }

  .slider__append {
    display: table-cell;
  }
}


// rc-slider
//

@prefixClass: rc-slider;
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

.@{prefixClass} {
  position: relative;
  height: 17px;
  padding: 7px 0;
  width: 100%;
  border-radius: 6px;

  &-rail {
    position: absolute;
    width: 100%;
    background-color: @gray-border;
    height: 4px;
    border-radius: 6px;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: @blue;
  }

  &-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -6px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: @blue;
    outline: none;
    box-shadow: 0 0 0 4px white;

    &:hover {
      background-color: shade(@blue, 20%);
    }

    &-active {
      &:active {
        background-color: shade(@blue, 10%);
      }
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    &:first-child {
      margin-left: -4px;
    }
    &:last-child {
      margin-left: -4px;
    }
    &-active {
      border-color: tint(@blue, 50%);
    }
  }

  &-disabled {
    .@{prefixClass}-track {
      background-color: #ccc;
    }

    .@{prefixClass}-handle, .@{prefixClass}-dot {
      border-color: #ccc;
      background-color: lighten(@gray, 18%);
      cursor: not-allowed;
    }

    .@{prefixClass}-mark-text, .@{prefixClass}-dot {
      cursor: not-allowed!important;
    }
  }
}

.slider__control--inverse .@{prefixClass} {
  &-rail {
    background-color: @blue;
  }
  &-track {
    background-color: @gray-border;
  }
}

.@{prefixClass}-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .@{prefixClass} {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      margin-bottom: -7px;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;
      &:first-child {
        margin-bottom: -4px;
      }
      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

.motion-common() {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
}

.make-motion(@className, @keyframeName) {
  .@{className}-enter, .@{className}-appear {
    .motion-common();
    animation-play-state: paused;
  }
  .@{className}-leave {
    .motion-common();
    animation-play-state: paused;
  }
  .@{className}-enter.@{className}-enter-active, .@{className}-appear.@{className}-appear-active {
    animation-name: ~"@{keyframeName}In";
    animation-play-state: running;
  }
  .@{className}-leave.@{className}-leave-active {
    animation-name: ~"@{keyframeName}Out";
    animation-play-state: running;
  }
}
.zoom-motion(@className, @keyframeName) {
  .make-motion(@className, @keyframeName);
  .@{className}-enter, .@{className}-appear {
    transform: scale(0, 0); // need this by yiminghe
    animation-timing-function: @ease-out-quint;
  }
  .@{className}-leave {
    animation-timing-function: @ease-in-quint;
  }
}
.zoom-motion(rc-slider-tooltip-zoom-down, rcSliderTooltipZoomDown);

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.@{prefixClass}-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: 4px 0 8px 0;
  }

  &-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: @white;
    text-align: center;
    text-decoration: none;
    background-color: @gray-dark;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: 8px - 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: @gray-dark;
  }
}