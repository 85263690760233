// Nav tree menu
//
// Tree related tags should be classified since Bootstrap
// dropdowns use unclassified <ul> <li>
//
//


.nav-tree {
  position: relative;
  padding-bottom: 20px;
  margin-left: 3px;

  .spinner-wrap {
    position: absolute;
    top: 30%;
    left: 50%;
  }
}

.nav-tree__scroll {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.nav-tree__header {
  height: 54px;
  padding: 10px 0 10px 15px;
  background: @gray-bg;
  border-bottom: 1px solid @gray-border;
}

.nav-tree__search {
  float: left;
  width: 96%;

  .form-control {
    float: left;
    width: 85%;
    padding-left: 28px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.search--icon {
  position: relative;

  .tags-input {
    padding-left: 29px;

    input {
      padding: 0;
    }

    .dropdown-menu {
      margin-left: -23px;
    }
  }

  .glyphicon {
    position: absolute;
    left: 9px;
    top: 50%;
    font-size: 14px;
    color: @gray-border;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.nav-tree__search--icon {
  position: relative;

  .glyphicon-search {
    position: absolute;
    left: 9px;
    top: 50%;
    font-size: 14px;
    color: @gray-border;
    z-index: 5;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .borderless {
    padding-left: 29px;
  }
}

.modal-body {
  .nav-tree__header {
    margin: -20px -20px 20px;
  }
}

.nav-tree__list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  .nav-tree__list {
    display: none;
    padding-left: 13px;
  }
}

.nav-tree__item {
  .clearfix();
  position: relative;
  margin-top: 1px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 8px;
    top: 22px;
    bottom: 0;
    width: 1px;
    border-left: 1px dotted @gray-border;
    z-index: 2;
  }
}

.nav-tree__item-container.has-children {
  margin-left: 15px;
}

.nav-tree__item--selected {
  & > .nav-tree__wrapper {
    background-color: #e9f2fd;

    .nav-tree__inh-icon {
      background-color: darken(@hover-bg, 4%);
    }
  }
}

.nav-tree__item--no-child {
  .nav-tree__inwrapper {
    padding-left: 9px !important;
  }

  .nav-tree__intitle {
    padding-left: 17px;
  }

  .nav-tree__editable {
    .navbar-tree__icon-folder {
      top: 9px;
    }
  }

  &:before {
    display: none;
  }
}

.nav-tree__item--expanded {
  > .nav-tree__wrapper .nav-tree__icon {
    .icon-minus-squared();
  }

  > .nav-tree__list {
    display: block;
  }
}

.nav-tree__icon {
  position: absolute;
  top: 4px;
  left: 4px;
  .icon-plus-squared();
  z-index: 1;

  &:hover {
    .opacity(0.6);
  }
}

.nav-tree__icon--no-child {
  display: none;
}

.nav-tree__icon--no-child-folder {
  display: inline-block;
  position: absolute;
  left: -2px;
  top: 3px;
  width: 10px;
  height: 12px;
  margin-right: 6px;
  margin-left: 4px;
  background: url('../img/folders.png') -17px 0 no-repeat;
  background-size: 27px 30px;
}

.nav-tree__wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;

  .dropdown-toggle {
    display: inline-block;
    visibility: hidden;
  }

  > .nav-tree__inwrapper {
    display: table-cell;
    padding: 0 0 0 21px;
    outline: none;

    &:focus {
      text-decoration: none;
    }
  }

  &:hover {
    background-color: @hover-bg;

    .dropdown-toggle {
      visibility: visible;
    }

    .nav-tree__inh-icon {
      background-color: darken(@hover-bg, 4%);
    }
  }
}

.nav-tree__control {
  display: inline-block;
  width: 85%;
  padding: 4px 0;
}

.nav-tree__editable{
  .nav-tree__icon{
    top: 13px;
  }

  .navbar-tree__icon-folder{
    top: 7px;
  }
}

.nav-tree__title {
  display: block;
  position: relative;
  font-size: 12px;
  text-align: left;

  .checkbox-rev,
  .radio-rev{
    display: inline-block;
    float: left;
    margin: 0 0 0 3px;
  }
}

.nav-tree__intitle {
  display: block;
  padding: 2px 25px 2px 21px;
  line-height: 1.38;
  word-wrap: break-word;
}

.nav-tree__title--editable{
  width: 80%;
}

.navbar-tree__dropdown {
  position: absolute;
  right: 2px;
  top: 0;

  .dropdown-toggle {
    height: 20px;
    padding: 0 6px 0 1px;
    text-align: center;
    color: @gray-darker;
    border: none;
    background: none;
    box-shadow: none;

    &:hover,
    &:focus {
      background: darken(@hover-bg, 6%);
    }

    .caret {
      position: relative;
      top: -1px;
      margin: 0;
    }
  }

  .dropdown-menu {
    left: auto;
    right: 0;
    padding: 5px 0;
    box-shadow: 0 0 9px rgba(0,0,0,0.26);

    li a {
      padding: 2px 13px;
      font-size: 12px;
      color: @gray-darker;
    }
  }

  &.open {
    .dropdown-toggle {
      display: inline-block;
    }
  }
}

.nav-tree__inh-icons {
  display: table-cell;
  position: relative;
  top: 2px;
  width: 123px;
}

.nav-tree__inh-icon {
  float: left;
  display: block;
  width: 22px;
  margin: 0 1px 0;
  padding: 2px 0;
  line-height: 1;
  letter-spacing: -0.03em;
  font-size: 9px;
  text-align: center;
  color: @white;
  background: darken(@gray-bg, 4%);
  border-radius: 2px;
  cursor: default;
  .user-select(none);
}

.nav-tree__inh-icon--on {
  background: @green !important;
}

.nav-tree__table {
  float: right;
  color: @gray;

  td {
    width: 24px;
    text-align: center;
  }
}

.nav-tree__info-table {
  float: right;
  margin: 4px 0 0 0;

  td {
    width: 400px;
  }
}

.nav-tree__table ~ div.clickable {
  width: 73%;
}

.nav-tree__table--heading {
  margin-top: 8px;
  font-size: 9px;

  td {
    width: 20px;
    padding-right: 4px;
    text-align: left;
  }
}

.nav-tree__table--full-heading {
  float: none;
  width: 100%;
  margin: 0;
  font-size: 12px;
  border-bottom: 1px solid @gray-border;
  background: @gray-bg;

  td {
    padding: 6px 0;
    text-align: left;

    &:first-child {
      padding-left: 15px;
    }
  }
}

.nav-tree__table--align-left {
  td {
    text-align: left;
  }
}

.navbar-tree__icon-folder {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 13px;
  background: url('../img/folders.png') 0 0 no-repeat;
  background-size: 27px 30px;

  &.open {
    background-position: 0 -15px;
  }
}

.tree-wrapper {
  height: 456px;
  overflow-y: scroll;
  background: @white;
  border: 1px solid @grayBorder;
}

.modal-tree {
  padding: 0;
}

.modal-tree .controls {
  margin: 0;
  background: #f8f8f8;
  border: none;
}

.modal-tree .nav-tree__list--top-level {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  font-size: 13px;
  overflow: auto;
  overflow-y: scroll;
  background: white;
  border: 1px solid #e5e5e5;
}

.modal-tree li {
  font-size: 13px;
}

.modal-tree .modal-body .label {
  color: #888;
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal-tree .modal-body .label .star {
  color: #dd4b39;
}

.modal-tree .error {
  border-color: #dd4b39;
}

.modal-tree .error-message {
  color: #dd4b39;
}

.modal-tree .validation-field {
  position: relative;
}

.modal-tree .validation-field .validation-indicator {
  display: block;
  position: absolute;
  left: 303px;
}

.nav-tree--home {
  .nav-tree__inwrapper {
    display: inline-block;
    max-width: 17.1%;
  }

  .dropdown-menu {
    left: 0;
    right: auto;
  }
}
