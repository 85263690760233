//
// Dropdown menus
// --------------------------------------------------


// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top:   @caret-width-base solid;
  border-right: @caret-width-base solid transparent;
  border-left:  @caret-width-base solid transparent;
}

.btn-xs{
  .caret{
    margin-left: 5px;
  }
}

// The dropdown wrapper (div)
.dropdown {
  position: relative;
  outline: none;
}

.dropdown-toggle {
  text-align: left;
  cursor: pointer;
}

.dropdown-toggle--fixed {
  min-width: 160px;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 140px;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: @font-size-base;
  text-align: left;
  background-color: @dropdown-bg;
  border: 1px solid @input-border-focus;
  border-radius: @input-border-radius;
  background-clip: padding-box;


  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider(@dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > a,
  .dropdown-item {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: @line-height-base;
    color: @dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }

  .muted {
    color: #868a8e;
  }
}

.dropdown-menu--extended {
  width: 80%;
}

.dropdown-xs{
  .dropdown-menu {
    font-size: 12px;
  }
}

// Hover/Focus state
.dropdown-menu > a:not(.active),
.dropdown-item {
  &:hover,
  &:focus {
    text-decoration: none;
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;

    .muted {
      color: #868a8e;
    }
  }
}

// Active state,
.dropdown-item {
  &.active,
  &:hover,
  &:focus {
    color: @dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: @dropdown-link-active-bg;

    .muted {
      color: lighten(@dropdown-link-active-bg, 41%);
    }
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu.disabled {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-disabled-color;
  }
}
// Nuke hover/focus effects
.dropdown-menu.disabled {
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    .reset-filter();
    cursor: not-allowed;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  margin-top: 3px;
  padding: 3px 20px;
  font-size: 12px;
  font-weight: bold;
  font-size: @font-size-small;
  line-height: @line-height-base;
  color: @dropdown-header-color;

  &:first-child {
    margin-top: 0;
  }
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: (@zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: @caret-width-base solid;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
  }
}

.dropdown__subtitle {
  margin: 15px 10px -2px;
  font-size: 12px;
  color: @gray;

  &:first-child {
    margin-top: 5px;
  }
}

.dropdown__control {
  margin: 16px 0 -5px;
  padding: 8px 10px;
  text-align: right;
  border-top: 1px solid @gray-border;
  background: darken(@gray-bg, 3%);

  .btn-xs {
    font-weight: bold !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

.dropdown__code {
  display: inline-block;
  position: relative;
  width: 35px;
  margin: 0 4px 0 12px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid @gray-border;
  border-radius: 3px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -3px;
    bottom: -5px;
    left: -17px;
    width: 1px;
    background: @gray-border;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: -16px;
    height: 1px;
    width: 9px;
    background: @gray-border;
  }
}

.active .dropdown__code {
  border-color: rgba(255,255,255,0.8);

  &:before,
  &:after {
    background: rgba(255,255,255,0.8);
  }
}

.dropdown__code--parent {
  margin-left: -6px;

  &:before,
  &:after {
    display: none;
  }
}

.h-item__policy {
  display: inline-block;

  .dropdown-toggle:after {
    display: none !important;
  }

  .a-content__item__label {
    display: flex;
    align-items: center;
    line-height: 1.4;

    .glyphicons {
      margin: -1px 0 0 6px !important;
      font-size: 11px;
    }
  }

  .dropdown-toggle {
    padding: 0;
    white-space: normal;
    border: none;
    background: none;
  }

  .dropdown-menu {
    width: 295px;
    padding: 2px 0;
    border: none;
    border-left: 4px solid @red;
    box-shadow: 0 0 16px rgba(0,0,0,0.25);
    transform: translate3d(0, 27px, 0) !important;
  }
}

.h-item__errors__header {
  padding-bottom: 3px;
  font-weight: bold;
  text-transform: capitalize;
}

.h-item__errors__item {
  padding: 6px 10px;
  font-size: 12px;

  & + & {
    border-top: 1px dashed @gray-border;
  }
}

.h-item__errors__text {
  margin: 0;
  font-size: 11px;

  & + & {
    margin-top: 3px;
  }
}


// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: @grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      .dropdown-menu-right();
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      .dropdown-menu-left();
    }
  }
}

