// Multi Selector widget
//

.multi-selector {
  .controls {
    margin-top: 2px;
    padding: 2px 2px 2px 6px;
    max-height: 109px;
    overflow-y: auto;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
  }

  .checkbox {
    margin: 5px 0;
    font-size: 12px;

    &:last-child {
      margin-bottom: 3px;
    }
  }
}

.multi-selector-body {
  padding: 0 2px 2px 6px;
  max-height: 109px;
  overflow-y: auto;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  background: @white;

  .checkbox-rev:last-child,
  .radio-rev:last-child {
    margin-bottom: 4px;
  }
}

.multi-selector-body--plain {
  padding-bottom: 6px;

  [class^="col-xs-"],
  [class*=" col-xs-"] {
    width: auto;
    padding: 0;
  }

  .form-group {
    margin: 0;
  }

  .control-label {
    display: none;
  }

  .checkbox-rev:last-child,
  .radio-rev:last-child {
    margin-bottom: 0;
  }

  .multi-selector-body {
    padding: 0;
    overflow-y: auto;
    border: none;
    background: transparent;
  }
}

.multi-selector--radio-select {
  background: @white;

  .radio-select {
    display: block;
    padding: 4px 2px 2px 6px;
    max-height: 430px;
    overflow-y: auto;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;

    &.row {
      margin: 0;
    }

    .radio-select__column {
      padding: 0 2px 2px 6px;
    }

    .radio-inline {
      display: block;
      margin: 0 !important;
      padding-top: 7px;
      padding-bottom: 0;
      min-height: 22px !important;
    }
  }
}

.multi-selector-body__control {
  margin: 5px 0;
  text-align: right;
}

.modal-body {
  .multi-selector-body {
    max-height: 430px;
  }
}

.ag-home-form .multi-selector-body,
.search-bar .multi-selector-body {
  padding: 4px 4px 0;
  max-height: 112px;
  overflow-y: auto;
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 3px;

  .checkbox__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;

    &:before {
      left: 5px;
      top: 3px;
    }

    &:after {
      left: 8px;
      top: 6px;
    }
  }

  .checkbox-rev,
  .radio-rev {
    position: relative;
    margin: 0;
    padding-left: 0;
  }

  label {
    display: block;
    padding: 2px 3px 2px 26px;
    border-radius: 3px;

    &:hover {
      background: darken(@gray-bg, 3%);
    }
  }

  input[type="checkbox"]:checked {
    ~ span:not(.checkbox__icon) {
      position: relative;
      color: @white;
    }

    ~ .checkbox__icon {
      background: @blue;
    }
  }
}

.search-bar__options .multi-selector-body {
  max-height: 85px;

  label {
    color: @gray-darker;
    font-size: 13px;
  }
}

.multi-selector--xs {
  .controls {
    margin-top: 0;
    padding-top: 0;
    min-height: 90px;
    max-height: 90px;
  }

  .checkbox {
    margin: 3px 0 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.multi-selector-body__title {
  margin: 10px 0 7px 3px;
  color: @gray;
  font-size: 12px;
  font-weight: bold;

  &:first-child {
    margin-top: 4px;
  }
}