/* Based on Sublime Text's Monokai theme */

.cm-s-monokai.CodeMirror {background: #002b36; color: #f8f8f2;}/* Origin {background: #272822; color: #f8f8f2;} */
.cm-s-monokai div.CodeMirror-selected {background: #49483E !important;}
.cm-s-monokai.CodeMirror ::selection { background: rgba(73, 72, 62, .99); }
.cm-s-monokai.CodeMirror ::-moz-selection { background: rgba(73, 72, 62, .99); }
.cm-s-monokai .CodeMirror-gutters {background: #073642; border-right: 0px;} /* Origin {background: #272822; border-right: 0px;} */
.cm-s-monokai .CodeMirror-guttermarker { color: white; }
.cm-s-monokai .CodeMirror-guttermarker-subtle { color: #d0d0d0; }
.cm-s-monokai .CodeMirror-linenumber {color: #d0d0d0;}
.cm-s-monokai .CodeMirror-cursor {border-left: 1px solid #f8f8f0 !important;}

.cm-s-monokai span.cm-comment {color: #75715e;}
.cm-s-monokai span.cm-client-comment {color: #fff; background: #f00}
.cm-s-monokai span.cm-filter {color: #ffe792;}
.cm-s-monokai span.cm-atom {color: #ae81ff;}
.cm-s-monokai span.cm-number {color: #ae81ff;}

.cm-s-monokai span.cm-property, .cm-s-monokai span.cm-attribute {color: #bf4819;} /* Origin {color: #a6e22e;} */
.cm-s-monokai span.cm-keyword {color: #bf4819;} /* Origin {color: #f92672;} */
.cm-s-monokai span.cm-string {color: #e6db74;}

.cm-s-monokai span.cm-variable {color: #1379c7;} /* Origin {color: #f8f8f2;} */
.cm-s-monokai span.cm-variable-2 {color: #9effff;}
.cm-s-monokai span.cm-def {color: #fd971f;}
.cm-s-monokai span.cm-bracket {color: #c43230;} /* Origin {color: #f8f8f2;} */
.cm-s-monokai span.cm-tag {color: #a6e22e;} /* Origin {color: #f92672;} */
.cm-s-monokai span.cm-link {color: #ae81ff;}
.cm-s-monokai span.cm-error {background: #f92672; color: #f8f8f0;}
.cm-s-monokai .cm-line-error {background: #f92672 !important;}

.cm-s-monokai .CodeMirror-activeline-background {background: #373831 !important;}
.cm-s-monokai .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
