.price-classes {
  &:extend(.static-slats, .static-slats--multiline all);

  .price-classes__head--id {
    width: 10.5%;
  }

  .price-classes__col--id {
    padding-top: 15px;
  }

  .price-classes__col--form {
    width: 80%;
  }

  .price-classes__head--form {
    width: 80%;
  }

  .price-classes__textarea {
    height: 32px;
  }

  .price-classes__required-indicator {
    color: @red;
  }

  .static-slats__head {
    font-size: 13px;
  }
}

.price-classes__textarea {
  min-height: 32px;
  margin-bottom: 5px;
  resize: vertical;
  line-height: 20px;
}
