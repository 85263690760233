.CodeMirror__read-only {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 11px;
  background: rgba(200,200,255,0.08);

  &.cm-comment {
    font-size: 12px;
    color: #b4ab8c !important;
    font-weight: bold;
  }

  &.cm-error {
    font-weight: bold;
    background: rgba(200,200,255,0.09) !important;
  }

  &:not(.cm-comment) {
    color: #7fa5c1 !important;
  }

  &:last-child {
    padding-right: 8px;
    border-radius: 0 4px 4px 0;
  }
}
