.sorting-customization__item {
  .caret {
    color: @blue;
  }
  .sorting-customization__delete-btn {
    margin-top: 0;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .sortable__drag-indicator {
    right: 10px;
    top: 15px;
    position: absolute;
  }

  .sorting-customization__fields {
    position: absolute;
    left: 20%;
    top: 36%;
    min-width: 50%;
  }

  .sorting-customization__item__caret {
    position: relative;
    left: -15px;
    z-index: 1;
  }

  .sortable__title__header {
    font-size: 14px;
  }
}

.sorting-customization__item__select {
  border: 0;
  background: rgba(0, 0, 0, 0);
  color: @blue;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    border: none;
    outline: 0;
  }
}

.sorting-customization__item__select--order-choices {
  min-width: 220px;
}
