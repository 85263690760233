//
// Wells
// --------------------------------------------------


// Base class
.well {
  position: relative;
  min-height: 20px;
  padding: 19px;
  margin: 10px 0 20px;
  background-color: @well-bg;
  border: 1px solid @well-border;
  border-radius: @border-radius-base;
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .form-group__label-offset {
    margin-bottom: 8px;

    .checkbox-rev {
      padding-top: 4px;
    }
  }

  &:hover {
    .well__toggle-control {
      display: block;
    }
  }

  &:empty {
    display: none;
  }
}

.well__toggle-control {
  display: none;
  position: absolute;
  right: 9px;
  top: 17px;
  cursor: pointer;
  .opacity(0.88);

  &:hover {
    .opacity(1);
  }
}

.form-group__well {
  margin-right: -15px;
  padding: 10px 15px;
  font-size: 11px;
  border: 1px dashed @gray-border;
  background: @gray-bg;

  h5 {
    margin: 0 0 5px;
    font-size: 11px;
  }
}

.well-breakdown {
  margin: 10px 0 30px;
  padding: 8px 15px 3px;
  background: #f2f7fd;
  border: 2px solid #e6eff9;
  border-radius: 10px;

  .table {
    margin-bottom: 0;

    tr:first-child {
      td {
        border-top: none;
      }
    }
  }

  .col {
    vertical-align: top;
  }

  .row__toggle__content & {
    margin: 0;
  }
}

// Sizes
.well-lg {
  padding: 24px;
  border-radius: @border-radius-large;
}
.well-sm {
  margin-bottom: 10px;
  padding: 9px;
  border-radius: @border-radius-small;
}

.well-list {
  margin: 3px 0;

  li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

