.checkbox--partially-checked {

  input[type="checkbox"],
  .checkbox-rev {
    .opacity(0.48);
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-right: 6px;
  outline: none;

  select {
    position: absolute;
    height: 100%;
    cursor: pointer;
    .opacity(0);
    z-index: 5;
  }

  .value {
    .text-overflow();
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 0 20px 0 8px;
    background: @white;
    z-index: 1;
    .box-sizing(border-box);
    .user-select(none);

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 9px;
      top: 14px;
      width: 0;
      height: 0;
      border-top: 4px solid @grayDark;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  &.custom-select-disabled {
    .value {
      color: @gray;
      background: #eee;

      &:after {
        border-top-color: @grayLight;
      }
    }

    .form-control {
      .opacity(0); // in a case of 'disabled' attr applied
    }
  }

  &.custom-select-focused {
    .value {
      border: 1px solid @input-border-focus;
    }
  }

  .has-error & {
    .value {
      border: 1px solid @red;
    }
  }
}

.custom-select.custom-select--transparent {
  width: auto;
  margin-top: -2px;

  .value {
    height: 24px;
    padding-right: 23px;
    padding-left: 2px;
    line-height: 22px;
    color: @blue;
    border: none;
    background-color: transparent;

    &:after {
      top: 10px;
      border-top-color: @blue;
    }
  }
}

.custom-select+small {
  display: inline-block;
  margin-top: 5px;
}

/* Inputs */

.input-max {
  width: 100%;
}

.input-xlarge {
  width: 433px;
}

.input-large {
  width: 291px;
}

.input-medium {
  width: 191px;
}

.input-small {
  width: 141px;
}

.input-xsmall {
  width: 90px;
}

.input-daterange__small {
  width: 96px !important;
}

/* Dropdown control */

.custom-select .value {
  display: inline-block;
  height: 32px;
  line-height: 31px;
  text-decoration: none;
  text-align: left;
  color: @grayDark;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid @gray-border;

  &.focus {
    outline: none;
  }
}

.form-horizontal {
  .control-label {
    float: left;
    width: 220px;
    padding-right: 6px;
    padding-bottom: 3px;
  }

  .control-label--relative-narrow {
    position: relative;
    min-height: 1px;
    width: 16%;
    margin-bottom: 2px;
    padding-left: 15px;
  }

  .control-label--max {
    width: 100% !important;
  }

  .controls {
    margin-left: 220px;
  }

  .form-control {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
  }

  .form-group {
    margin-bottom: 8px;
  }
}

.form-horizontal--single-group {
  .form-group:first-child {
    padding-top: 20px;
  }
}

.form-horizontal--label-wide {
  .control-label {
    width: 290px;
  }

  .form-group__label-offset {
    &:before {
      width: 275px;
    }
  }
}

.form-horizontal--label-fix {

  // .control-label equal to .col-xs-3 width
  .control-label {
    width: 25% !important;
  }

  .form-control-title {
    margin-left: 25%;
    padding-left: 8px;
  }
}

.form-control--additional {
  display: inline-block;
  margin: 7px 0 0 7px;
}

.label-basic {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

.modal-window {
  .form-horizontal {
    .control-label {
      text-align: right;
      width: 105px;
    }

    .controls {
      margin-left: 115px;
    }

    .input-large {
      width: 240px;
    }
  }
}

.validation-indicator {
  display: inline-block;
  position: relative;
  top: 9px;
  left: 5px;
  width: 13px;
  height: 12px;

  &.success {
    background: url(../img/sprite_main.png) -240px -230px;
  }

  &.error {
    background: url(../img/sprite_main.png) -260px -230px;
  }
}

.error {
  border-color: @red;

  .form-control,
  +.value,
  .value {
    border-color: @red;
  }
}

.form-group__helper-text {
  line-height: 29px;

  &:before {
    content: " "
  }
}

.form-group__sub {
  margin: 6px 0 2px;
  font-size: 12px;

  .glyphicon {
    cursor: default;
  }
}

.form-group__note {
  margin: 6px 0 2px;
  font-size: 11px;
  color: @gray;
}

table+.form-group__note {
  margin-top: 15px;
  padding: 8px 0;
  border-top: 1px dotted @gray-border;
}

.form-group__static-note {
  margin: 14px 10px 0 220px;
  font-size: 11px;

  &.muted {
    color: lighten(@gray, 11%);
  }
}

.form-group__error-offset {
  .form-group__error {
    margin-top: -12px;
  }
}

.form-group__label-offset {
  &:empty {
    display: none;
  }

  &:before {
    content: "";
    float: left;
    display: block;
    width: 205px;
    height: 22px;
  }

  .btn:first-child {
    margin-left: 15px;
  }
}

.form-group__label-offset--top-border {

  [class^="col-"]:before,
  [class*=" col-"]:before {
    content: "";
    display: block;
    height: 1px;
    margin: 7px 0 8px;
    background: @gray-border;
  }
}

.form-horizontal--narrow-labels {
  .controls {
    margin-left: 143px;
  }

  .control-label {
    width: 143px;
  }
}

.form-horizontal--block-labels {
  .control-label {
    width: auto !important;
  }
}

.form-vertical {
  .control-label {
    .bold();
    font-size: 12px;
  }
}

.radio,
.checkbox {

  .radio,
  .checkbox {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.focused {
  border-color: @input-border-focus;
  .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6)");
}

.form-group,
.inputlabel {
  .glyphicon-info-sign {
    display: inline;
    font-size: 11px;
    color: @blue;
  }
}

.form-group--shift {
  margin-left: 15px;
}

.form-horizontal {
  .fieldset--narrow {
    margin: 0;
  }
}

input.borderless {
  border: none;
  outline: none;
}

input.nested {
  width: 100%;
  height: 100%;
}

.checkbox.disabled .inputlabel {
  color: @gray-light;
  cursor: not-allowed;
}

.col-xs-12 .fileinput__value {
  max-width: 68%;
}

.col-xs-8 .fileinput__value {
  max-width: 50%;
}

.fileinput__value {
  .text-overflow();
  display: inline-block;
}

.btn-fileinput[disabled] input {
  cursor: not-allowed;
}

.btn-fileinput {
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
  }
}

.form-control {
  .placeholder {
    padding-left: 3px;
  }
}

.form-control--file {
  padding-left: 3px;
  padding-right: 3px;

  input[type="file"] {
    position: relative;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.form-control__input-header {
  display: block;
  margin: 7px 0 10px;
}

.form-group:hover {
  .form-group__tail-control {
    display: inline-block;
  }
}

.form-group__tail-control {
  display: none;
  margin-top: 6px;
}

.form-group-closer>.form-group {
  margin-bottom: 0;
}

.checkbox--form {
  margin-left: 220px;
  white-space: nowrap;
}

.form-control--fileinput {
  padding: 4px 12px;
  height: auto;

  span {
    vertical-align: middle;
  }

  img {
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid #dbdbdb;
  }

  .btn-fileinput {
    padding: 2px 13px;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .none {
    display: inline-block;
    margin-right: 10px;
    border: 1px dashed #dbdbdb;
    font-size: 90%;
    color: @grayLight;
    padding: 15px;
  }

  .clear {
    position: relative;
    top: 5px;
    right: 5px;
    float: right;

    &:after {
      content: '\00D7';
      position: absolute;
      right: -11px;
      top: 0px;
    }
  }

  .clear[disabled] {
    cursor: not-allowed;
  }
}

.form-control--fileinput--preview {
  border: none;
  padding: 0;
  background: none !important;
}

.form-control__fileinput__wrapper {
  padding: 10px 15px 5px;
  background: @gray-bg;
  border: 1px dashed @gray-border;

  .form-group__error {
    margin-top: 46px;
    margin-right: 25px;
  }
}

.select2-container .select2-choice>.select2-chosen {
  line-height: 31px;
}

.form-group__list {
  .clearfix();
  .well();
  .well-sm();

  .control-label {
    width: 210px;
  }

  &.form-group__list--narrow-labels {
    .control-label {
      width: 150px;
    }
  }

  .alert {
    margin: 0 6px 9px 183px;
    padding: 7px 8px;
    font-size: 11px;
  }

  .alert__text {
    padding: 3px 0 0 18px;
  }

  .multi-selector-body {
    max-height: none;
  }

  &:hover {
    .form-group__list-control {
      display: block;
    }
  }
}

.form-group__list--empty,
.block-empty {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 20px 10px;
  color: @gray;
  font-size: 15px;
  text-align: center;
  background: @gray-bg;
  border-radius: 0;
  border: 1px dashed darken(@gray-bg, 8%);
}

.form-group__list-control {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 9px;
  color: @red;

  &:hover {
    color: lighten(@red, 12%);
  }

}

.form-group__label-message {
  display: inline-block;
  margin: 3px 0 0 15px;
  font-size: 12px;
  color: @green;
}

.form-inline-edit {
  display: flex;
  align-items: center;
  padding: 0 7px;
}

.form-inline-edit__label {
  color: #555;
  padding-right: 10px;
}

.form-inline-edit__input {
  position: relative;
  padding-right: 3px;
  flex-grow: 1;
}

.form-inline-edit__control .btn {
  float: left;
  margin-left: 7px;
}

.form-group__date-control-lnk {
  position: absolute;
  top: 2px;
  right: 15px;
  padding: 6px 10px;
  font-size: 11px;
  font-weight: bold;
}

.form-group--single {

  [class^="col-xs"],
  [class*=" col-xs"] {
    width: auto;
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
}

.row__toggle {
  margin: 5px 0;
  border-top: 1px dotted @gray-border;
  border-bottom: 1px dotted @gray-border;
}

.row__toggle ~ .alert-danger {
  position: absolute;
  top: 0;
  left: 100%;
  width: 140px;
}

.row__toggle--expanded {
  .row__toggle__label .glyphicons {
    .rotate(90deg);
  }

  .row__toggle__content {
    display: block;
  }

  .row__toggle__label__aside {
    display: none;
  }
}

.row__toggle__message {
  text-align: center;
  font-size: 13px;
  color: #666A6E;
}

.row__toggle__label {
  margin: 4px 0 14px;
  padding: 10px 15px;
  color: @gray;
  font-size: 14px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.7);
  .user-select(none);

  &:hover {
    color: @blue;
    cursor: pointer;
    background: @white;
  }

  .glyphicons {
    top: 0;
    margin-right: 5px;
    font-size: 11px;
  }
}

.row__toggle__content {
  .clearfix();
  display: none;
  padding: 17px 15px;
  background: rgba(255, 255, 255, 0.4);
  border-top: 1px dotted @gray-border;

  .form-group__select-group__control {
    right: -29px;
    line-height: 1;

    .glyphicon {
      margin: -3px -4px 0 0;
      padding: 4px;
      background: @white;
      border-radius: 15em;
    }
  }

  .multi-selector-body {
    max-height: 240px !important;
  }
}

.row__toggle__label__aside {
  float: right;
  margin-top: 2px;
  color: @gray;
  font-weight: normal;
  font-size: 12px;
}

.row__toggle--form-group {
  margin-top: -8px;
  border: none;

  .row__toggle__label {
    color: @link-color;
    background: @gray-bg;

    &:hover {
      color: @link-hover-color;
    }
  }

  .row__toggle__content {
    padding: 4px 0 0;
    background: transparent;
    border: none;
  }
}

.form-horizontal--flex {
  .form-group {
    display: flex;

    [class^="col-xs"]:not(.control-label),
    [class*=" col-xs"]:not(.control-label) {
      width: auto;
      flex: 1;
    }

    .control-label {
      width: 37%;
    }
  }
}

.radio-choice {
  .radio:hover {
    background: none !important;
  }

  .radio__icon {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
  }
}
