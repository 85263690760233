.compound-inputs {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.compound-inputs__col {
  display: table-cell;

  &:first-child {
    .value,
    .form-control {
      border-radius: 3px 0 0 3px;
      border-right: none;
    }
  }

  &:last-child {
    .value,
    .form-control, 
    .form-mentions__input {
      border-radius: 0 3px 3px 0;
    }
  }
}
