.feature-tooltip {
  position: absolute;
  display: inline-block;
  max-width: 350px;
  padding: 12px 15px;
  color: @white;
  cursor: default;
  background: #223bd6;
  border-radius: 10px;
  box-shadow: 0 0 16px rgba(47,93,176,0.8);
  z-index: 100;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: -10px;
    margin-top: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #223bd6;
  }

  .feature-tooltip__control {
    position: absolute;
    right: 0;
    top: 1px;
    color: @white;

    &:hover {
      color: darken(#223bd6, 20%);
    }
  }
}


.feature-tooltip--condensed {
  left: auto !important;
  width: 230px !important;
  margin: 8px 0 0 -16px;
  padding: 8px 14px;
  transform: translateX(-50%);

  .feature-tooltip__title {
    padding-right: 20px;
    font-size: 13px;
  }

  .feature-tooltip__footer {
    margin-top: 10px !important;

    .btn {
      font-size: 12px;
    }
  }
}

.feature-tooltip--top {
  &:after {
    top: auto;
    bottom: -10px;
    right: 50%;
    margin-right: -10px;
    border-top: 10px solid #223bd6;
    border-right: 10px solid transparent;
    border-bottom: none;
    border-left: 10px solid transparent;
  }
}

.feature-tooltip--right {
  &:after {
    top: 50%;
    right: auto;
    left: -10px;
    border-top: 10px solid transparent;
    border-right: 10px solid #223bd6;
    border-bottom: 10px solid transparent;
    border-left: none;
  }
}

.feature-tooltip--bottom {
  &:after {
    top: 0;
    right: 50%;
    margin-right: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #223bd6;
    border-left: 10px solid transparent;
  }
}

.feature-tooltip__title {
  margin: 0 0 10px;
  font-size: 15px;
  font-weight: bold;
}

.feature-tooltip__text {
  margin: 0;

  & + & {
    margin-top: 10px;
  }
}

.feature-tooltip__footer {
  margin: 12px 0 0;
  text-align: right;

  .btn {
    font-size: 14px;
    color: #223bd6;
    font-weight: bold !important;
    background: @white;
    border-radius: 6px;

    &:hover {
      color: @white;
      background: darken(#223bd6, 20%);
    }
  }
}