.modal--full-page {
  .modal-dialog {
    width: 1190px;
    margin-top: 20px !important;

    .modal-content {
      min-height: 75vh;
    }
  }
}

.modal-header--transparent {
  margin-bottom: 7px;
  padding: 10px 0;
  background: transparent;

  .modal-title {
    width: 100%;
    margin-top: -10px;
    padding: 14px 10px 14px 20px;
    background: @orange;
    border-radius: 3px;
  }

  .close {
    font-size: 14px;
    margin: -4px 15px 0 0;
    padding: 7px 12px;
    color: darken(@orange, 40%);
    border: 1px solid darken(@orange, 25%);
    border-radius: 3px;
    opacity: 1;
  }
}

.rev-details__section {
  position: relative;
  display: flex;
  margin: 15px 0 50px;
  border: 1px solid @gray-border;
  border-radius: 5px;

  &:hover:not(.rev-details__section--active) {
    box-shadow: 0 0 10px rgba(0,0,0,0.19);

    .rev-details__content__title {
      box-shadow: 0 -3px 6px -3px rgba(0,0,0,0.14);
    }
  }
}

.rev-details__section--active {
  border-color: @yellow;
  box-shadow: 0 0 0 1px @yellow;

  .rev-details__content__title {
    color: #5d4c0a;
  }
}

.rev-details__section--attn {
  border: none;
  box-shadow: 0 0 17px rgba(0,0,0,0.22) !important;

  .rev-details__content__title {
    box-shadow: 0 -3px 6px -3px rgba(0,0,0,0.14);
  }
}

.rev-details__content {
  flex: 1;
  padding: 25px 40px 25px;

  .table {
    margin: 0;
    font-size: 12px;

    + .table {
      margin-top: 30px;
    }

    tr {
      th, td {
        border-top: 1px dotted @gray-border;
      }
    }
  }
}

.rev-details__content__label {
  width: 29%;
  font-weight: bold;
}

.rev-details__control {
  width: 270px;
  padding: 25px 20px 25px 10px;
  text-align: right;
  border-left: 1px solid @gray-border;
  background: @gray-bg;
  border-radius: 0 5px 5px 0;

  .btn {
    margin-left: 7px;
    margin-bottom: 20px;
  }

  .alert {
    display: inline-block;
    padding: 5px 10px;
    font-weight: bold;
  }
}

.rev-details__control__message {
  color: #da5000;
}

.rev-details__content__title {
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 17px;
  margin: 0;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  background: @white;
}

.modal-content__check-list {
  min-height: 75vh;
  padding: 50px 0 10px;
  text-align: center;

  h3 {
    margin: 0 0 50px;
    font-size: 22px;
  }

  ul {
    display: inline-block;
  }

  li {
    margin-bottom: 9px;
    font-size: 16px;
    text-align: left;
  }

  .glyphicons {
    top: 2px;
    margin-right: 15px;
    font-size: 18px;
  }

  .glyphicons-unchecked {
    color: lighten(@gray, 17%);
  }
}

.modal-content__message {
  min-height: 75vh;
  padding: 50px 10%;
}

.navbar-exchange {
  z-index: @zindex-modal + 1;
}
