.floated-form__header-message {
  margin: 15px 0 -18px;

  .header-message {
    position: relative;
    background: rgba(199, 30, 37, 0.72);
  }
}

.floated-form {
  display: flex;
  flex-direction: column;
  width: 530px;
  min-height: 100vh;
  padding: 60px 70px 0 60px;
  background: @white;
  box-shadow: 0 0 30px rgba(14,13,58,0.6);

  .logo {
    display: block;
    width: 160px;
    height: 40px;
    margin: 0 80px 0 0;
    background: url(../img/ags-logo.svg) 0 0 no-repeat;
    background-size: cover;
  }

  .page-footer {
    position: static;
    color: @grayLight;
    background: none;

    .container {
      width: auto;
      min-width: auto;
      padding: 0;
    }

    .line-item {
      float: none;
      max-width: none;
      line-height: 1.6;
    }

    .sub-nav {
      float: none;
      margin: 10px 0 0;

      a {
        margin: 0 15px 0 0;
        color: @grayLight !important;

        &:hover {
          color: @grayDark !important;
        }
      }
    }
  }
}

.floated-form--wide {
  width: 530px;
}

.floated-form--alt {
  display: block;
  background: darken(@gray-bg, 1%);
  min-height: auto;
  margin: 75px auto 30px;
  padding: 15px 35px 30px;
  border: 1px solid @gray-border;
  box-shadow: none;

  .floated-form__body__header {
    margin: 10px 0 20px;
  }

  .floated-form__control {
    margin-top: 30px;
  }
}

.floated-form__header {
  .clearfix();

  margin: 0 -10px 0 0;
  padding: 0 0 10px 0;

  .nav > li > a {
    padding-right: 0;
    padding-bottom: 7px;
    padding-left: 0;
    color: @gray-dark;
    background: transparent !important;
  }
}

.floated-form__body {
  flex-grow: 1;
  padding: 7vh 0 15px;

  .form-group {
    margin-bottom: 16px;
  }

  .control-label {
    display: none;
  }

  .form-group__well {
    margin-right: 0;
  }

  textarea {
    resize: vertical;
  }
}

.floated-form__body__preloader {
  .loading-spinner__wrapper {
    margin-top: 22px;
  }

  .loading-spinner {
    border: 3px solid #a8abaf;
    border-right-color: transparent;
  }
}

.floated-form__body__header {
  margin: 40px 0 30px;
  font-size: 24px;
  font-weight: bold;
}

.floated-form__body__message {
  margin: -5px 0 20px;
  padding: 12px 16px;
  color: @red;
  background: lighten(@red, 51%);
  border-radius: 3px;
}

.floated-form__error {
  margin-bottom: 2px;
  color: @red;
}

.floated-form__control {
  .clearfix();

  margin-top: 20px;

  .btn {
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: bold;

    & + .btn {
      margin-right: 15px;
    }
  }
}

.floated-form__footer {
  margin: 20px -30px -25px;
  padding: 11px 30px 7px;

  p {
    margin-bottom: 4px;
  }
}
