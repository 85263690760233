// React AFT

.ag-homepage-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #052445 url(../img/bg-main.jpg) 50% 0 no-repeat; // Admin
  background-size: cover;
  z-index: -1;

  .ag-branded & {
    background: #052445 url(../img/bg-main-asb.jpg) 50% 0 no-repeat; // ASB
    background-size: cover;
  }

  &--solid {
    background: #262d3e !important;
  }
}


.bcd.partner,
.partner {
  background: #f1f2f4;
}

.layout-rev {
  background: @white !important;

  .navbar {
    > .container {
      width: 100%;
      padding-left: 25px;
    }

    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  .content-header--tabs .tabs-inner--categories {
    margin-top: -12px;
  }
}

.content-box {
  padding: 15px;
  border: 1px solid @gray-border;
  background: @white;
}

.search-widget--rev { // Temp fix until filters redesign
  .search-widget .form-control {
    width: 247px;
  }
}

.page-nav {
  margin: 20px 0 60px;

  a {
    padding: 11px 14px !important;

    &:before {
      .glyphicon();
      content: "\e080";
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -6px;
      color: darken(@gray-light, 9%);
    }

    &:hover:not(.active) {
      background: #e6e6e6 !important;
    }
  }

  .active {
    font-weight: bold;
    color: @white;
    background: @btn-secondary-bg !important;

    &:before {
      color: rgba(255,255,255,0.9);
    }
}
}

.page-nav__footer {
  margin: 20px 0 0;
  padding: 20px 0 10px;
  border-top: 1px solid @gray-border;
}

.well-details {
  padding: 10px 15px;
  background: rgba(255,255,255,0.4);
  border: 1px dotted @gray-border;
}

// Shared Homepage/LOGIN2 footer

.page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  color: #c8cdcf;
  font-size: 11px;
  background: rgba(6,10,19,0.45);
  z-index: 1;

  .line-item {
    float: left;
    max-width: 610px;
  }

  .sub-nav {
    float: right;

    a,
    .btn-link {
      margin-left: 20px;
      padding: 0;
      font-size: 12px;
      font-weight: bold !important;
      color: #f2f2f2 !important;
      vertical-align: middle;

      &:hover {
        color: darken(#f2f2f2, 10%) !important;
      }
    }
  }
}
