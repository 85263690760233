html{
  min-width: 1240px;
}

.content{
  h1{
    font-size: 26px;
    margin: 15px 0 0 0;
  }
}

.dropdown-menu {
  &.right {
    left: auto;
    right: 0;
  }

  ul{
    .clearfix();
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .col {
    float: left;
    margin: 10px 0;
    padding: 5px 15px 5px 15px;
    border-right: 1px solid @grayLighter;

    &:last-child{
      border-right: none;
    }

    &.col-wide {
      width: 245px;
    }

    .custom-select{
      margin: 0;
    }
  }

  .item {
    margin-bottom: 10px;
  }

  li .glyphicons,
  li .glyphicon {
    margin-right: 4px;
  }
}

.header-actions {
  margin: 24px 0 0 20px;
}

.user-action-nav{
  .clearfix();
  padding: 10px 0 9px 15px;
  background: @white;

  .action-item{
    float: left;
    margin: 0 18px 0 0;

    .dropdown{
      display: inline-block;
    }
  }
}

.user-action{
  .item{
    margin-right: 15px;
  }
}

.edit-panel{
  .btn{
    margin-left: 15px;
  }
}

.selected-wrapper {
  .clear-lnk {
    margin-left: 5px;
    white-space: nowrap;
  }
}

.pagination-wrap{
  .clearfix();

  .pagination{
    .pull-left;
    margin: 0;
  }

  .btn-group{
    margin: 0 5px;

    .btn{
      width: 60px;
    }
  }

  .center-block {
    text-align: center;

    .pagination {
      float: none !important;
      margin-top: 18px;
    }
  }

  .dropdown-menu{
    width: 60px;
    min-width: 60px;

    a{
      padding: 0px 20px;
    }
  }
}

.alert-overlay{
  position: fixed;
  top: 199px;
  left: 50%;
  text-align: center;
  .bold();
  width: 300px;
  margin-left: -300px/2;
  z-index: 1060;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

h4.sub-head {
  padding: 0 0 20px;
  font-size: 13px;
  .bold();
}

.service-message {
  font-size: 14px;
  text-align: center;

  a {
    display: inline;
    color: @blue !important;
  }

  td {
    padding: 10px 0 25px 0 !important;
    border-top: none !important;
  }

  &:hover {
    td {
      color: @grayDark !important;
      cursor: default !important;
      background: inherit !important;
    }
  }
}

.popover-buttons {
  .popover-content();
  padding-top: 0px;
}

.dropdown-filters-container{
  width: 760px;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}
