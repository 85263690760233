// Color variables

@ag-branded-red:        #E31836;
@ag-branded-blue:       #041E5B;
@ag-branded-brown:      #5C0002;
@ag-branded-dark-gray:  #404040;
@ag-branded-bg:         #F3F5F1;
@ag-branded-light-blue: #B7DDF5;

.logo--ag {
  margin-top: 0 !important;
  background: url(../img/ags-logo.svg) 0 0 no-repeat !important;
}

.ag-branded {
  .navbar-default {
    border-top-color: @ag-branded-dark-gray;

    .logo {
      margin-top: 7px;
    }
  }

  .ag-right-block {
    .tabs-inner__item {
      background: lighten(@ag-branded-bg, 3%);
    }

    .tabs-inner__item--active {
      background: @ag-branded-bg;
    }
  }

  .ag-right-block-bg {
    border-radius: 0 8px 8px 0;
    background: @ag-branded-bg !important;
  }

  .search-bar {
    background-color: @ag-branded-dark-gray;
  }
}
