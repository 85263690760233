[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(../img/glyphicons-halflings.png);
  background-position: 14px 14px;
  background-repeat: no-repeat;
}


[class^="icon-large-"],
[class*=" icon-large"]  {
  display: inline-block;
  vertical-align: text-top;
  background: url(../img/sprite_main.png) 0 0 no-repeat;
  cursor: pointer;
}

.icon-user{
  position: relative;
  top: 2px;
}

.icon-arrow-right{
  background-position: -264px -96px;
}


.icon-darr-down {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../img/sprite_main.png) -520px -140px no-repeat;
}

.icon-darr-up {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../img/sprite_main.png) -530px -140px no-repeat;
}

.icon-advisor-owl{
  display: inline-block;
  position: relative;
  top: 1px;
  width: 18px;
  height: 11px;
  background: url(../img/sprite_hotels.png) 0px -40px no-repeat;
}

.icon-advisor-owl-small{
  display: inline-block;
  position: relative;
  top: 2px;
  width: 21px;
  height: 13px;
  background: url(../img/sprite_hotels.png) -40px -60px no-repeat;
}

.icon-advisor-medium{
  display: inline-block;
  width: 36px;
  height: 19px;
  margin: -3px -6px 0 0;
  background: url(../img/sprite_hotels.png) -90px -90px no-repeat;
}

.icon-advisor-large{
  display: inline-block;
  width: 131px;
  height: 22px;
  margin: -5px 15px 0 0;
  background: url(../img/sprite_hotels.png) -90px -60px no-repeat;
}

.map-marker {
  &:hover,
  &.active{
    z-index: 10000;
  }
}

.map-marker-match,
.map-marker-notmatch,
.map-marker-unavailable,
.map-marker-raw {
  position: absolute;
  top: -21px;
  left: -7px;
}

.map-marker-train,
.map-marker-airport,
.map-marker-landmark {
  position: absolute;
  top: -18px;
  left: -9px;
}

.legend-marker-match,
.map-marker-match{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -82px -8px no-repeat;
}

.map-marker-raw{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -310px -60px no-repeat;
}

.map-marker-active{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -200px -8px no-repeat;
}

.map-marker-booked{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -230px -8px no-repeat;
}

.legend-marker-notmatch,
.map-marker-notmatch{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -52px -8px no-repeat;
}

.legend-marker-street,
.map-marker-street,
.map-marker-searched{
  display: inline-block;
  width: 28px;
  height: 40px;
  margin-bottom: -35px;
  background: url(../img/sprite_map_pins.png) -112px 0 no-repeat;
}

.legend-marker-unavailable,
.map-marker-unavailable{
  display: inline-block;
  width: 13px;
  height: 20px;
  background: url(../img/sprite_hotels.png) -240px 0px no-repeat;
}

.legend-list{
  .legend-marker-street{
    margin-left: -3px;
  }
}

.legend-marker-pinned{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -260px 0px no-repeat;
}

.legend-marker-train{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -280px 0px no-repeat;
}

.legend-marker-airport{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -300px 0px no-repeat;
}

.legend-marker-landmark{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -320px 0px no-repeat;
}

.map-marker-train{
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../img/sprite_hotels.png) -280px -30px no-repeat;
}

.map-marker-airport{
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../img/sprite_hotels.png) -300px -30px no-repeat;
}

.map-marker-landmark{
  display: inline-block;
  top: -10px;
  left: -7px;
  width: 14px;
  height: 10px;
  background: url(../img/sprite_map_pins.png) -180px -10px no-repeat;
}

.map-marker-pin{
  position: absolute;
  top: -27px;
  left: -9px;
  display: inline-block;
  width: 17px;
  height: 27px;
  background: url(../img/sprite_hotels.png) -260px -30px no-repeat;
}

.map-marker-pin-large{
  position: absolute;
  top: -36px;
  left: -12px;
  display: inline-block;
  width: 23px;
  height: 36px;
  background: url(../img/sprite_hotels.png) -340px 0px no-repeat;
}

.legend-marker-custom{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -250px -60px no-repeat;
}

.map-marker-pin-custom{
  position: absolute;
  top: -40px;
  left: -13px;
  display: inline-block;
  width: 27px;
  height: 40px;
  background: url(../img/sprite_map_pins.png) -147px 0px no-repeat;
}


.map-marker-in-policy,
.map-marker-client-preferred,
.map-marker-bcd-preferred,
.map-marker-out-of-policy {
  position: absolute;
  top: -31px;
  left: -11px;
}

.map-marker-in-policy {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -100px -40px no-repeat;
}

.map-marker-client-preferred {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -130px -40px no-repeat;
}

.map-marker-bcd-preferred {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -160px -40px no-repeat;
}

.map-marker-out-of-policy {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -190px -40px no-repeat;
}

.pin-hotel-map{
  min-width: 20px;
}

.rating-common-small{
  display: inline-block;
  width: 69px;
  height: 11px;
  margin: 2px 5px 0 0;

  span{
    display: inline-block;
    float: left;
    width: 100%;
    height: 11px;
    background: url(../img/sprite_hotels.png) -70px -160px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 5px;
    }
    &.rating1{
      width: 14px;
    }
    &.rating1h{
      width: 20px;
    }
    &.rating2{
      width: 28px;
    }
    &.rating2h{
      width: 34px;
    }
    &.rating3{
      width: 48px;
    }
    &.rating3h{
      width: 54px;
    }
    &.rating4{
      width: 56px;
    }
    &.rating4h{
      width: 63px;
    }
    &.rating5{
      width: 69px;
    }
  }
}

.rating-advisor{
  display: inline-block;
  width: 70px;
  height: 13px;
  background: url(../img/sprite_hotels.png) -110px -20px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 0;
    height: 13px;
    background: url(../img/sprite_hotels.png) -110px 0px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 7px;
    }
    &.rating1{
      width: 14px;
    }
    &.rating1h{
      width: 21px;
    }
    &.rating2{
      width: 28px;
    }
    &.rating2h{
      width: 35px;
    }
    &.rating3{
      width: 42px;
    }
    &.rating3h{
      width: 49px;
    }
    &.rating4{
      width: 56px;
    }
    &.rating4h{
      width: 63px;
    }
    &.rating5{
      width: 70px;
    }
  }
}

.rating-advisor-small{
  display: inline-block;
  width: 59px;
  height: 11px;
  margin: 0 5px 0 0;
  background: url(../img/sprite_hotels.png) 0 -180px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 0;
    height: 11px;
    background: url(../img/sprite_hotels.png) 0 -160px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 6px;
    }
    &.rating1{
      width: 12px;
    }
    &.rating1h{
      width: 18px;
    }
    &.rating2{
      width: 24px;
    }
    &.rating2h{
      width: 30px;
    }
    &.rating3{
      width: 36px;
    }
    &.rating3h{
      width: 42px;
    }
    &.rating4{
      width: 48px;
    }
    &.rating4h{
      width: 54px;
    }
    &.rating5{
      width: 60px;
    }
  }
}

.rating-advisor-large{
  display: inline-block;
  width: 84px;
  height: 16px;
  background: url(../img/sprite_hotels.png) 0px -100px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 0;
    height: 16px;
    background: url(../img/sprite_hotels.png) 0px -80px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 9px;
    }
    &.rating1{
      width: 17px;
    }
    &.rating1h{
      width: 26px;
    }
    &.rating2{
      width: 34px;
    }
    &.rating2h{
      width: 43px;
    }
    &.rating3{
      width: 51px;
    }
    &.rating3h{
      width: 60px;
    }
    &.rating4{
      width: 68px;
    }
    &.rating4h{
      width: 77px;
    }
    &.rating5{
      width: 84px;
    }
  }
}

.icon-trip-hotel{
  width: 27px;
  height: 33px;
  margin: 7px 0 0;
  background: url(../img/sprite_main.png) -660px -130px no-repeat;
}

.icon-modal-close-dark{
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url(../img/sprite_main.png) -710px -130px no-repeat;
}

.icon-chevron-right {
  width: 15px;
  height: 22px;
  background: url(../img/sprite_main.png) -650px -370px no-repeat;
 }

 .icon-chevron-left {
  width: 15px;
  height: 22px;
  background: url(../img/sprite_main.png) -670px -370px no-repeat;
 }

.icon-darr-bottom-small {
  width: 5px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -180px -40px no-repeat;
}

.icon-darr-up-small {
  width: 5px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -180px -50px no-repeat;
}

.icon-free-upgrade-option {
  width: 27px;
  height: 15px;
  background: url(../img/sprite_hotels.png) -250px -120px no-repeat;
}

.icon-sm-close {
  width: 9px;
  height: 9px;
  background: url(../img/sprite_main.png) -740px -240px no-repeat;
}

.icon-book{
  background-position: -48px -49px;
  opacity: .7;
}

.icon-photos{
  background-position: -120px -49px;
  opacity: .7;
  transform: scale(1.15, 1.1);
}

.icon-scroll-hint-arr{
  width: 14px;
  height: 18px;
  background: url(../img/sprite_main.png) no-repeat -710px -270px;
}

.icon-scroll-hint-arr-dark{
  width: 14px;
  height: 18px;
  background: url(../img/sprite_main.png) no-repeat -710px -310px;
}

.icon-cc-vi{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat 0 -20px;

  &.active{
    background-position: 0 0;
  }
}

.icon-cc-mc,.icon-cc-ca{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -34px -20px;

  &.active{
    background-position: -34px 0;
  }
}

.icon-cc-ds{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -68px -20px;

  &.active{
    background-position: -68px 0;
  }
}

.icon-cc-ax{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -102px -20px;

  &.active{
    background-position: -102px 0;
  }
}

.icon-cc-e{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat 0 -20px;

  &.active{
    background-position: 0 0;
  }
}

.icon-cc-to{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -34px -20px;

  &.active{
    background-position: -34px 0;
  }
}

.icon-cc-jc{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -68px -20px;

  &.active{
    background-position: -68px 0;
  }
}

.icon-cc-dc{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -102px -20px;

  &.active{
    background-position: -102px 0;
  }
}

.icon-cc-cb{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -136px -20px;

  &.active{
    background-position: -136px 0;
  }
}

.icon-rewards-go{
  width: 21px;
  height: 9px;
  background: url(../img/sprite_main.png) no-repeat -740px -270px;
}

.icon-hs-other{
  width: 15px;
  height: 16px;
  background: url(../img/sprite_main.png) no-repeat -300px -269px;
}

[class^="icon-flag-"]{
  width: 16px;
  height: 11px;
  margin-top: 4px;
  background-image: url(../img/sprite_flags.png);
}

.icon-flag-en{
  background-position: 0 0;
}

.icon-flag-en-gb{
  background-position: 0 -15px;
}

.icon-flag-fr-ca{
  background-position: 0 -45px;
}

.icon-flag-fr{
  background-position: 0 -30px;
}

.icon-flag-es{
  background-position: 0 -75px;
}

.icon-flag-de{
  background-position: 0 -60px;
}

.icon-flag-pt{
  background-position: 0 -90px;
}

.icon-flag-it{
  background-position: 0 -105px;
}

.icon-flag-ja{
  background-position: 0 -120px;
}

.icon-flag-ko{
  background-position: 0 -135px;
}

.icon-thumbs-up {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -310px -120px no-repeat;
}

.icon-check-circle {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -330px -120px no-repeat;
}

.icon-excl-circle {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -350px -120px no-repeat;
}

.icon-excl-circle-orange {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -370px -120px no-repeat;
}

.icon-question-circle-blue {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -310px -140px no-repeat;
}

.icon-in-policy {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -310px -160px no-repeat;
}

.icon-bcd-preferred {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -330px -160px no-repeat;
}

.icon-client-preferred {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -350px -160px no-repeat;
}

.icon-out-of-policy {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -370px -160px no-repeat;
}

.icon-close-ext {
  width: 14px;
  height: 14px;
  background: url(../img/sprite_main.png) -560px -240px no-repeat;
}

.icon-custom-plus-sign {
  width: 11px;
  height: 11px;
  background: url(../img/sprite_main.png) -760px -240px no-repeat;
}

.squared-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  vertical-align: top;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  color: @white;
  background: @gray;
  border-radius: 3px;
}

.highlight-dark-red{
  .squared-icon{
    background: darken(@red, 3%);
  }
}

.highlight-orange {
  .squared-icon{
    background: @orange;
  }
}

.icon-minus-squared {
  display: inline-block; /* for mixins, don't remove */
  width: 11px;
  height: 11px;
  background: url('../img/sprite_admin.png') 0 0 no-repeat;
}

.icon-plus-squared {
  display: inline-block; /* for mixins, don't remove */
  width: 11px;
  height: 11px;
  background: url('../img/sprite_admin.png') -20px 0 no-repeat;
}

.icon-circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 5px 0 1px;
  border-radius: 10em;
  background: @green;
}

.icon-unavailable {
  position: relative;
  top: -2px;
  width: 22px;
  height: 15px;
  line-height: 14px;
  text-align: center;
  background: darken(@gray-bg, 7%);
  border-radius: 3px;

  &:after {
    content: 'n/a';
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    color: @gray;
  }
}

.icon-folder-company {
  width: 19px;
  height: 15px;
  background: url('../img/sprite_admin.png') -40px 0 no-repeat;
}

.icon-folder-country {
  width: 19px;
  height: 15px;
  background: url('../img/sprite_admin.png') -70px 0 no-repeat;
}

.icon-item-company {
  width: 13px;
  height: 13px;
  background: url('../img/sprite_admin.png') -100px 0 no-repeat;
}

.icon-item-country {
  width: 11px;
  height: 14px;
  background: url('../img/sprite_admin.png') -120px 0 no-repeat;
}

.icon-folder-company-add {
  width: 19px;
  height: 15px;
  background: url('../img/sprite_admin.png') -140px 0 no-repeat;
}

.icon-folder-country-add {
  width: 19px;
  height: 15px;
  background: url('../img/sprite_admin.png') -170px 0 no-repeat;
}

.icon-item-company-add {
  width: 17px;
  height: 12px;
  background: url('../img/sprite_admin.png') -200px 0 no-repeat;
}

.icon-item-country-add {
  width: 16px;
  height: 13px;
  background: url('../img/sprite_admin.png') -220px 0 no-repeat;
}

.spinner-icon{
  display: inline-block;
  height: 32px;
  width: 32px;
  margin: 0 3px;
  background: url('../img/spinner_32x32.gif') no-repeat 0 0;
}

.spinner-icon--small{
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 3px;
  background: url('../img/spinner_32x32.gif') no-repeat 0 0;
  background-size: 16px 16px;
}

.ico-tripadvisor {
  display: inline-block;
  width: 22px;
  height: 13px;
  background: url('../img/icon-tripadvisor.png') no-repeat 0 0;
  background-size: cover;
}

.ico-agency-preferred {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-agency-preferred.png') no-repeat 0 0;
  background-size: cover;
}

.ico-company-preferred-chain {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-company-preferred-chain.png') no-repeat 0 0;
  background-size: cover;
}

.ico-company-preferred {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-company-preferred.png') no-repeat 0 0;
  background-size: cover;
}

.ico-agency-preferred-chain {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-bcd-preferred-chain.png') no-repeat 0 0;
  background-size: cover;
}

.ico-security-approved {
  display: inline-block;
  width: 16px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-security-approved.png') no-repeat 0 0;
  background-size: cover;
}

.ico-standard {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-standard.png') no-repeat 0 0;
  background-size: cover;
}

.ico-great-rates {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-great-rates.png') no-repeat 0 0;
  background-size: cover;
}

.ico-out-of-policy {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background: url('../img/icon-out-of-policy.png') no-repeat 0 0;
  background-size: cover;
}

.ico-tier,
.ico-agency-tier {
  display: inline-block;
  position: relative;
  min-width: 35px;
  margin-right: 3px;
  padding: 0 7px 0 22px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  vertical-align: top;
  color: @white;
  z-index: 0;

  &:before {
    .ico-company-preferred();
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 9px;
    top: 0;
    height: 18px;
    border-radius: 0 10em 10em 0;
    background: @green;
    z-index: -1;
  }
}

.ico-agency-preferred-chain-tier,
.ico-company-preferred-chain-tier {
  display: inline-block;
  position: relative;
  min-width: 35px;
  margin-right: 3px;
  padding: 0 7px 0 22px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  vertical-align: top;
  color: @white;
  z-index: 0;

  &:before {
    .ico-company-preferred();
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 16px;
    top: 0;
    height: 18px;
    border-radius: 0 10em 10em 0;
    background: @green;
    z-index: -1;
  }
}

.ico-tier {
  &:before {
    .ico-company-preferred();
  }
}

.ico-agency-tier {
  &:before {
    .ico-agency-preferred();
  }

  &:after {
    background: @blue;
  }
}

.ico-agency-preferred-chain-tier {
  &:before {
    .ico-agency-preferred-chain();
  }

  &:after {
    background: @blue;
  }
}

.ico-company-preferred-chain-tier {
  &:before {
    .ico-company-preferred-chain();
  }

  &:after {
    background: @green;
  }
}


.ico-range {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 9px;
  background: url('../img/icon-price-range.png') no-repeat 0 0;
  background-size: cover;
}

.ico-circle-full {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 20em;
  background: @green;
  border: 1px solid @white;
}

.ico-circle-empty {
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid @green;
  border-radius: 20em;
  background: @white;
}

.ico-iata {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 30px;
  height: 8px;
  background: url('../img/icon-iata.png') no-repeat 0 0;
  background-size: cover;
}

.ico-vpa_guarantee {
  display: inline-block;
  position: relative;
  top: 5px;
  height: 12px;
  width: 28px;
  cursor: default;

  &:after {
    content: "VPA Guarantee";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2px 3px;
    line-height: 1;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: -0.03em;
    color: @white;
    border-radius: 3px;
    background: #500d21;
  }
}

.ico-vpa_payment {
  display: inline-block;
  position: relative;
  top: 5px;
  height: 12px;
  width: 28px;
  cursor: default;

  &:after {
    content: "VPA Payment";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2px 3px;
    line-height: 1;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: -0.03em;
    color: @white;
    border-radius: 3px;
    background: #500d21;
  }
}

.ico-evoucher {
  display: inline-block;
  position: relative;
  top: 5px;
  height: 12px;
  width: 38px;
  cursor: default;

  &:after {
    content: "eVoucher";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 43px;
    padding: 2px;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    font-style: italic;
    font-size: 9px;
    letter-spacing: -0.07em;
    color: @white;
    border-radius: 3px;
    background: #900101;
  }
}

.ico-cvv {
  display: inline-block;
  position: relative;
  top: 5px;
  height: 12px;
  width: 28px;
  cursor: default;

  &:after {
    content: "CVV";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2px 3px;
    line-height: 1;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: -0.03em;
    color: @white;
    border-radius: 3px;
    background: #2a57a7;
  }
}

[class^="icon-am"], [class*=" icon-am"] {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 3px;
  background-image: url(../img/sprite-amenities.png);
  background-size: 200px;
  background-repeat: no-repeat;
}

.icon-am-internet {
  background-position: 0 0;

  &.stencil {
    background-position: 0 -25px;
  }
}

.icon-am-free-parking {
  background-position: -28px 0;

  &.stencil {
    background-position: -28px -25px;
  }
}

.icon-am-wifi,
.icon-am-wifi-internet {
  background-position: -56px 0;

  &.stencil {
    background-position: -56px -25px;
  }
}

.icon-am-breakfast,
.icon-am-free-breakfast {
  background-position: -85px 0;

  &.stencil {
    background-position: -85px -25px;
  }
}

[class^="cc-icon"], [class*=" cc-icon"] {
  display: inline-block;
  width: 34px;
  height: 22px;
  margin-right: 4px;
  background-image: url(../img/sprite-cc-logos.png);
  background-size: 300px;
  background-repeat: no-repeat;
}

.cc-icon-vi {
  background-position: 0 -28px;

  &.active {
    background-position: 0 0;
  }
}

.cc-icon-mc {
  background-position: -37px -28px;

  &.active {
    background-position: -37px 0;
  }
}

.cc-icon-ax {
  background-position: -73px -28px;

  &.active {
    background-position: -73px 0;
  }
}

.cc-icon-dc {
  background-position: -110px -28px;

  &.active {
    background-position: -110px 0;
  }
}

.cc-icon-jc {
  background-position: -147px -28px;

  &.active {
    background-position: -147px 0;
  }
}

.cc-icon-ds {
  background-position: -185px -28px;

  &.active {
    background-position: -185px 0;
  }
}

.cc-icon-cb {
  background-position: -223px -28px;

  &.active {
    background-position: -223px 0;
  }
}

.cc-icon-to {
  background-position: -262px -28px;

  &.active {
    background-position: -262px 0;
  }
}

.cc-icon-tp {
  background-position: 0 -87px;

  &.active {
    background-position: 0 -59px;
  }
}

.icon-double-arrow-up {
  display: inline-block;
  width: 10px;

  &:after {
    content: "»";
    display: block;
    position: relative;
    top: 2px;
    left: 1px;
    font-size: 16px;
    line-height: 1;
    .rotate(270deg);
  }
}

.icon-double-arrow-down {
  display: inline-block;
  width: 10px;

  &:after {
    content: "»";
    display: block;
    position: relative;
    top: 2px;
    left: 4px;
    font-size: 16px;
    line-height: 1;
    .rotate(90deg);
  }
}

.icon-preferred-vendor {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  vertical-align: top;
  background: url(../img/assets/cars-common/preferred-vendor.svg) 0 0 no-repeat;
}

.icon-preferred-car-type {
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-right: 4px;
  vertical-align: top;
  background: url(../img/assets/cars-common/preferred-car-type.svg) 0 0 no-repeat;
}

.icon-bcd-preferred-vendor {
  display: inline-block;
  width: 29px;
  height: 18px;
  margin-right: 1px;
  vertical-align: top;
  background: url(../img/assets/cars-common/preferred-vendor-bcd.svg) 0 0 no-repeat;
}

.icon-bcd-preferred-car-type {
  display: inline-block;
  width: 29px;
  height: 18px;
  margin-right: 1px;
  vertical-align: top;
  background: url(../img/assets/cars-common/preferred-car-type-bcd.svg) 0 0 no-repeat;
}

.pref-ico__wrapper {
  display: inline-block;
  margin: 0 1px 3px 0;
  vertical-align: top;

  [class^="icon-"], [class*=" icon-"] {
    float: left;
  }

  .icon-preferred-car-type,
  .icon-bcd-preferred-car-type {
    & + .tier__wrapper__value {
      color: #205a80;
      border-color: #e2e2e6;
    }
  }

  .icon-bcd-preferred-vendor,
  .icon-bcd-preferred-car-type {
    & + .tier__wrapper__value {
      margin-left: -20px;
      padding-left: 21px;
    }
  }
}

.tier__wrapper__value {
  float: left;
  display: inline-block;
  position: relative;
  height: 18px;
  margin: 0 4px 0 -13px;
  padding: 0 6px 0 12px;
  line-height: 16px;
  font-size: 11px;
  font-weight: bold;
  color: darken(@green, 3%);
  border-radius: 0 10em 10em 0;
  border: 1px solid #e2e2e1;
  z-index: -1;
  cursor: default;
}
