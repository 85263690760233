@modal-overlay-zindex: 999;
@modal-zindex: 1041;
@modal-dimmed-zindex: 998;

.with-overlay {
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: @black;
    z-index: @modal-overlay-zindex;
    opacity: .8;
  }
}

.modal-mixin {
  position: fixed;
  top: 20%;
  left: 50%;
  background: #fff;
  z-index: @modal-zindex;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.35);

  &.modal-dimmed {
    z-index: @modal-dimmed-zindex;
  }
}

.modal-width(@width) {
  width: @width;
  margin-left: @width / -2;
}

.modal-simple {
  .modal-mixin();
  .modal-width(450px);
  min-height: 120px;
  padding: 20px 25px;

  .spinner {
    margin: 2px 0 0;
  }

  p {
    margin: 0 0 9.5px;
    line-height: 1.45;
    font-size: 16px;

    &.small {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  ul {
    margin: 0;
    paddig: 0 0 0 20px;
  }

  li {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .controls {
    margin: 20px -25px -20px;
    padding: 15px 20px;
    text-align: right;
    border-top: 1px solid @modal-footer-border-color;
    background: @grayBg;

    .btn {
      margin-left: 5px;
    }
  }
}

.modal-window-container {
  > div {
    position: relative;
    margin: 80px auto 30px;
    width: 580px;
    background: #fff;
    z-index: @modal-zindex;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.35);
    padding: 0;
  }
  &.modal-wide > div {
    margin-top: 70px;
    width: 770px;
  }
}

.modal-dialog.modal-wide {
  margin-top: 70px;
  width: 770px;
}

.modal-top {
    z-index: @zindex-modal-region;
}

.modal-window {
  position: relative;
  margin: 80px auto 30px;
  width: 580px;
  background: #fff;
  z-index: @modal-zindex;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.35);

  &.modal-dimmed {
    z-index: @modal-dimmed-zindex;
    .opacity(0.7);
  }

  &.modal-wide {
    margin-top: 70px;
    width: 770px;
  }

  .modal-body {
    .modal-footer { // Before new modals migration dirty fix
      margin: 20px -20px -20px;
      padding: 20px;
    }
  }

  .modal-footer {
    margin: 0;
    border-top: 1px solid @modal-footer-border-color;
  }

  .bottom-control {
    margin: 0 0 10px;
  }

  .hotel-info {
    padding: 0 0 15px 0;
    margin: 0 0 15px;
    border-bottom: 1px solid @grayLighter;

    .name{
      display: inline-block;
      .bold();
      margin: 0 10px 0 0;
    }

    .descr{
      display: block;
      color: @grayLight;
    }
  }

  .wizard-section{
    margin: 0 0 10px;

    h4{
      margin: 0 0 7px;
    }
  }

  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
  }

  .wizard-buttons {
    .clearfix();
    padding: 10px 0;

    .loader {
      position: relative;
      margin-left: 20px;
    }
  }
}

.modal-window.rates-modal {
  margin: 80px auto 30px;
  width: 580px;

  code.rate-description {
    display: block;
    padding: 20px;

    max-height: 409px;
    overflow-y: scroll;

    color: @grayDark;
    background-color: transparent;
  }
}

.modal-general-close {
  cursor: pointer;
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}

// Info modal for text info / errors
//


.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 1000;
}

.modal-header__selector {
  float: right;
  margin: -1px 25px 0 0;

  .dropdown-toggle {
    padding: 4px 10px;
    background: @grayBg;
  }
}

.info-modal {
  position: relative;
  top: 25%;
  width: 600px;
  margin: 0 auto 20px;
  background: @white;
  border-left: 6px solid @red;
}

.info-modal-warning {
  border-left: 6px solid @orange;
}

.modal--centered{
  text-align: center;
  height: 100%;
}

.modal-gallery {
  display: inline-block;
  margin: 7% auto 2%;
  background: #fff;
  z-index: @modal-zindex;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.35);

  .modal-content{
    display: inline-block;
  }
}

.modal__sidebar-left{
  float: left;
  width: 200px;
  margin-right: 15px;
}

.modal__replacing-control {
  float: left;
  width: 8%;
  margin-top: 128px;
  text-align: center;

  .btn {
    height: 100px;
    margin-bottom: 10px;

    &.disabled,
    &[disabled] {
      .opacity(.4);
    }
  }
}

.modal__pane {
  float: left;
  position: relative;
  height: 443px;
  width: 46%;
  margin-bottom: 5px;
  padding: 0 10px 12px;
  overflow-y: scroll;
  z-index: 3;
  background: @gray-bg;
  border: 1px solid @gray-border;

  .table--rows {
    background: none;

    tbody {
      background: @white;
    }

    td {
      padding: 6px 7px;
    }
  }
}

.modal-display {
  .modal-dialog {
    width: 1038px;
  }

  .container {
    width: 998px;
  }

  .modal--centered,
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.multiple-select {
  padding: 10px 0 0;
}

.multiple-select__header {
  margin: 0;
  padding: 2px 0 12px 20px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;
}

.multiple-select__list {
  margin: 0;
  padding: 0 0 10px;
  list-style: none;
  max-height: 330px;
  overflow: hidden;
  overflow-y: scroll;

  li:first-child {
    .multiple-select__title {
      margin-top: 0;
    }
  }
}

.multiple-select__title {
  margin: 3px 0;
  padding-left: 20px;
  line-height: 27px;
  font-weight: bold;
  color: @gray-dark;
  background: @gray-bg;
}

.multiple-select__items a {
  position: relative;
  display: block;
  padding: 3px 3px 3px 40px;
  color: @gray-dark;

  &:hover {
    background: #f5f5f5;
  }
}

.multiple-select__items {
  .glyphicons {
    position: absolute;
    left: 20px;
    top: 4px;
  }
}

.modal-scrollbox {
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid @grayBorder;
  background: @white;
  border-radius: 2px;
}

.modal-scrollbox__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    cursor: pointer;
    padding: 10px 15px;
    border-top: 1px solid @grayBorder;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background: #FFFBED;
    }

    &.checked {
      background: #FFFBED;

      &:after {
        content: "\e084";
        font-family: 'Glyphicons Halflings';
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 50%;
        color: @green;
        margin-top: -9px;
      }
    }
  }
}

.modal--rev {  // Modal with white header/footer
  .modal-header {
    background: @white;
  }

  .modal-body {
    min-height: 230px;
  }

  .modal-footer {
    padding: 15px 20px 16px;
    background: @white;

    .btn-link {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.modal-header-form {
  .clearfix();
  margin: -20px -20px 7px;
  padding: 7px 20px 9px;
  border-bottom: 1px solid @gray-border;
  background: @gray-bg;

  label {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
  }

  .form-group {
    float: left;
    position: relative;
    margin-right: 5px;

    .form-group__error {
      .form-group__error--top();
      right: 7px;
      left: auto;
      top: 11px;

      &:before {
        right: 12px;
        left: auto;
      }
    }
  }

  .btn {
    margin-top: 19px;
  }

  .input-split {
    width: 232px !important;
  }

  .input-max {
    width: 471px !important;
  }

  .input-full {
    width: 100% !important;
  }
}

.modal-body__scroll-header {
  margin: 0 -20px 5px;
  padding: 4px 22px 12px 21px;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;
}

.modal-body__clear-lnk {
  float: right;
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
}

.modal-body__scroll__tag {
  float: right;
  margin-top: 2px;
  font-size: 11px;
  color: @green;
}

.modal-body__scroll-list {
  max-height: 238px;
  margin: 0 -20px;
  padding: 0 20px;
  list-style: none;
  overflow-y: auto; 

  li {
    padding: 6px 10px;
    border-bottom: 1px solid @gray-border;
    cursor: pointer;
    .user-select(none);

    &:hover {
      background: @gray-bg;
    }
  }
}

.modal-body__scroll-item--selected {
  color: @white;
  background: @blue !important;

  .modal-body__scroll-info,
  .modal-body__scroll__tag {
    color: tint(@blue, 80%);
  }
}

.modal-body__scroll-info {
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.4;
  color: @gray-dark;
}

.modal-body__panel {
  padding: 60px 0 50px;

  &:empty {
    display: none;
  }

  h4 {
    margin: 0;
    color: @gray-dark;
    text-align: center;
  }

  .alert {
    margin: -12px 0;
  }
}

.modal-body .result-block {
  margin: 0;

  pre {
    font-size: 12px;
  }
}

.modal-body__well-section {
  margin-bottom: 15px;
  padding: 10px 17px 6px;
  background: @gray-bg;
  border-radius: 3px;

  .form-group {
    margin-bottom: 0;
  }
}

.modal-body__section-title {
  margin: 20px 0 15px;
  padding: 0 0 8px;
  font-size: 15px;
  border-bottom: 1px solid @gray-border;

  &:first-child {
    margin-top: 0;
  }

  .required-note {
    margin-top: 4px;
  }
}

.modal-body__section-add {
  margin-bottom: 10px;
  border: 1px dashed @gray-border;

  .btn {
    width: 100%;
    padding: 9px;
  }
}

.modal__controls {
  position: relative;
  margin: -20px -20px 20px;
  padding: 11px 20px 7px 20px;
  background: @gray-bg;
  z-index: 5;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);

  .radio-inline {
    padding-bottom: 5px;
  }
}

.modal__controls__row {
  margin: -10px -20px -7px;
  padding: 8px 20px 1px;
  background: @white;

  & + & {
    margin: 7px -20px -7px;
    padding: 2px 20px 0;
    border-top: 1px solid @gray-border;
  }
}

.modal__controls__row--bg {
  padding: 12px 20px 8px;
  background: @gray-bg;
}

.modal__controls__details {
  display: table;
  width: 100%;
  margin: 0 0 14px 0;
  padding: 10px 16px;
  font-size: 11px;
  background: @gray-bg;
  border: 1px solid @gray-border;
}

.modal__controls__details__header {
  margin: 6px 0;
  font-size: 12px;
}

.modal__controls__details__control {
  .clearfix();
  margin: -5px 0 11px;
  padding: 6px 0 3px;
  border: 1px dashed @gray-border;

  .row,
  .form-group,
  .radio-select__column {
    margin: 0;
    padding: 0;
  }

  .radio-select__column {
    width: auto;
    padding-right: 30px;
  }
}

.modal__controls__details__item {
  display: table-cell;
  padding: 0 18px 0 0;
  h5 {
    margin: 0 0 2px;
    font-size: 11px;
  }

  .modal__controls__details__value {
    text-transform: capitalize;
    font-weight: bold;
  }
}

.modal-rate-desc__wrap {
  &:empty,
  p:empty {
    display: none;
  }

  & + & {
    margin-top: 20px;
  }
}

.modal-rate-desc__outline {
  margin-top: 18px;
  padding: 8px 12px;
  font-size: 12px;
  background: @gray-bg;
  border-radius: 4px;

  h4 {
    margin: 0 0 4px;
    font-size: 13px;
  }
}

.modal-rate-desc__title {
  margin: 0 0 10px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;
}

.modal-rate-desc__list {
  margin-top: 10px;

  li {
    margin-bottom: 3px;
  }
}

.modal-rate__table {
  width: 743px;
  margin: 6px 0 9px 17px;


  tr:first-child {
    th, td {
      border-top: none;
    }
  }
}

.modal-rate-desc__row {
  margin-bottom: 10px;

  &:empty {
    display: none;
  }
}

.modal-body__placeholder {
  margin: 30px 0 10px;
  padding: 20px 40px;
  text-align: center;
  color: @gray-dark;
  border: 2px dashed @gray-border;
}

.modal-body__placeholder--error {
  color: @red;
  border-color: lighten(@red, 25%);
  background: lighten(@red, 52%);

  .modal-body__placeholder__title {
    color: @red;
  }
}

.modal-body__placeholder__title {
  margin: 5px 0 10px;
  color: @gray-darker;
  font-size: 16px;
  font-weight: bold;
}

.modal-body__placeholder__text {
  font-size: 12px;
}

.modal-body__info-title {
  margin: 0 -20px 5px;
  padding: 5px 20px 10px;
  border-bottom: 1px solid @gray-border;

  h4 {
    margin: 0;
    font-weight: bold;
    font-size: 13px;
  }
}

.modal-body__form-group__error {
  margin: 15px 20px 15px 185px;
  color: @red;
  font-size: 12px;
}

.modal-footer {
  .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    padding: 0;
  }
}

.price-comparison {
  display: flex;
  margin: 30px 0 0;
  padding: 15px 30px;
  background: @gray-bg;
  border: 2px dashed @gray-border;
}

.price-comparison__item {
  flex: 1;
}

.price-comparison__title {
  margin: 0;
}

.price-comparison__value {
  font-size: 24px;
}

.price-comparison__item--muted {
  color: @gray;
  text-align: right;
}

.modal-footer--flex {
  display: flex;
  align-items: center;;
}

.modal-footer__legend {
  margin-right: auto;
  padding: 5px 10px;
  max-width: 80%;
  font-size: 11px;
  text-align: left;
  background: rgba(255,255,255,0.9);
  border-radius: 3px;

  .glyphicon {
    font-size: 12px;
  }

  .text-gray {
    color: #a2abb1;
  }

}
