.modal--rate,
.modal--xl {
  .modal-dialog {
    width: 900px;
    margin-top: 50px !important;
  }

  .modal-header {
    .clearfix();
    padding: 23px 20px;
    background: #fbfbfc;
    border-bottom: 2px solid @gray-darker;

    .modal-title {
      float: left;
      margin-top: 2px;
      font-weight: bold;
    }

    .btn {
      float: right;
      margin-left: auto;
    }
  }

  .modal-body {
    z-index: 2;
    min-height: 400px;
  }

  .loading-spinner__wrapper {
    padding: 150px 0;
  }

  .list-unstyled {
    margin-bottom: 9px;
  }
}

.modal-body__tabs {
  float: left;
  width: 210px;
  margin: -15px 0 0 -21px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 210px;
    background: @gray-bg;
    border-right: 1px solid @gray-border;
    z-index: -1;
  }
}

.modal-body__tabs__title {
  margin: 29px 0 -20px;
  padding: 0 10px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}

.modal-body__tabs-list {
  margin: 0;
  padding: 30px 0 0 20px;
  list-style: none;

  li {
    margin: 0 -1px 5px 0;
    padding: 8px 14px;
    border: 1px solid @gray-border;
    background: lighten(@gray-bg, 1%);
    cursor: pointer;

    &:hover {
      background: lighten(@gray-bg, 2%);
    }
  }
}

.modal-body__tabs__str {
  font-weight: normal;
  margin-top: 3px;
  font-size: 12px;
}

.modal-body__tabs-list--active {
  font-weight: bold;
  border-right: 1px solid @white !important;
  background: @white !important;
  cursor: default !important;
}

.modal--xl {
  .modal-body__tabs-list {
    li {
      margin-left: 18px;
      padding: 8px 14px;

      &:not(.modal-body__tabs-list--active) {
        border-color: lighten(@gray-border, 7%);
        border-right-color: @gray-border;
      }

      &:not(.modal-body__tabs-list--active):hover {
        border-color: darken(@gray-border, 12%);
      }
    }
  }

  .modal-body__tabs-list--active {
    margin-left: 0 !important;
    border-left: 3px solid @gray-dark;
  }
}

.modal-body__tabs-content {
  margin: 16px 0 0 230px;

  .tabs-inner {
    float: left;
    width: 100%;
    margin: -40px 0 20px;
    background: transparent;

    .a-flight-code__item {
      display: block;
      margin: 0;
      font-size: 11px;
      font-weight: normal;
    }
  }

  .tabs-inner__item {
    height: 45px;
    min-width: 140px;
    padding-top: 5px;
    padding-bottom: 4px;
    text-align: left;
  }

  h5 {
    margin-bottom: 15px;
  }

  pre + h5 {
    margin-top: 30px;
  }

  pre {
    white-space: pre-wrap;
  }
}

.modal-rate__sm-text {
  margin: 5px 0 15px 40px;
  font-size: 11px;
}

.spotlightModal {
  .modal-content {
    background-color: transparent;
  }
}
