.bl-wrapper {
  display: flex;
  width: 800px;
  min-height: 490px;
  margin: 5vh auto 0;
  border: 1px solid @gray-border;
}

.bl-content {
  display: flex;
  width: 80%;
}

.bl-content__text {
  display: flex;
  align-items: center;
  padding: 30px 30px 65px;
  font-size: 15px;
}

.bl-sidebar {
  display: flex;
  flex-direction: column;
  padding: 25px 0 0;
  border-left: 1px dotted @gray-border;
}

.bl-sidebar__content {
  flex-grow: 1;

  .form-group {
    .clearfix();

    & + .form-group {
      margin-top: 25px;
    }
  }

  .control-label {
    display: block;
    width: auto;
    font-weight: bold;
  }

  .multi-selector-body {
    max-height: none;
  }

  .checkbox,
  .radio {
    margin-top: 5px;
  }
}

.bl-sidebar__footer {
  padding: 15px;
  background: darken(@gray-bg, 3%);
}