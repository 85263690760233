// Tags widget
//
// [1]  - Need for a cases that tag items breaks in a 2 rows,
// so "Clear all" link always appended to the end.

.tag-list {
  .clearfix();
}

.tag-list__title {
  float: left;
  margin: 10px 12px 0 0;
  font-size: 12px;
  color: @gray;
}

.tag-list__wrapper {
  margin: -5px 0 0 90px;
}

.tag-list__tags {
  display: inline;  /* [1] */
}

.tag-list__item {
  float: left;
  margin: 5px 5px 0 0;
  padding: 4px 9px;
  font-size: 12px;
  background: @gray-bg;
  border: 1px solid @gray-border;
  border-radius: 20em;
  cursor: default;

  &:hover {
    background: darken(@gray-bg, 3%);
  }

  .glyphicon-remove {
    margin-left: 7px;
    font-size: 10px;
  }

  a {
    color: @gray-dark
  }
}

.tag-list__clear-lnk {
  display: inline-block;
  margin: 14px 0 0 8px;
}
