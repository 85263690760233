// Switcher
//

.switcher {
  display: inline-block;
  position: relative;
  height: 25px;
  width: 66px;
  margin: 0 9px;
  vertical-align: middle;
  background: #8E8E8E;
  border-radius: 10em;
  cursor: pointer;
  .transition(all 0.4s);

  &:before {
    content: "\e014";
    position: relative;
    top: 0;
    right: -41px;
    margin-top: 11px;
    color: @white;
    font-size: 14px;
    font-weight: bold;
    .transition(all 0.3s);
    .glyphicon();
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -3px;
    display: block;
    height: 25px;
    width: 36px;
    border-radius: 15em;
    border: 1px solid @gray-border;
    background: #F5F5F5;
    .transition(all 0.3s);
  }

  &.switcher--readonly {
    cursor: default;
  }
}

.switcher--on {
  background: #2D89C8;

  &:before {
    content: "\e013";
    right: -10px;
  }

  &:after {
    left: 33px;
  }

  .switcher__dots {
    left: 45px;
  }
}

.switcher__dots {
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 10px;
  width: 12px;
  height: 9px;
  z-index: 3;
  .transition(all 0.3s);
}